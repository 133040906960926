import React, { CSSProperties, useEffect, useState } from 'react';
import {
    Button,
    Space,
    Descriptions,
    Typography,
    Dropdown,
    InputNumber,
    Menu,
    Upload,
    Pagination,
    Table,
    Row,
    Col,
    Tag,
    Checkbox,
    Select,
    Modal,
    notification,
    message,
} from 'antd';
import {
    DeleteOutlined,
    UploadOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import API_SERVICE from '../../services/api-service';

import ORDER_RECEIVED from './Actions/ORDER_RECEIVED';
import PENDING_CONFIRMATION from './Actions/PENDING_CONFIRMATION';
import PAYMENT_SIGNOFF from './Actions/PAYMENT_SIGNOFF';
import READY_TO_SHIP from './Actions/READY_TO_SHIP';
import ORDER_DELIVERED from './Actions/ORDER_DELIVERED';
import ORDER_COMPLETE from './Actions/ORDER_COMPLETE';
import ORDER_SHIPPED from './Actions/ORDER_SHIPPED';

const { Text, Title, Link } = Typography;
export default function OrderAction({
    details,
    callback,
    setActiveTabKey,
}: any) {
    const [shipping_charges, set_shipping_charges] = useState(
        details?.shipmentCharge,
    );
    const [other_charges, set_other_charges] = useState(details?.otherTax);
    const [loading, set_loading] = React.useState(false);

    switch (details?.adminStatus) {
        case 'ORDER_RECEIVED':
            return <ORDER_RECEIVED details={details} />;
        case 'PENDING_CONFIRMATION':
            return (
                <PENDING_CONFIRMATION
                    details={details}
                    callback={callback}
                    setActiveTabKey={setActiveTabKey}
                />
            );
        case 'PENDING_PAYMENT':
            return (
                <PENDING_CONFIRMATION
                    details={details}
                    callback={callback}
                    setActiveTabKey={setActiveTabKey}
                />
            );
        case 'PAYMENT_SIGN_OFF':
            return <PAYMENT_SIGNOFF details={details} />;
        case 'READY_TO_SHIP':
            return <READY_TO_SHIP details={details} />;
        case 'ORDER_SHIPPED':
            return <ORDER_SHIPPED details={details} />;
        case 'ORDER_DELIVERED':
            return <ORDER_DELIVERED details={details} />;
        case 'ORDER_COMPLETE':
            return <ORDER_COMPLETE details={details} />;
        default:
            return <div />;
    }
}
