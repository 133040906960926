export const DASHBOARD = '/admin';
export const FRANCHISES_LIST = '/admin/franchises';
export const PORT_AND_SUPPLIERS_LIST = '/admin/port-suppliers';
export const CONTAINER_TYPES = '/admin/container-types';
export const CATEGORIES_LIST = '/admin/categories';
export const USER_LIST = '/admin/users';
export const ROLES_LIST = '/admin/roles';
export const PRODUCT_LIST = '/admin/products';
export const ORDER_LIST = '/admin/orders';
export const VIEW_KNOWLEDGE = '/admin/view-document';
export const UPLOAD_KNOWLEDGE = '/admin/upload-document';
export const SHIPPING_DOCUMENTS = '/admin/shipping';
export const ACCOUNT_MANAGEMENT = '/admin/account';
