import * as React from 'react';
import { ReactElement } from 'react';
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
} from 'react-router-dom';
import DesktopLayout from 'shared/components/layout/DesktopLayout';

import { useTranslation } from 'react-i18next';
import HomePage from './home/HomePage';
import ProductListPage from './ProductList/ProductListPage';
import ProductDetailsPage from './ProductDetails/ProductDetailsPage';
import Cart from './Cart/Cart';
import Wishlist from './Wishlist/Wishlist';
import ProfileList from './UserProfile/ProfileList';
import FranchiseProfile from './FranchiseProfile/FranchiseProfile';
import Orders from './Orders/Orders';
import Addresses from './Addresses';
import Dashboard from './DashboardClient';
import Shipping from './Shipping';
import Account from './Account';
import Notifications from './Notifications/Notifications';
import Training from './Training/Training';
import { AuthConsumer } from 'shared/contexts/AuthContext';
import UserOrderDetails from './Orders/OrderDetails';
import KnowledgeBaseFranchise from './KnowledgeBaseFranchise/KnowledgeBaseFranchise';
import UploadDocument from './KnowledgeBaseFranchise/UploadDocumentsPage';

interface MenuItem {
    route: string;
    icon: ReactElement;
    label: string;
}

type Props = {};

const Layout: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();

    const { pathname } = useLocation();
    const history = useHistory();

    const selectedMenu = pathname;

    function handleMenuClick(route: string) {
        if (route !== pathname) {
            history.push(route);
        }
    }

    const AppLayout = DesktopLayout;

    React.useEffect(() => {
        document.title =
            'Franchise | Danube Home One Application | Home Decor, Furnishings, Lighting, Bath & Kitchen';
    }, []);

    return (
        <AppLayout selectedMenu={selectedMenu} onMenuClick={handleMenuClick}>
            <AuthConsumer>
                {({ isAuth, user }) =>
                    user.role.name === 'FRANCHISE_STAFF' ? (
                        <Switch>
                            <Route
                                exact
                                path="/training"
                                component={Training}
                            />
                            <Redirect to="/training" />
                        </Switch>
                    ) : (
                        <Switch>
                            <Route exact path="/">
                                <Redirect to="/home" />
                            </Route>
                            <Route exact path="/home" component={HomePage} />
                            <Route
                                exact
                                path="/home/:type"
                                component={ProductListPage}
                            />
                            <Route
                                exact
                                path="/home/:category/:category_id"
                                component={HomePage}
                            />
                            <Route
                                exact
                                path="/home/:store/:store_id/:category/:category_id"
                                component={ProductListPage}
                            />

                            <Route
                                exact
                                path="/products/:productId"
                                component={ProductDetailsPage}
                            />

                            <Route
                                exact
                                path="/wishlist"
                                component={Wishlist}
                            />
                            <Route exact path="/cart" component={Cart} />

                            <Route exact path="/orders" component={Orders} />
                            {/* <Route exact path='/orders/:id' component={Orders} /> */}
                            <Route
                                exact
                                path={'/orders/:order_id'}
                                component={UserOrderDetails}
                            />

                            <Route
                                exact
                                path="/franchise-profile"
                                component={FranchiseProfile}
                            />
                            <Route
                                exact
                                path="/users"
                                component={ProfileList}
                            />

                            <Route
                                exact
                                path="/notifications"
                                component={Notifications}
                            />

                            <Route
                                exact
                                path="/shipping"
                                component={Shipping}
                            />
                            <Route exact path="/account" component={Account} />

                            <Route
                                exact
                                path="/addresses"
                                component={Addresses}
                            />

                            <Route
                                exact
                                path="/dashboard"
                                component={Dashboard}
                            />

                            <Route
                                exact
                                path="/training"
                                component={Training}
                            />
                            <Route
                                exact
                                path="/franchise-development"
                                component={KnowledgeBaseFranchise}
                            />
                            <Route
                                exact
                                path="/upload-documents"
                                component={UploadDocument}
                            />
                        </Switch>
                    )
                }
            </AuthConsumer>
        </AppLayout>
    );
};

export default Layout;
