import React, { CSSProperties,useState } from 'react';
import './index.scss';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import { Checkbox, Col, Row, Input, Typography, Space, notification, message,Button ,Anchor} from 'antd';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import Carousel from 'react-multi-carousel';
import { CodeSandboxOutlined, HeartOutlined, HeartFilled } from '@ant-design/icons';
import 'react-multi-carousel/lib/styles.css';
import { useHistory } from 'react-router-dom';
import API_SERVICE from 'client/src/services/api-service';
import { useTranslation } from 'react-i18next';
import {globals} from 'client/src/App';

const { Search } = Input;
const contentStyle: CSSProperties = {
    height: '320px',
    color: '#fff',
    width: '200px',
    lineHeight: '320px',
    textAlign: 'center',
    background: '#364d79',
};
const { Text, Title, Link } = Typography;
export default function ProductCardCarousel({ title, refresh, products,category_id}: any) {
    const history = useHistory();
    const { t } = useTranslation();
    //const [productId,setProductId]=useState(2);
    //const [downloadurl,setDownloadurl]=useState("");

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 5, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3,
            slidesToSlide: 3, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    };

    async function get_download(category_id:number){
        try {
            const resp = await API_SERVICE.get_download(category_id);
            console.log("download file",resp.data.data.item);
            refresh()
            globals.refresh()
            window.open(resp.data.data.item,"_blank") ;
            message.success("Downloaded");
        } catch (e) {
          console.log(e)
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    async function add_to_wishlist(product_id){
      try {
          const resp = await API_SERVICE.add_to_wishlist(product_id);
          message.success("Added to wishlist")
          refresh()
          globals.refresh()
      } catch (e) {
        console.log(e)
          notification.error({
              message: t(API_SERVICE.handleErrors(e)),
          });
      }
    }

    async function remove_from_wishlist(product_id: number) {
      try {
          const resp = await API_SERVICE.remove_from_wishlist(product_id);
          message.success("Removed from wishlist")
          refresh()
          globals.refresh()
      } catch (e) {
        console.log(e)
          notification.error({
              message: t(API_SERVICE.handleErrors(e)),
          });
      }
    }

    if (!products || !products.length)
      return null;
      console.log("categoryid",category_id);
      
    return (
        <>
        
      
        <div className="product-card-carousel">
            {
            
                title  || <></>
            }
           
            <Carousel
                swipeable={false}
                draggable={false}
                showDots={true}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                removeArrowOnDeviceType={['tablet', 'mobile']}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px">
                {products.map((product:any, i:number) => {
                    return <div key={i} className="centered">
                      <div className={'product-card'}>
                        {(!product.isWishlisted)?
                          <div className="wishlist-icon" onClick={()=>add_to_wishlist(product.id)}><HeartOutlined/></div>
                          :<div className="wishlist-icon-filled" onClick={()=>remove_from_wishlist(product.id)}><HeartFilled /></div>
                        }
                        <img src={product.thumbnail? product.thumbnail.url:''} className="image"
                          onClick={() => history.push('/products/'+product.id)} />
                        <div className="details-section" onClick={() => history.push('/products/'+product.id)}>
                            <div>
                                <Text className={'product-code'}>{product.skuId}</Text>
                            </div>
                            <div>
                                <Text className={'product-name'}>{product.name}</Text>
                            </div>
                        </div>

                    </div>
                    </div>
                    })}
            </Carousel>
        </div>
        </>
    );

}
