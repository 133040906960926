import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Space, Descriptions, Typography, Dropdown, InputNumber, Menu, Upload, Pagination, Table, Row, Col, Tag, Checkbox, Select, Modal, Radio, notification, message } from 'antd';
import { DeleteOutlined, UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import API_SERVICE from '../../../services/api-service';

const { Text, Title, Link } = Typography;
export default function ORDER_DELIVERED({ details }: any) {

    const total_amount = details.totalCbmOrder? details.totalCbmOrder.portAmount : 0
    const recieved_amount = details.orderPaymentFile? details.orderPaymentFile.amount : 0
    const pending = total_amount - recieved_amount

    const [file_id,set_file_id] = useState(false)
    const [loading,set_loading] = React.useState(false)

    async function submit(){
      set_loading(true)
      try {
        const data = await API_SERVICE.order_complete(details.id,
          {
            "fileTaxInvoiceId" : file_id,
          }
        )
        window.location.assign('/admin/orders')
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
      set_loading(false)
    }

    async function handle_upload(file){
      try {
        const data = await API_SERVICE.upload_file(file,'DOCUMENT')
        set_file_id(data.data.data.id)
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
    }

    return (
      <div style={{
        paddingTop: 8,
        }} >
        <Descriptions column={1} size="small" layout="horizontal">
          <Descriptions.Item label="Status"> Order Delivered </Descriptions.Item>
          <Descriptions.Item label="Total Order Value"> ${total_amount} </Descriptions.Item>
          <Descriptions.Item label="Payment Received"> ${recieved_amount} </Descriptions.Item>
          <Descriptions.Item label="Payment Pending"> ${pending} </Descriptions.Item>
        </Descriptions>
        <br/>
        <Upload
          accept=".png,.jpg,.jpeg,.pdf"
          maxCount={1}
          beforeUpload={file => {
            handle_upload(file)
            return false;
           }}
          className="upload-list-inline">
          <Button icon={<UploadOutlined />}>Upload Final Tax Invoice</Button>
        </Upload>
        <br/>
        <div>
          <Button type="primary" onClick={submit}>Mark Order as Complete</Button>
        </div>
        <br/>
        <Button
          type="primary"
          style={{
            marginBottom: 16,
          }}
          icon={<DownloadOutlined />}
          onClick={()=>{
            const paymentFile = details.paymentFile
            if (paymentFile)
              window.open(paymentFile.url, '_blank').focus();
          }}>
          Payment Documents
          </Button>

      </div>
    )
}
