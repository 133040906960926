import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import { Button, Menu, Modal, notification, Space, Form, Typography, Input, Row, Col, Select, Divider, Upload, message } from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { ShopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function FranchiseCreateOrEditPage({ onClose, details }: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [countries, setCountries] = useState([] as any);
    const [keyfilter, set_keyfilter] = useState('')
    async function handle_keypress(e) {
      const key = e.key.toUpperCase()
      if (key.length===1 && key.match(/[a-z]/i))
        set_keyfilter(key)
    }
    const history = useHistory();
    const fetchCountries = async () => {
        try {
            const { data: { data: { items } } } = await API_SERVICE.countries({ perpage: 1000 });
            setCountries(items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const [file,set_file] = useState(false)
    async function handle_upload(file){
      try {
        set_file(true)
        const data = await API_SERVICE.upload_file(file,'FLAGS')
        set_file(data.data.data.id)
      }
      catch (e) {
        set_file(false)
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
    }

    useEffect(() => {
        fetchCountries();
    }, []);
    return (
        <Modal title={
            (<Space>
                <ShopOutlined />
                <Text>{details ? 'Edit Franchise' : 'Add New Franchise'}</Text>
            </Space>)
        }
               width={800}
               visible={true}
               onOk={
                   () => {
                       form.submit();
                   }
               }
               onCancel={() => onClose()}>
            <Form
                size='large'
                form={form}
                initialValues={details || {}}
                fields={[]}
                onFinish={(value) => {

                    if (!file){
                      message.info('Country flag is required');
                      return false;
                    }
                    value.fileId = file;

                    const reqObj: any = value;
                    setSaving(true);

                    if (details) {
                        reqObj['franchiseOwner'] = {
                            ...reqObj['franchiseOwner'],
                            id: details['franchiseOwner'].id,
                        };
                        if (!reqObj['franchiseOwner'].password) {
                            delete reqObj['franchiseOwner'].password;
                        }
                        API_SERVICE.franchiseUpdate(details.id, reqObj).then((d) => {
                            onClose(true);
                        }).catch((e) => {
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        }).finally(() => {
                            setSaving(false);
                        });
                    } else {
                        API_SERVICE.franchiseCreate(reqObj).then((d) => {
                            onClose(true);
                        }).catch((e) => {
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        }).finally(() => {
                            setSaving(false);
                        });
                    }
                }}
                layout='vertical'
                requiredMark={true}
            >
                <Row gutter={20}>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Name'}
                            name={'name'}
                            rules={[
                                { required: true, message: 'Name required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Code'}
                            name={'code'}
                            rules={[
                                { required: true, message: 'Code required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Country'}
                            name={'countryId'}
                            rules={[
                                { required: true, message: 'Country required' },
                            ]}
                        >
                            <Select onInputKeyDown={handle_keypress} onBlur={()=>set_keyfilter('')}>
                                {
                                    countries.map((country: any) => {
                                        if (country.name.indexOf(keyfilter)===0)
                                        return <Select.Option
                                            value={country.id}>{country.name}</Select.Option>;
                                    })
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'City'}
                            name={'city'}
                            rules={[
                                { required: true, message: 'City required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Address'}
                            name={'address'}
                            rules={[
                                { required: true, message: 'Address required' },
                            ]}
                        >
                            <TextArea rows={2} />
                        </Form.Item>
                    </Col>
                </Row>

                <Upload
                  accept=".png,.jpg,.jpeg"
                  maxCount={1}
                  listType="picture"
                  onRemove={()=>set_file(false)}
                  beforeUpload={file => {
                     if (!file.type.includes('image')) {
                       return Upload.LIST_IGNORE;
                     }
                     else {
                       handle_upload(file)
                       return false;
                     }
                   }}
                  className="upload-list-inline">
                  {!file? <Button>Upload Flag</Button>:[]}
                </Upload>

                <>
                    <Divider />
                    <Text>Franchise Owner</Text>
                    <Row gutter={20}>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                label={'Name'}
                                name={['franchiseOwner', 'name']}
                                rules={[
                                    { required: true, message: 'Name required' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                label={'Phone'}
                                name={['franchiseOwner', 'phone']}
                                rules={[
                                    { required: true, message: 'Phone required' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                label={'Email'}
                                name={['franchiseOwner', 'email']}
                                rules={[
                                    { required: true, type: 'email', message: 'Email required' },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col md={12} sm={24} xs={24}>
                            <Form.Item
                                label={'Password'}
                                name={['franchiseOwner', 'password']}
                                rules={[
                                    { required: !details, message: 'Password required' },
                                ]}
                            >
                                <Input type={'password'} />
                            </Form.Item>
                        </Col>

                        <Col md={24} sm={24} xs={24}>
                            <Form.Item
                                label={'Address'}
                                name={['franchiseOwner', 'address']}
                                rules={[
                                    { required: true, message: 'Address required' },
                                ]}
                            >
                                <TextArea rows={2} />
                            </Form.Item>
                        </Col>
                    </Row>
                </>


            </Form>
        </Modal>
    );
}
