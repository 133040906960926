import React, { CSSProperties } from 'react';
import './index.scss';
import {
    Menu,
    Col,
    Row,
    Space,
    Typography,
    Slider,
    Checkbox,
    Breadcrumb,
    Pagination,
    message,
    notification,
} from 'antd';
import ProductCardCarousel from 'shared/components/ProductCardCarousel/ProductCardCarousel';
import {
    CodeSandboxOutlined,
    BgColorsOutlined,
    UnorderedListOutlined,
    TagOutlined,
    HeartOutlined,
    HeartFilled,
} from '@ant-design/icons';
import {
    ArrowDownward,
    ArrowDropDown,
    ColorizeOutlined,
    DirectionsBoatOutlined,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from 'client/src/services/api-service';
import { get_port } from 'client/src/services/local-storage';
import MiniCart from '../MiniCart/MiniCart';
import { globals } from 'client/src/App';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function ProductListPage(props: any) {
    const route = props.match.params;
    const product_type = route.type
        ? route.type.replace('-', ' ').title()
        : false;
    const history = useHistory();
    const { t } = useTranslation();

    const [total, set_total] = React.useState(10);
    const [fetching, set_fetching] = React.useState(false);
    const [products, set_products] = React.useState([]);
    const [attrib_color, set_attrib_color] = React.useState([]);
    const [attrib_category, set_attrib_category] = React.useState([]);
    const [attrib_price, set_attrib_price] = React.useState([0, 0]);
    const [priceMarks, set_priceMarks] = React.useState({});
    const [pagination, setPagination] = React.useState({
        page: 1,
        perpage: '10',
    });

    const [attrib, set_attrib] = React.useState({
        colors: [],
        subcategories: [],
        prices: {
            isWishlisted: false,
            priceMax: 0,
            priceMin: 0,
        },
    });
    const [data_flag, set_data_flag] = React.useState(false);

    React.useEffect(() => {
        if (attrib.prices.priceMax) {
            const obj = {};
            const arr = [attrib.prices.priceMin, attrib.prices.priceMax];
            arr.map((price, index) => {
                obj[price] = {
                    style: {
                        fontSize: '12px',
                    },
                    label: (
                        <div>
                            <div>{price}</div>
                            <div>AED</div>
                        </div>
                    ),
                };
            });
            set_priceMarks(obj);
        }
    }, [attrib]);

    async function get_attributes() {
        set_data_flag(true);
        try {
            const params = {};
            if (product_type) {
                if (product_type === 'Search') {
                    params.q = get_query('q');
                    if (!params.q) return history.push('/');
                } else params.type = 'is' + product_type.replace(' ', '');
            } else params.categoryId = route.category_id;

            const resp = await API_SERVICE.get_attributes(params);
            set_attrib_color([]);
            set_attrib_category([]);
            set_attrib_price([0, 0]);
            set_attrib(resp.data.data);
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
        set_data_flag(false);
    }

    async function get_products() {
        set_fetching(true);
        const port = get_port();

        try {
            const params = { ...pagination };
            params.portId = port.id;
            const attrib = [];
            if (attrib_color.length)
                attrib.push(
                    `colors=${JSON.stringify(attrib_color).replace(/#/g, '$')}`,
                );
            if (attrib_price[1]) {
                attrib.push(`min=${attrib_price[0]}`);
                attrib.push(`max=${attrib_price[1]}`);
            }
            if (attrib_category.length)
                attrib.push(`category_ids=${JSON.stringify(attrib_category)}`);
            //return console.log(attrib)
            if (!product_type || product_type === 'Search') {
                if (product_type === 'Search') {
                    params.q = get_query('q');
                    if (!params.q) return false;
                } else params.category_id = route.category_id;
                const resp = await API_SERVICE.get_products(params, attrib);
                if (resp.data && resp.data.data && resp.data.data.items)
                    set_products(resp.data.data.items);
                set_total(resp.data.data.total);
            } else {
                params.type = 'is' + product_type.replace(' ', '');
                const resp = await API_SERVICE.get_trending_products(
                    params,
                    attrib,
                );
                set_products(resp.data.data.items);
                set_total(resp.data.data.total);
            }
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
        set_fetching(false);
    }

    async function add_to_wishlist(product_id) {
        try {
            const resp = await API_SERVICE.add_to_wishlist(product_id);
            message.success('Added to wishlist');
            get_products();
            globals.refresh();
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    async function remove_from_wishlist(product_id: number) {
        try {
            const resp = await API_SERVICE.remove_from_wishlist(product_id);
            message.success('Removed from wishlist');
            get_products();
            globals.refresh();
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    function get_query(name) {
        const params = props.location.search;
        let value = '';
        if (params && params.includes(name + '='))
            params
                .slice(1)
                .split('&')
                .map((param) => {
                    if (param.includes(name + '=')) {
                        value = param.split('=')[1];
                    }
                });
        return value;
    }

    React.useEffect(() => {
        if (!data_flag && !fetching) {
            if (pagination.page === 1) get_products();
            else setPagination({ page: 1, perpage: '10' });
        }
    }, [attrib_color, attrib_category, attrib_price, props.location]);

    React.useEffect(() => {
        if (!data_flag && !fetching) {
            get_products();
        }
    }, [pagination]);

    React.useEffect(() => {
        get_attributes();
    }, [props.location]);

    return (
        <div className={'product-list-page-wrapper'}>
            <div className="breadcrumbs-wrapper">
                <Row>
                    {product_type ? (
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item>{product_type}</Breadcrumb.Item>
                        </Breadcrumb>
                    ) : (
                        <Breadcrumb separator=">">
                            <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                            <Breadcrumb.Item
                                href={`/home/${route.store}/${route.store_id}`}
                            >
                                {route.store}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item>{route.category}</Breadcrumb.Item>
                        </Breadcrumb>
                    )}
                </Row>
            </div>
            <Row className={'layout-wrapper'} wrap={false}>
                <Col className={'filter-wrapper'} flex={'250px'}>
                    <Title level={5}>Filter By</Title>
                    <Menu
                        onClick={() => {}}
                        style={{ width: '100%' }}
                        mode="inline"
                    >
                        <SubMenu icon={<BgColorsOutlined />} title="Color">
                            <div className="color-selector">
                                <Row gutter={[6, 6]}>
                                    {attrib.colors.map((c, index) => {
                                        function handle_select() {
                                            const active_index =
                                                attrib_color.indexOf(c);
                                            if (active_index === -1)
                                                set_attrib_color(
                                                    attrib_color.concat([c]),
                                                );
                                            else {
                                                let arr = [...attrib_color];
                                                arr.splice(active_index, 1);
                                                set_attrib_color(arr);
                                            }
                                        }
                                        return (
                                            <Col span={4}>
                                                <div
                                                    className="color-selector-box"
                                                    onClick={handle_select}
                                                    style={{
                                                        backgroundColor: c,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        color: '#F4F4F4',
                                                        fontSize: 24,
                                                    }}
                                                >
                                                    {attrib_color.includes(c)
                                                        ? '╱'
                                                        : ''}
                                                </div>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </div>
                        </SubMenu>
                        <SubMenu
                            icon={<UnorderedListOutlined />}
                            title="Category"
                        >
                            <div className="category-wrapper">
                                {attrib.subcategories.map((c, index) => {
                                    function handle_select(checked) {
                                        const active_index =
                                            attrib_category.indexOf(c.id);
                                        if (active_index === -1)
                                            set_attrib_category(
                                                attrib_category.concat([c.id]),
                                            );
                                        else {
                                            let arr = [...attrib_category];
                                            arr.splice(active_index, 1);
                                            set_attrib_category(arr);
                                        }
                                    }
                                    return (
                                        <div key={index}>
                                            <Checkbox onChange={handle_select}>
                                                {c.name}
                                            </Checkbox>
                                        </div>
                                    );
                                })}
                            </div>
                        </SubMenu>
                        <SubMenu icon={<TagOutlined />} title="Price Range">
                            {attrib.prices.priceMax ? (
                                <div className="price-range-selector">
                                    <Slider
                                        marks={priceMarks}
                                        min={attrib.prices.priceMin}
                                        range
                                        max={attrib.prices.priceMax}
                                        onChange={set_attrib_price}
                                        defaultValue={[
                                            attrib.prices.priceMin,
                                            attrib.prices.priceMax,
                                        ]}
                                    />
                                </div>
                            ) : (
                                []
                            )}
                        </SubMenu>
                    </Menu>
                </Col>
                <Col className={'list-wrapper'} flex={'auto'}>
                    <Row gutter={15} justify={'flex-start'}>
                        {products.map((product, i) => {
                            return (
                                <Col md={6} sm={12} xs={24}>
                                    <div className="centered">
                                        <div key={i} className={'product-card'}>
                                            {!product.isWishlisted ? (
                                                <div
                                                    className="wishlist-icon"
                                                    onClick={() =>
                                                        add_to_wishlist(
                                                            product.id,
                                                        )
                                                    }
                                                >
                                                    <HeartOutlined />
                                                </div>
                                            ) : (
                                                <div
                                                    className="wishlist-icon-filled"
                                                    onClick={() =>
                                                        remove_from_wishlist(
                                                            product.id,
                                                        )
                                                    }
                                                >
                                                    <HeartFilled />
                                                </div>
                                            )}
                                            <img
                                                src={
                                                    product.thumbnail
                                                        ? product.thumbnail.url
                                                        : ''
                                                }
                                                className="image"
                                                onClick={() => {
                                                    history.push({
                                                        pathname:
                                                            '/products/' +
                                                            product.id,
                                                        state: {
                                                            parent: {
                                                                category:
                                                                    'category',
                                                                subcategory:
                                                                    'subcategory',
                                                            },
                                                            product: product,
                                                        },
                                                    });
                                                }}
                                            />
                                            <div
                                                className="details-section"
                                                onClick={() => {
                                                    history.push({
                                                        pathname:
                                                            '/products/' +
                                                            product.id,
                                                        state: {
                                                            parent: {
                                                                category:
                                                                    'category',
                                                                subcategory:
                                                                    'subcategory',
                                                            },
                                                            product: product,
                                                        },
                                                    });
                                                }}
                                            >
                                                <div>
                                                    <Text
                                                        className={
                                                            'product-code'
                                                        }
                                                    >
                                                        {product.skuId}
                                                    </Text>
                                                </div>
                                                <div>
                                                    <Text
                                                        className={
                                                            'product-name'
                                                        }
                                                    >
                                                        {product.name}
                                                    </Text>
                                                </div>
                                            </div>
                                            <div className={'order-details'}>
                                                <Space>
                                                    <div className={'items'}>
                                                        <CodeSandboxOutlined />
                                                        <Text>
                                                             Min{' '}
                                                            {product.minimumOrderQuantity ||
                                                                '---'}
                                                        </Text>
                                                    </div>
                                                    <div />
                                                    <div className={'items'}>
                                                        <Text>
                                                            {product.cbnValue}{' '}
                                                            CBM
                                                        </Text>
                                                    </div>
                                                </Space>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                    <br />
                    <Pagination
                        showSizeChanger
                        defaultCurrent={1}
                        total={total}
                        onChange={(page, size) =>
                            setPagination({ page: page, perpage: size })
                        }
                    />
                </Col>
            </Row>

            <MiniCart />
        </div>
    );
}
