import React from 'react';
import moment from 'moment';
import {
    Typography,
    Space,
    Button,
    Row,
    Col,
    Card,
    DatePicker,
    Select,
    Descriptions,
    notification,
} from 'antd';
import { ShoppingOutlined, UserOutlined } from '@ant-design/icons';
import { DashboardOutlined, StoreOutlined } from '@material-ui/icons';
import './index.css';
import { PieChart } from 'react-minimal-pie-chart';
//import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from '../../services/api-service';
import { roundOf } from '../../services/utility';
import { useHistory } from 'react-router-dom';

const pie_external = 200;
const pie_internal = 130;
const formatter = new Intl.NumberFormat('en-US');
const status = [
    {
        id: 'receivedOrders',
        title: 'Received',
    },
    {
        id: 'unconfirmedOrders',
        title: 'Unconfirmed',
    },
    {
        id: 'pendingPayment',
        title: 'Pending Payment',
    },
    {
        id: 'pendingSignoff',
        title: 'Pending Sign-off',
    },
    {
        id: 'readyToShip',
        title: 'Ready to Ship',
    },
    {
        id: 'shipped',
        title: 'Shipped',
    },
    {
        id: 'delivered',
        title: 'Delivered',
    },
    {
        id: 'complete',
        title: 'Complete',
    },
];

function users_set(details) {
    if (!details.userActivity) return [];
    return [
        {
            title: 'Active (Admin Side)',
            value: details.userActivity.adminActive,
            color: '#DC202B',
        },
        {
            title: 'Active (Franchise Side)',
            value: details.userActivity.franchiseActive,
            color: '#F9BBBB',
        },
        {
            title: 'In-Active (Admin Side)',
            value: details.userActivity.adminInactive,
            color: '#AFAFAF',
        },
        {
            title: 'In-Active (Franchise Side)',
            value: details.userActivity.franchiseInactive,
            color: '#E5E5E5',
        },
    ];
}

function payment_set(details) {
    if (!details.payment) return [];
    return [
        {
            title: 'Amount Received',
            value: parseInt(details.payment.received),
            color: '#DC202B',
        },
        {
            title: 'Amount Pending',
            value: details.payment.total - parseInt(details.payment.received),
            color: '#AFAFAF',
        },
    ];
}

interface Props {
    location: any;
}

export default function Dashboard(props: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [details, set_details] = React.useState({});
    const [stats, set_stats] = React.useState({});
    const [franchises, setFranchises] = React.useState([] as any[]);
    const [franchise_id, set_franchise_id] = React.useState(-1);
    const [start_date, set_start_date] = React.useState('');
    const [end_date, set_end_date] = React.useState('');
    const [top_franchises, set_top_franchises] = React.useState([]);

    async function clear_filters() {
        set_start_date('');
        set_end_date('');
        set_franchise_id(-1);
        get_details(false);
    }

    async function get_details(use_filters = true) {
        try {
            const params = {};
            if (use_filters && start_date && end_date) {
                params.startDate = start_date.format('YYYY-MM-DD');
                params.endDate = end_date.format('YYYY-MM-DD');
                if (franchise_id > -1) params.franchiseId = franchise_id;
            }
            const resp = await API_SERVICE.dashboard_stats({ params });
            set_details(resp.data && resp.data.data);
            console.log(resp);
        } catch (e) {
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    const get_franchise_list = async () => {
        try {
            const {
                data: { data },
            } = await API_SERVICE.franchises({ isActive: true });
            setFranchises(data.items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    async function get_top_franchises() {
        try {
            const resp = await API_SERVICE.top_franchises();
            if (resp.data && resp.data.data)
                set_top_franchises(resp.data.data.items);
        } catch (e) {
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    React.useEffect(() => {
        get_details();
        get_top_franchises();
        get_franchise_list();
    }, []);

    React.useEffect(() => {
        if (details) {
            set_stats(
                details.activity || {
                    franchiseActive: '---',
                    franchiseInactive: '---',
                    portsActive: '---',
                    portsInactive: '---',
                    countriesActive: '---',
                    countriesInactive: '---',
                    supplierActive: '---',
                    supplierInactive: '---',
                },
            );
        }
    }, [details]);

    return (
        <div className="container">
            <div className="header">
                <div className="heading">
                    <DashboardOutlined
                        style={{
                            fontSize: 36,
                            margin: 8,
                        }}
                    />
                    <div>Dashboard</div>
                </div>
                <div>
                    <Row gutter={10}>
                        <Col>
                            <Button
                                type="primary"
                                onClick={() => history.push('/admin/account')}
                            >
                                Account Management
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                onClick={() => history.push('/admin/shipping')}
                            >
                                Shipping Management
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="controls">
                <div>
                    <Row align="middle" gutter={10}>
                        <Col>From</Col>
                        <Col>
                            <DatePicker
                                onChange={set_start_date}
                                value={start_date}
                            />
                        </Col>
                        <Col>to</Col>
                        <Col>
                            <DatePicker
                                onChange={set_end_date}
                                value={end_date}
                            />
                        </Col>
                        <Col>
                            <Descriptions bordered={true}>
                                <Descriptions.Item
                                    label="  Select Franchise  "
                                    style={{ padding: 0 }}
                                >
                                    <Select
                                        style={{ width: 250 }}
                                        onChange={set_franchise_id}
                                        value={
                                            franchise_id > -1
                                                ? franchise_id
                                                : null
                                        }
                                    >
                                        {franchises.map((i, index) => (
                                            <Select.Option value={i.id}>
                                                {i.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row align="middle" gutter={10}>
                        <Col>
                            <Button onClick={clear_filters}>Clear</Button>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={get_details}>
                                Apply
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="controls">
                <div
                    style={{
                        flex: 1,
                    }}
                >
                    <center>
                        <ShoppingOutlined
                            style={{
                                fontSize: 36,
                            }}
                        />
                        <div>Order Details</div>
                    </center>
                </div>
                {status.map((item, index) => (
                    <div className="stats-container">
                        <div>
                            <b>{item.title}</b>
                            <div className="stats">
                                {details.orderDetail &&
                                    details.orderDetail[item.id]}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <Row gutter={10} style={{ marginTop: 12 }}>
                <Col>
                    <Card>
                        <PieChart
                            style={{
                                height: pie_external,
                            }}
                            label={(props) =>
                                roundOf(props.dataEntry.percentage, 0) + '%'
                            }
                            labelPosition={82}
                            labelStyle={{
                                fontSize: 8,
                                fontWeight: '400',
                            }}
                            data={users_set(details)}
                        />
                        <div
                            style={{
                                position: 'relative',
                                //1.95 ~ 2
                                top: -(pie_external + pie_internal) / 1.95,
                                left: (pie_external - pie_internal) / 1.95,
                                height: pie_internal,
                                width: pie_internal,
                                borderRadius: '50%',
                                backgroundColor: '#FFF',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                fontSize: 14,
                                fontWeight: '500',
                            }}
                        >
                            <div>Total Users</div>
                            <div className="title-black">
                                {details.userActivity &&
                                    details.userActivity.all}
                            </div>
                        </div>
                        <div style={{ marginTop: -pie_internal }}>
                            {users_set(details).map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: item.color,
                                            height: 14,
                                            width: 14,
                                            borderRadius: 20,
                                            margin: 8,
                                        }}
                                    />
                                    <div>{item.title}</div>
                                </div>
                            ))}
                        </div>
                    </Card>
                </Col>

                <Col style={{ flex: 1 }}>
                    <Card>
                        <Row align="middle">
                            <Typography.Title level={4}>
                                Top Franchises by Order Amount
                            </Typography.Title>
                        </Row>
                        {top_franchises.map((i, index) => (
                            <Row key={index} justify="space-between">
                                <Col>
                                    {index + 1}{' '}
                                    <img
                                        src={i.url}
                                        className="flag-thumbnail"
                                        style={{ margin: 8 }}
                                    />{' '}
                                    {i.franchiseName}
                                </Col>
                                {/*<Col>${i && i.orderSum && formatter.format(Number(i.orderSum.toFixed(2)))}</Col>*/}
                                <Col>
                                    $
                                    {i &&
                                        i.orderSum &&
                                        formatter.format(
                                            Number(roundOf(i.orderSum)),
                                        )}
                                </Col>
                            </Row>
                        ))}
                    </Card>
                </Col>

                <Col>
                    <Card>
                        <PieChart
                            style={{
                                height: pie_external,
                            }}
                            label={(props) =>
                                roundOf(props.dataEntry.percentage, 0) + '%'
                            }
                            labelPosition={82}
                            labelStyle={{
                                fontSize: 8,
                                fontWeight: '400',
                            }}
                            data={payment_set(details)}
                        />
                        <div
                            style={{
                                position: 'relative',
                                //1.95 ~ 2
                                top: -(pie_external + pie_internal) / 1.95,
                                left: (pie_external - pie_internal) / 1.95,
                                height: pie_internal,
                                width: pie_internal,
                                borderRadius: '50%',
                                backgroundColor: '#FFF',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                fontSize: 14,
                                fontWeight: '500',
                            }}
                        >
                            <div>Total Payment</div>
                            <div className="title-black">
                                {/*USD {details.payment && formatter.format(Number(details.payment.total.toFixed(2)))}*/}
                                USD{' '}
                                {details.payment &&
                                    formatter.format(
                                        Number(roundOf(details.payment.total)),
                                    )}
                            </div>
                        </div>
                        <div style={{ marginTop: -pie_internal }}>
                            {payment_set(details).map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: item.color,
                                            height: 14,
                                            width: 14,
                                            borderRadius: 20,
                                            margin: 8,
                                        }}
                                    />
                                    <div>
                                        {item.title}
                                        <br />- USD{' '}
                                        {formatter.format(
                                            Number(roundOf(item.value)),
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Card>
                </Col>
            </Row>

            <Row gutter={10} style={{ marginTop: 12 }}>
                {[
                    {
                        title: 'Avg. Order Amt.',
                        count:
                            details.order &&
                            details.order.avg &&
                            formatter.format(Number(details.order.avg.avg)),
                    },
                    {
                        title: 'Least Order Amt.',
                        count:
                            details.order &&
                            details.order.min &&
                            formatter.format(Number(details.order.min.min)),
                    },
                    {
                        title: 'Max. Order Amt.',
                        // count: details.order && details.order.max && formatter.format(Number(details.order.max.max.toFixed(2)))
                        count:
                            details.order &&
                            details.order.max &&
                            formatter.format(
                                Number(roundOf(details.order.max.max)),
                            ),
                    },
                    {
                        title: '# of Franchises',
                        count: `${stats.franchiseActive}/${
                            stats.franchiseInactive + stats.franchiseActive
                        } Active`,
                    },
                    {
                        title: '# of Ports',
                        count: `${stats.portsActive}/${
                            stats.portsInactive + stats.portsActive
                        } Active`,
                    },
                    {
                        title: '# of Countries',
                        count: `${stats.countriesActive}/${
                            stats.countriesInactive + stats.countriesActive
                        } Active`,
                    },
                    {
                        title: '# of Suppliers',
                        count: `${stats.supplierActive}/${
                            stats.supplierInactive + stats.supplierActive
                        } Active`,
                    },
                ].map((i, index) => (
                    <Col key={index}>
                        <Card>
                            <b>{i.title}</b>
                            <div className="title-red">{i.count}</div>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
}
