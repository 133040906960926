import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import {
    Button,
    Col,
    Menu,
    notification,
    Row,
    Space,
    Checkbox,
    Tag,
    Typography,
    Form,
    Input,
    Select,
    Divider, Tree,
    Upload,
    message,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { BarsOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import AccessControl from 'client/src/services/AccessControl'
import {get_permissions} from 'client/src/services/local-storage'

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function CategoriesPage(props: any) {
    const [categories, setCategories] = useState([] as any[]);
    const [checkedKeys, setCheckedKeys] = useState([] as any[]);
    const [fetching, setFetching] = useState(false);
    const [store_details,set_store_details] = useState(false);
    const [category_details,set_category_details] = useState(false);
    const [subcategory_details,set_subcategory_details] = useState(false);
    const refresh = async () => {
        setFetching(true);
        try {
            const { data: { data: { items } } } = await API_SERVICE.categories({ perpage: 1000 });
            const d = items.map((c: any) => {
                return {
                    ...c, title: `${c.id}. ${c.name}`, key: c.id, children: c.children.map((innerC: any) => {
                        return {
                            ...innerC,
                            key: innerC.id,
                            title: `${innerC.id}. ${innerC.name}`,
                            children: innerC.children.map((subC: any) => {
                                return { ...subC, key: subC.id, title: `${subC.id}. ${subC.name}`,
                                  children: null, storeId: c.id };
                            }),
                        };
                    }),
                };
            });
            setCategories(JSON.parse(JSON.stringify(d)));
            const CheckedKeys: number[] = [];
            const HalfCheckedKeys: number[] = [];
            d.forEach((c: any) => {
                c.children.forEach((innerC: any) => {
                    innerC.children.forEach((subC: any) => {
                        //console.log(subC.name, subC.isActive, subC.id);
                        if (subC.isActive) {
                            CheckedKeys.push(subC.id);
                        }
                    });
                    const innerC_status = checked_status(innerC.children)
                    if (innerC_status==='CHECKED'){
                      innerC.isActive = true;
                      CheckedKeys.push(innerC.id);
                    }
                    else if (innerC_status==='HALF_CHECKED'){
                      innerC.isActive = 'HALF_CHECKED';
                      HalfCheckedKeys.push(innerC.id);
                    }
                    else innerC.isActive = false;
                });
                const c_status = checked_status(c.children)
                if (c_status==='CHECKED')
                    CheckedKeys.push(c.id);
                else if (c_status==='HALF_CHECKED')
                    HalfCheckedKeys.push(c.id);
            });
            setCheckedKeys({
              checked: CheckedKeys,
              halfChecked: HalfCheckedKeys
            });
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    function handle_select(e,obj) {
      console.log(obj)
      if (obj.node.parentId===null)
        set_store_details(obj.node)
      else if (obj.node.children)
        set_category_details(obj.node)
      else set_subcategory_details(obj.node)
    }

    useEffect(() => {
        refresh();
    }, []);

    return (
        <div className={'categories-wrapper page-wrapper'}>
            <Row className={'main-wrapper'}>
                <Col span={12} className={'main-section'}>
                    <div className='header-section'>
                        <Row gutter={20} align={'middle'}>
                            <Col>
                                <Title level={3}> <BarsOutlined /></Title>
                            </Col>
                            <Col>
                                <Title level={4}> Category Master</Title>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={12} className={'main-section'}>
                    <AccessControl id={9}>
                    <div className='header-section'>
                        <Row gutter={20} justify={'center'} align={'middle'}>
                            <Col>
                                <Space style={{ paddingTop: '2px' }}>
                                    <Checkbox onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }} checked={true}>Fully Enabled</Checkbox>
                                    <Checkbox onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }} indeterminate={true}>Partially Enabled</Checkbox>
                                    <Checkbox checked={false} onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>Disabled</Checkbox>
                                </Space>
                            </Col>
                        </Row>
                    </div>
                    </AccessControl>

                </Col>
            </Row>
            <Row className={'main-wrapper'}>
                <Col span={12} className={'main-section'}>
                    <div className='sub-section'>
                        <StoreForm
                          refresh={refresh}
                          details={store_details}
                          clear={()=>set_store_details(false)} />
                        <Divider />
                        <CategoryForm
                          refresh={refresh}
                          categories={categories}
                          details={category_details}
                          clear={()=>set_category_details(false)} />
                        <Divider />
                        <SubCategoryForm
                          refresh={refresh}
                          categories={categories}
                          details={subcategory_details}
                          clear={()=>set_subcategory_details(false)} />
                    </div>
                </Col>
                <Col span={12} className={'main-section'}>
                    <div className='sub-section'>
                        <AccessControl id={9}>
                        <Tree
                            checkable
                            // onExpand={onExpand}
                            // expandedKeys={expandedKeys}
                            // autoExpandParent={autoExpandParent}
                            onCheck={async (e: any, v: any) => {
                                //AccessControl
                                if (!get_permissions().includes(11)){
                                  return false
                                }
                                const { id, name, isActive, parentId } = v.node;
                                API_SERVICE.categoryUpdate(id, { name, parentId, isActive: !isActive }).then((d) => {
                                    refresh();
                                }).catch((e) => {
                                    notification.error({ message: API_SERVICE.handleErrors(e) });
                                });
                            }}
                            disabled={fetching}
                            checkStrictly
                            checkedKeys={fetching? []:checkedKeys}
                            onSelect={handle_select}
                            treeData={categories}
                        />
                        </AccessControl>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

const StoreForm = ({ refresh, details, clear }: any) => {
    const [saving, setSaving] = useState(false);
    const [file,set_file] = useState(false);
    const [default_images,set_default_images] = useState([])
    const [render_upload,set_render_upload] = useState([])
    const [form] = Form.useForm();

    async function handle_upload(file){
      try {
        set_file(true)
        const data = await API_SERVICE.upload_file(file,'CATEGORY')
        set_file(data.data.data.id)
      }
      catch (e) {
        set_file(false)
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
    }

    React.useEffect(()=>{
      set_render_upload([
        <Upload
          key={default_images.length? default_images[0].id:-1 }
          accept=".png,.jpg,.jpeg"
          defaultFileList = {default_images}
          maxCount={1}
          listType="picture"
          onRemove={()=>set_file(false)}
          beforeUpload={file => {
             if (!file.type.includes('image')) {
               return Upload.LIST_IGNORE;
             }
             else {
               handle_upload(file)
               return false;
             }
           }}
          className="upload-list-inline">
          {!file? <Button >Upload Image</Button>:[]}
        </Upload>]
      )
    },[default_images,file])

    useEffect(()=>{
      if (details){
        let file = details.file
        if (file){
          set_file(details.file.id)
          file.uid = file.id;
          file.thumbUrl = file.url;
          set_default_images([file])
        }
        form.setFieldsValue(details)
      }
      else {
        form.resetFields()
        set_file(false)
        set_default_images([])
      }
    },[details])

    return <Form
        size='large'
        fields={[]}
        form={form}
        onFinish={(value) => {
            if (!file){
              message.info('Store image is required');
              return false;
            }
            value.fileId = file;
            const reqObj: any = value;
            setSaving(true);
            if (details){
              const id = details.id
              API_SERVICE.categoryUpdate( id, reqObj ).then((d) => {
                  refresh();
              }).catch((e) => {
                  notification.error({ message: API_SERVICE.handleErrors(e) });
              }).finally(() => {
                  setSaving(false);
                  clear()
              });
            }
            else {
              API_SERVICE.categoryCreate({ ...reqObj, isActive: true }).then((d) => {
                  refresh();
              }).catch((e) => {
                  notification.error({ message: API_SERVICE.handleErrors(e) });
              }).finally(() => {
                  setSaving(false);
                  clear()
              });
            }
        }}
        layout='vertical'
        requiredMark={true}>
        {render_upload}
        <br />
        <Row gutter={20} align={'middle'}>
            <Col flex={'auto'}>
                <Form.Item
                    label={'Store name'}
                    name={'name'}
                    rules={[
                        { required: true, message: 'Name required' },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <AccessControl id={!details?10:11}>
            <Col>
                <Button loading={saving} htmlType={'submit'} type={'primary'} style={{ marginTop: '20px' }}
                        icon={<PlusCircleOutlined />}>
                    {!details? 'Add':'Save'}
                </Button>
            </Col>
            </AccessControl>
            {details && <Col>
                  <Button onClick={clear} type={'primary'} style={{ marginTop: '20px' }}
                          icon={<PlusCircleOutlined />}>
                      Cancel
                  </Button>
              </Col>}
        </Row>
    </Form>;
};

const CategoryForm = ({ refresh, categories, details, clear }: any) => {
    const [saving, setSaving] = useState(false);

    const [form] = Form.useForm();
    useEffect(()=>{
      if (details)
        form.setFieldsValue(details)
      else form.resetFields()
    },[details])

    return <Form
        form={form}
        size='large'
        fields={[]}
        onFinish={(value) => {
            const reqObj: any = value;
            setSaving(true);
            if (details){
              const id = details.id
              API_SERVICE.categoryUpdate( id, reqObj ).then((d) => {
                  refresh();
              }).catch((e) => {
                  notification.error({ message: API_SERVICE.handleErrors(e) });
              }).finally(() => {
                  setSaving(false);
                  clear()
              });
            }
            else {
              API_SERVICE.categoryCreate({ ...reqObj, isActive: true }).then((d) => {
                  refresh();
              }).catch((e) => {
                  notification.error({ message: API_SERVICE.handleErrors(e) });
              }).finally(() => {
                  setSaving(false);
              });
            }
        }}
        layout='vertical'
        requiredMark={true}>
        <Row gutter={20} align={'middle'}>
            <Col span={24}>
                <Form.Item
                    label={'Select store'}
                    name={'parentId'}
                    rules={[
                        { required: true, message: 'Store required' },
                    ]}
                >
                    <Select>
                        {
                            categories.map((c: any) => {
                                return <Select.Option value={c.id}>{c.name}</Select.Option>;
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col flex={'auto'}>
                <Form.Item
                    label={'Category name'}
                    name={'name'}
                    rules={[
                        { required: true, message: 'Name required' },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <AccessControl id={!details?10:11}>
            <Col>
                <Button loading={saving} htmlType={'submit'} type={'primary'} style={{ marginTop: '20px' }}
                        icon={<PlusCircleOutlined />}>
                    {!details? 'Add':'Save'}
                </Button>
            </Col>
            </AccessControl>
            {details && <Col>
                  <Button onClick={clear} type={'primary'} style={{ marginTop: '20px' }}
                          icon={<PlusCircleOutlined />}>
                      Cancel
                  </Button>
              </Col>}
        </Row>
    </Form>;
};

const SubCategoryForm = ({ refresh, categories, details, clear }: any) => {
    const [saving, setSaving] = useState(false);
    const [subCategories, setSubCategories] = useState([]);

    const [form] = Form.useForm();
    useEffect(()=>{
      if (details){
        const category = categories.find((c: any) => c.id === details.storeId)
        setSubCategories(category.children)
        form.setFieldsValue(details)
      }
      else form.resetFields()
    },[details])

    return <Form
        form={form}
        size='large'
        fields={[]}
        form={form}
        onFinish={(value) => {
            const reqObj: any = value;
            setSaving(true);
            if (details){
              const id = details.id
              API_SERVICE.categoryUpdate( id, reqObj ).then((d) => {
                  refresh();
              }).catch((e) => {
                  notification.error({ message: API_SERVICE.handleErrors(e) });
              }).finally(() => {
                  setSaving(false);
                  clear()
              });
            }
            else {
              API_SERVICE.categoryCreate({ ...reqObj, isActive: true }).then((d) => {
                  refresh();
              }).catch((e) => {
                  notification.error({ message: API_SERVICE.handleErrors(e) });
              }).finally(() => {
                  setSaving(false);
              });
            }
        }}
        layout='vertical'
        requiredMark={true}>
        <Row gutter={20} align={'middle'}>
            <Col span={24}>
                <Form.Item
                    label={'Select store'}
                    name={'storeId'}
                    rules={[
                        { required: true, message: 'Store required' },
                    ]}
                >
                    <Select onChange={(e) => {
                        form.resetFields(['parentId']);
                        setSubCategories(categories.find((c: any) => c.id === e).children);
                    }
                    }>
                        {
                            categories.map((c: any) => {
                                return <Select.Option value={c.id}>{c.name}</Select.Option>;
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    label={'Select Category'}
                    name={'parentId'}
                    rules={[
                        { required: true, message: 'Category required' },
                    ]}
                >
                    <Select>
                        {
                            subCategories.map((c: any) => {
                                return <Select.Option value={c.id}>{c.name}</Select.Option>;
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col flex={'auto'}>
                <Form.Item
                    label={'Subcategory name'}
                    name={'name'}
                    rules={[
                        { required: true, message: 'Name required' },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <AccessControl id={!details?10:11}>
            <Col>
                <Button loading={saving} htmlType={'submit'} type={'primary'} style={{ marginTop: '20px' }}
                        icon={<PlusCircleOutlined />}>
                    {!details? 'Add':'Save'}
                </Button>
            </Col>
            </AccessControl>
            {details && <Col>
                  <Button onClick={clear} type={'primary'} style={{ marginTop: '20px' }}
                          icon={<PlusCircleOutlined />}>
                      Cancel
                  </Button>
              </Col>}
        </Row>
    </Form>;
};


function checked_status(children) {
  let all_active = true
  let all_empty = true
  children.map((child)=>{
    if (child.isActive==='HALF_CHECKED'){
      console.log(child)
      all_active = false
      all_empty = false
    }
    else if (child.isActive)
      all_empty = false
    else all_active = false
  })
  if (all_empty)
    return 'ALL_EMPTY'
  else if (all_active)
    return 'CHECKED'
  else return 'HALF_CHECKED'
}
