import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Space, Descriptions, Typography, Dropdown, InputNumber, Menu, Upload, Pagination, Table, Row, Col, Tag, Checkbox, Select, Modal, notification, message } from 'antd';
import { DeleteOutlined, UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import API_SERVICE from '../../../services/api-service';

const { Text, Title, Link } = Typography;
export default function READY_TO_SHIP({ details }: any) {



    return (
      <div>
        <div><b>Status</b> : Order Shipped</div>
        <br/>
        <div style={{
          display: 'flex',
          }} >
            <div onClick={()=>{
              const paymentFile = details.paymentFile
              if (paymentFile)
                window.open(paymentFile.url, '_blank').focus();
              }}>
              <Typography.Link>Download Payment Docs</Typography.Link>
            </div>
            <div style={{width:12}} />
            <div onClick={()=>{
              const shipmentFile = details.shipmentFile
              if (shipmentFile)
                window.open(shipmentFile.url, '_blank').focus();
              }}>
              <Typography.Link>Download Shipping Docs</Typography.Link>
            </div>
        </div>
      </div>
    )
}
