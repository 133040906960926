import React, { useEffect, useState } from 'react';
import './KnowledgeBase.scss';
import {
    Col,
    Form,
    Input,
    Modal,
    notification,
    Row,
    Select,
    Space,
    TreeSelect,
    Typography,
} from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import FileUpload from 'shared/components/FileUpload';

const { Text } = Typography;
export default function UploadDocumentPage({ onClose }: any) {
    const [form] = Form.useForm();
    const [franchiseList, setFranchiseList] = useState([]);
    const [franchiseId, setFranchiseId] = useState([]);
    const [documentType, setDocumentType] = useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState();
    const [documentId, setDocumentId] = useState();
    const [document, setDocument] = useState();
    const documentUpdate = ({ id, url }) => {
        setDocumentId(id);
        setDocument(url);
    };
    const x = franchiseList.map((item: any) => ({
        title: item?.user?.franchise?.name,
        value: item?.user?.id,
    }));
    const allFranchiseIds = x.map(({ value }) => value);

    const getDocumentTypes = async () => {
        try {
            const params = {
                type: 'admin',
            };
            const data = await API_SERVICE.get_documents_type(params);
            setDocumentType(data.data.data);
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
                placement: 'bottomRight',
            });
        }
    };
    const getFranchiseList = async () => {
        try {
            const data = await API_SERVICE.get_franchise_list();
            setFranchiseList(data.data.data);
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
                placement: 'bottomRight',
            });
        }
    };
    const uploadDocument = async (data: any) => {
        try {
            const params = {
                title: data.title,
                franchiseId: franchiseId,
                documentTypeId: selectedDocumentType,
                fileId: documentId,
            };
            await API_SERVICE.upload_documents_admin(params);
            notification.success({
                message: 'Uploaded Successfully',
                placement: 'bottomRight',
            });
            onClose(true);
        } catch (err) {
            notification.error({
                message: API_SERVICE.handleErrors(err),
                placement: 'bottomRight',
            });
        }
    };
    const handleChange = (value: any) => {
        setSelectedDocumentType(value);
    };
    useEffect(() => {
        getDocumentTypes();
        getFranchiseList();
    }, []);
    return (
        <Modal
            title={
                <Space>
                    <ShopOutlined />
                    <Text>{'Upload Document'}</Text>
                </Space>
            }
            width={500}
            visible={true}
            onOk={() => {
                form.submit();
            }}
            onCancel={() => onClose()}
        >
            <Form
                size="large"
                form={form}
                fields={[]}
                onFinish={uploadDocument}
                layout="vertical"
                requiredMark={true}
            >
                <Row gutter={20}>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Document Title'}
                            name={'title'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Document Type required',
                                },
                            ]}
                        >
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item label="Select Franchise">
                            <TreeSelect
                                className="inputFields text-center buyxgetyselect"
                                allowClear={true}
                                // placeholder="Select Franchise"
                                treeCheckable={true}
                                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                                dropdownStyle={{ maxHeight: '300px' }}
                                onChange={(ids) => setFranchiseId(ids)}
                                value={franchiseId}
                                maxTagCount={'responsive'}
                                maxTagPlaceholder={(omittedValues) =>
                                    `+ ${omittedValues.length} Products ...`
                                }
                                treeData={[
                                    {
                                        title:
                                            franchiseId.length > 0 ? (
                                                <span
                                                    onClick={() =>
                                                        setFranchiseId([])
                                                    }
                                                    style={{
                                                        display: 'inline-block',
                                                        color: '#286FBE',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Unselect all
                                                </span>
                                            ) : (
                                                <span
                                                    onClick={() =>
                                                        setFranchiseId(
                                                            allFranchiseIds,
                                                        )
                                                    }
                                                    style={{
                                                        display: 'inline-block',
                                                        color: '#286FBE',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    Select all
                                                </span>
                                            ),
                                        value: 'xxx',
                                        disabled: true,
                                        checkable: false,
                                    },
                                    ...x,
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Select Document Type'}
                            name={'type'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Document Type required',
                                },
                            ]}
                        >
                            <Select
                                onChange={handleChange}
                                options={documentType.map((item: any) => {
                                    return {
                                        value: item.id,
                                        label: item.name,
                                    };
                                })}
                            ></Select>
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item label="Upload File">
                            <FileUpload
                                type={'DOCUMENT'}
                                callback={documentUpdate}
                                style={{ display: 'block' }}
                                onChange={(id: any) => {
                                    setDocumentId(id);
                                }}
                            >
                                <div className="fileUploadDesign">
                                    Please Choose File
                                </div>
                            </FileUpload>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
