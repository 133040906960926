import React, { CSSProperties, useState, useEffect } from 'react';
import './index.scss';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import { Carousel, Space, Typography, Modal, Collapse, List, notification } from 'antd';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import {CloseOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from 'client/src/services/api-service';
import {get_port} from 'client/src/services/local-storage';

const { Text, Title } = Typography;
const { Panel } = Collapse;

//"port-preference" is hardcoded in multiple files.
const localkey = 'port-preference'
export default function PortSelector(props: any) {
    const history = useHistory();
    const { t } = useTranslation();
    const stored_port = get_port();
    const [selection,select] = useState(stored_port)
    const [data,set_data] = useState([])
    const [loading,set_loading] = React.useState(true)
    async function get_ports() {
      try {
          const resp = await API_SERVICE.get_ports();
          set_data(resp.data.data.items);
          set_loading(false);
      } catch (e) {
        console.log(e)
          notification.error({
              message: t(API_SERVICE.handleErrors(e)),
          });
          set_loading(false);
      }
    }
    useEffect(()=>{
      get_ports()
    },[])
    useEffect(()=>{
      if (selection.id!=stored_port.id)
        localStorage.setItem(
          localkey,
          JSON.stringify(selection)
        );
      history.push()
    },[selection])
    return (
        <Modal zIndex={1200} width={380} closable={false} visible={props.visible} footer={null}>
          <div className="port-selector-container">
            <div className="series">
              <div className="title-container">
                <DirectionsBoatOutlined/>
                <div className="title">Availability at Port</div>
              </div>
              <CloseOutlined onClick={props.close} />
            </div>
            <div className="collapse-container">
            <Collapse expandIconPosition="right" ghost>
              {
                data.map((item:any,index)=>{
                  return (
                    <Panel key={index} header={item.name}>
                      <List
                        className="panel"
                        size="small"
                        split={false}
                        dataSource={item.ports}
                        renderItem={(port:any) =>
                          <List.Item>
                            {
                              selection.id===port.id?
                              <Link>{port.name}</Link>
                              :<Link onClick={()=>select(port)}>
                                <Text>{port.name}</Text>
                              </Link>
                            }
                          </List.Item>
                        }
                      />
                    </Panel>
                  );
                })
              }
            </Collapse>
            </div>
          </div>
        </Modal>
    );
}
