import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import { Button, Menu, Modal, notification, Space, Form, Typography, Input, Row, Col, Select, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { ShopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function ContainerCreateOrEditPage({ onClose, details }: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [countries, setCountries] = useState([] as any);
    const history = useHistory();
    const fetchCountries = async () => {
        try {
            const { data: { data: { items } } } = await API_SERVICE.countries({ perpage: 1000 });
            setCountries(items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };
    useEffect(() => {
        fetchCountries();
    }, []);
    return (
        <Modal title={
            (<Space>
                <ShopOutlined />
                <Text>{details ? 'Edit Container Type' : 'Add New Container Type'}</Text>
            </Space>)
        }
               width={800}
               visible={true}
               onOk={
                   () => {
                       form.submit();
                   }
               }
               onCancel={() => onClose()}>
            <Form
                size='large'
                form={form}
                initialValues={details || {}}
                fields={[]}
                onFinish={(value) => {
                    const reqObj: any = value;
                    setSaving(true);

                    if (details) {
                        API_SERVICE.containerUpdate(details.id, reqObj).then((d) => {
                            onClose(true);
                        }).catch((e) => {
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        }).finally(() => {
                            setSaving(false);
                        });
                    } else {
                        API_SERVICE.containerCreate({ ...reqObj, isActive: true }).then((d) => {
                            onClose(true);
                        }).catch((e) => {
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        }).finally(() => {
                            setSaving(false);
                        });
                    }
                }}
                layout='vertical'
                requiredMark={true}
            >
                <Row gutter={20}>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Name'}
                            name={'description'}
                            rules={[
                                { required: true, message: 'Name required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Height in ft'}
                            name={'height'}
                            rules={[
                                { required: true, message: 'Height required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'CBM'}
                            name={'cbm'}
                            rules={[
                                { required: true, message: 'CBM required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Round-off CBM'}
                            name={'roundOffCbm'}
                            rules={[
                                { required: true, message: 'Round-off CBM required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
