import React from 'react';
import moment from 'moment';
import {
    Typography,
    Space,
    Button,
    Row,
    Col,
    Card,
    DatePicker,
    Select,
    Descriptions,
    notification,
} from 'antd';
import { ShoppingOutlined, UserOutlined } from '@ant-design/icons';
import { DashboardOutlined } from '@material-ui/icons';
import './index.css';
import { PieChart } from 'react-minimal-pie-chart';
//import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from '../../services/api-service';
import { roundOf } from '../../services/utility';
import { useHistory } from 'react-router-dom';

const pie_external = 200;
const pie_internal = 130;
const formatter = new Intl.NumberFormat('en-US');
const stats = [
    {
        id: 'placedOrders',
        title: 'Placed',
    },
    /*
  {
    id: 'incompleteOrders',
    title: 'Incomplete',
  },
  */
    {
        id: 'unconfirmedOrders',
        title: 'Unconfirmed',
    },
    {
        id: 'pendingPayment',
        title: 'Pending Payment',
    },
    {
        id: 'processing',
        title: 'Being Processed',
    },
    {
        id: 'shipped',
        title: 'Shipped',
    },
    {
        id: 'delivered',
        title: 'Delivered',
    },
    {
        id: 'complete',
        title: 'Complete',
    },
];

function payment_set(details) {
    if (!details.payment) return [];
    return [
        {
            title: 'Amount Received',
            value: parseInt(details.payment.received),
            color: '#DC202B',
        },
        {
            title: 'Amount Pending',
            value: details.payment.total - parseInt(details.payment.received),
            color: '#AFAFAF',
        },
    ];
}

interface Props {
    location: any;
}

export default function Dashboard(props: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [details, set_details] = React.useState({});
    const [start_date, set_start_date] = React.useState('');
    const [end_date, set_end_date] = React.useState('');
    const [franchise, set_franchise] = React.useState({});

    async function clear_filters() {
        set_start_date('');
        set_end_date('');
        get_details(false);
    }

    async function get_details(use_filters = true) {
        try {
            const params = {};
            if (use_filters && start_date && end_date) {
                params.startDate = start_date.format('YYYY-MM-DD');
                params.endDate = end_date.format('YYYY-MM-DD');
            }
            const resp = await API_SERVICE.client_dashboard_stats({ params });
            if (resp.data && resp.data.data) {
                set_details(resp.data && resp.data.data);
                if (resp.data.data.franchise)
                    set_franchise(resp.data.data.franchise);
            }
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }
    React.useEffect(() => {
        get_details();
    }, []);

    return (
        <div className="container">
            <div className="header">
                <div className="heading">
                    <DashboardOutlined
                        style={{
                            fontSize: 36,
                            margin: 8,
                        }}
                    />
                    <div>Dashboard</div>
                </div>
                <div>
                    <Row gutter={10}>
                        <Col>
                            <Button
                                type="primary"
                                onClick={() => history.push('/orders')}
                            >
                                My Orders
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                onClick={() => history.push('/account')}
                            >
                                Account Management
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                type="primary"
                                onClick={() => history.push('/shipping')}
                            >
                                Shipping Management
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="controls">
                <div>
                    <Row align="middle" gutter={10}>
                        <Col>From</Col>
                        <Col>
                            <DatePicker
                                onChange={set_start_date}
                                value={start_date}
                            />
                        </Col>
                        <Col>to</Col>
                        <Col>
                            <DatePicker
                                onChange={set_end_date}
                                value={end_date}
                            />
                        </Col>
                        {/*<Col>
                      <Descriptions bordered={true}>
                        <Descriptions.Item label=" Select Franchise " style={{padding:0}}>
                          <Select />
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>*/}
                    </Row>
                </div>
                <div>
                    <Row align="middle" gutter={10}>
                        <Col>
                            <Button onClick={clear_filters}>Clear</Button>
                        </Col>
                        <Col>
                            <Button type="primary" onClick={get_details}>
                                Apply
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="controls">
                <div
                    style={{
                        flex: 1,
                    }}
                >
                    <center>
                        <ShoppingOutlined
                            style={{
                                fontSize: 36,
                            }}
                        />
                        <div>Order Details</div>
                    </center>
                </div>
                {stats.map((item, index) => (
                    <div className="stats-container">
                        <div>
                            <b>{item.title}</b>
                            <div className="stats">
                                {details.orderDetail &&
                                    details.orderDetail[item.id]}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <Row gutter={10} style={{ marginTop: 12 }}>
                <Col>
                    <Card>
                        <PieChart
                            style={{
                                height: pie_external,
                            }}
                            label={(props) =>
                                roundOf(props.dataEntry.percentage, 0) + '%'
                            }
                            labelPosition={82}
                            labelStyle={{
                                fontSize: 8,
                                fontWeight: '400',
                            }}
                            data={payment_set(details)}
                        />
                        <div
                            style={{
                                position: 'relative',
                                //1.95 ~ 2
                                top: -(pie_external + pie_internal) / 1.95,
                                left: (pie_external - pie_internal) / 1.95,
                                height: pie_internal,
                                width: pie_internal,
                                borderRadius: pie_internal,
                                backgroundColor: '#FFF',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                fontSize: 14,
                                fontWeight: '500',
                            }}
                        >
                            <div>Total Payment</div>
                            <div className="title-black">
                                USD{' '}
                                {details.payment &&
                                    formatter.format(
                                        Number(roundOf(details.payment.total)),
                                    )}
                            </div>
                        </div>
                        <div style={{ marginTop: -pie_internal }}>
                            {payment_set(details).map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: item.color,
                                            height: 14,
                                            width: 14,
                                            borderRadius: 20,
                                            margin: 8,
                                        }}
                                    />
                                    <div>
                                        {item.title}
                                        <br />- USD{' '}
                                        {formatter.format(
                                            Number(roundOf(item.value)),
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Card>
                </Col>

                {/* <Col>
                    <Card>
                        <div className="title-black">
                            <UserOutlined /> Franchise Users
                        </div>
                        <div className="active-count">
                            <b>
                                {franchise.active}/{franchise.total}
                            </b>{' '}
                            Active
                        </div>
                        <div className="inactive-count">
                            <b>
                                {franchise.inactive}/{franchise.total}
                            </b>{' '}
                            In-active
                        </div>
                    </Card>
                </Col> */}
            </Row>
        </div>
    );
}
