import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Space, Typography, InputNumber, Dropdown, Menu, Pagination, Table, Row, Col, Tag, Checkbox, Select, Modal, notification, message } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import { useHistory } from 'react-router-dom';

const { Text, Title, Link } = Typography;
export default function OrderDetails({ onClose, details }: any) {
  const history = useHistory();
  const [quantity_stack,set_quantity_stack] = React.useState([])
  const [order_stack,set_order_stack] = React.useState(details.orderDetails)
  const [loading,set_loading] = React.useState(false)
  const [deletedProductsIds,set_deletedProductsIds] = React.useState([])
  const [modifiedProducts,set_modifiedProducts] = React.useState([])
  const [add_flag,set_add_flag] = React.useState(false)

  const [search, setSearch] = useState('');
  const [fetching, setFetching] = useState(false);
  const [exit_flag,set_exit] = useState([]);
  const [product_list,set_product_list] = React.useState([])
  const [product_ids,set_product_ids] = React.useState([])
  const [pagination, setPagination] = useState({ page: 1, perpage: '10' });
  const [total, setTotal] = useState(10);



  async function get_all_products(){
    try {
        setFetching(true);
        const params = { ...pagination };
        if (search)
          params['q'] = search;
        const { data: { data } } = await API_SERVICE.get_admin_products(params);
        set_product_list(data.items)
        setTotal(data.total);
    } catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
    } finally {
        setFetching(false);
    }
  }

  async function handle_select(event,product){
    const checked = event.target.checked
    let arr = []
    if (checked){
      arr = [ ...order_stack ]
      if (!arr.includes(product.id))
        arr.push({
          quantity: 1,
          product: product,
        })
    }
    else {
      order_stack.map((i)=>{
        if (i.product.id!=product.id)
          arr.push(i)
      })
    }

    set_order_stack(arr);
    setModifiedIds(product.id, 1);
  }
   
  async function save_cart(){
    set_loading(true)
    try {
      const data = await API_SERVICE.pi_approval(details.id,
        {orderDetails: quantity_stack}
      )
      message.info("Cart Updated")
    }
    catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
    set_loading(false)
  }

  async function update_order(){
    set_loading(true);
    const reqObj = {
      deletedProductsIds,
      modifiedProducts
    }

    try {
      await API_SERVICE.regenerate_pi(details.id,
        reqObj
      )
      message.info("Order Updated");
      history.push({pathname: '/orders'})
    }
    catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
    set_loading(false)
  }

  function setDeletedIds (id) {
    let new_deletedProductsIds = deletedProductsIds;
    !new_deletedProductsIds.includes(id) && new_deletedProductsIds.push(id);
    set_deletedProductsIds(new_deletedProductsIds);

    //check if it exist in modified array
    let new_modifiedProducts = modifiedProducts;
    let currentIndex = new_modifiedProducts.findIndex((product) => {
      return product.id == id;
    });
    if(currentIndex > -1){
      new_modifiedProducts.splice(currentIndex, 1);
    }
    set_modifiedProducts(new_modifiedProducts);
  }

  function setModifiedIds (id, quantity) {
    let new_modifiedProducts = modifiedProducts;
    let currentIndex = new_modifiedProducts.findIndex((product) => {
      return product.id == id;
    });
    if(currentIndex > -1){
      new_modifiedProducts.splice(currentIndex, 1);
    }
    new_modifiedProducts.push({id, quantity});
    set_modifiedProducts(new_modifiedProducts);


    //check if it exist in deleted array
    let new_deletedProductsIds = deletedProductsIds;
    if(new_deletedProductsIds.includes(id)){
      new_deletedProductsIds.splice(new_deletedProductsIds.indexOf(id), 1);
    }
    set_deletedProductsIds(new_deletedProductsIds);
  }

  const columns = [
      {
          title: 'S.No',
          key: 'sno',
          align: 'center',
          render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
      },
      {
          title: 'Product Name',
          key: 'name',
          render: (a: any, v: any, index: number) => <Text>{a.product.name}</Text>,
      },
      {
          title: 'Thumbnail',
          key: 'thumbnail',
          align: 'center',
          render: (a: any, v: any, index: number) => <img src={a.product.thumbnail && a.product.thumbnail.url} className="thumbnail"/>,
      },
      {
          title: 'SKU-ID',
          key: 'sku',
          render: (a: any, v: any, index: number) => <Text>{a.product.skuId}</Text>,
      },
      {
          title: "CBM",
          key: 'cbm',
          render: (a: any, v: any, index: number) => <Text>{(a.product.cbnValue).toFixed(3)} CBM</Text>,
      },
      {
          title: 'Price',
          key: 'price',
          render: (a: any, v: any, index: number) => {
              return (
                <Text>
                  ${(Number(a.product[
                    {
                      'ON_STOCK': 'dubaiPrice',
                      'FROM_SOURCE': 'portPrice',
                      'PRE_ORDER': 'customPrice'
                    }[details.type]
                  ])).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </Text>
              );
          }
      },
      {
          title: 'Quantity',
          key: 'quantity',
          render: (a: any, v: any, index: number) =>
            
            <div>
              {
              details.status == 'PI_CANCELLED' 
              ? 
              <InputNumber
              key={a.product.id}
              value={a.quantity}
              style={{
                textAlign: 'center',
                width: 75,
              }}
              onChange={(i)=>{
                let quantity = i
                let arr = [ ...order_stack ]
                arr[index].quantity = parseInt(quantity);
                set_order_stack(arr);
                setModifiedIds(a.productId || a.product.id, parseInt(quantity));
              }}
              /> 
              :
              <div>{a.quantity}</div>
            }
            </div>
           
      },
      {
          title: 'Total',
          key: 'total',
          render: (a: any, v: any, index: number) => {
            const price = a.product[
                {
                  'ON_STOCK': 'dubaiPrice',
                  'FROM_SOURCE': 'portPrice',
                  'PRE_ORDER': 'customPrice'
                }[details.type]
              ]
            return (
              <Text>
              {
                (quantity_stack && quantity_stack.length && quantity_stack[index] && quantity_stack[index].quantity)?
                ('$'+(Number(quantity_stack[index].quantity*price)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))
                :0
              }
              </Text>
            )
          }
      },

      details.status == 'PI_CANCELLED' ? {
        title: 'Action',
        key: 'action',
        align: 'center',
        render: (a: any, v: any, index: number) =>
        <DeleteOutlined
          style={{
            fontSize:18
          }}
          onClick={()=>{
            let arr = [ ...order_stack ]
            arr.splice(index,1)
            set_order_stack(arr);
            setDeletedIds(a.productId || a.product.id)
          }}
          />,
        }
      :
      {}
     
  ]

    React.useEffect(()=>{
      let arr = []
      let ids = []
      order_stack.map((order,index)=>{
        arr.push({
          productId: order.product.id,
          quantity: order.quantity,
        })
        ids.push(order.product.id)
      })
      set_quantity_stack(arr)
      set_product_ids(ids)
    },[order_stack])


    React.useEffect(()=>{
      get_all_products()
    },[pagination, search])

    return (
      <>
      <div>
        <Row justify="space-between">
          <div className="title-desc">Edit customer Cart</div>
          {
            details.status == "PI_CANCELLED" && 
            <Button type="primary" onClick={()=>set_add_flag(true)} icon={<PlusCircleOutlined />}>Add New</Button>
          }
        </Row>
        <br/>
        <Table scroll={{ x:true }} pagination={false} dataSource={order_stack} columns={columns}>
        </Table>
        <br/>
        {
          details.status == "PI_CANCELLED" && 
            <div style={{display:'flex', justifyContent:'flex-end'}}>
              <Button type="primary" onClick={()=>{update_order()}}>Update Order</Button>
            </div>
        }
        <Modal
          destroyOnClose={true}
          visible={add_flag}
          width={720}
          onCancel={()=>{
            /*onClose()*/
            set_exit(
            <Modal
              closable={false}
              zIndex={1200}
              width={300}
              centered
              okText="Yes"
              cancelText="No"
              onOk={()=>{
                set_add_flag(false)
                set_exit([])
              }}
              onCancel={()=>set_exit([])}
              visible={true}>
              <Text>Are you sure you want to exit ?</Text>
            </Modal>)
          }}
          footer={[
            <Button type="primary" key="done" onClick={()=>set_add_flag(false)}>Done</Button>
          ]} >
          <div>
              <br/>
              <Search
                  placeholder='Search Products here'
                  disabled={fetching}
                  size='large'
                  onSearch={
                      (e) => {
                        setSearch(e);
                      }
                  }
              />
              <br/>
              <br/>
              {
                product_list.map((product,index)=>{
                  return (
                    <Row key={index} gutter={[10,10]} align="middle">
                      <Col><Checkbox key={product.id} defaultChecked={product_ids.includes(product.id)} onChange={(e)=>handle_select(e,product)} /></Col>
                      <Col><img src={product.thumbnail && product.thumbnail.url} className="thumbnail" /></Col>
                      <Col>
                        <div>{product.name}</div>
                        <div>{product.skuId}</div>
                      </Col>
                    </Row>
                  )
                })
              }
              <div style={{display:'flex', justifyContent:'flex-end'}}>
                <Pagination disabled={fetching} showSizeChanger key="4" defaultCurrent={1} total={total} onChange={(page,size)=>setPagination({ page:page, perpage: pagination.perpage })} />
              </div>
          </div>
        </Modal>
      </div>
      {exit_flag}
      </>
    )
}
