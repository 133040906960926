declare const API_URL: string;
export default {
    //for dev
    // ApiUrl: "http://65.1.67.235:8080",
    // SocketAddr: "http://65.1.67.235:3001",

    //for prod
    ApiUrl: 'https://api-oneapp.danubehome.com',
    SocketAddr: 'https://nf-oneapp.danubehome.com/',

    // SocketAddr: "https://api-oneapp.danubehome.com:8080",
};
