import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import { Button, Space, Typography, Collapse, Row, Col, notification, Pagination ,Input} from 'antd';
import { PlusCircleOutlined, ShoppingOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from '../../services/api-service';
import {roundOf} from '../../services/utility';

import dimensions from 'shared/assets/dimensions.png';
import box from 'shared/assets/box.png';
import basket from 'shared/assets/add-to-basket.png';
import port from 'shared/assets/port.png';
import container from 'shared/assets/container.png';

import CartSummary from './CartSummary';
import BulkUpload from './BulkUpload';
const { Search } = Input;
const { Text, Title, Link } = Typography;
const { Panel } = Collapse;
const formatter = new Intl.NumberFormat('en-US');
function toFixed(n, i = 2) {
    let str = '' + n;
    let index = str.indexOf('.');
    if (index > -1)
        return str.substring(0, (index + 1) + i);
    return str;
}

let cartId;
export default function Cart(props) {

    const history = useHistory();
    const { t } = useTranslation();
    const [data, set_data] = React.useState({});
    const [data_header, set_data_header] = React.useState({});
    const [products, set_products] = React.useState({});
    const [onStock_products, set_onStock_products] = React.useState({});
    const [cart_object, set_cart] = React.useState([]);
    const [loading, set_loading] = React.useState(true);
    const [pagination, setPagination] = useState({ page: 1, perpage: '10' });
    const [total, setTotal] = useState(10);
    


    async function get_cart() {
        try {
            const resp = await API_SERVICE.get_cart();
            if (resp.data && resp.data.data) {
                set_data(resp.data.data);
                let cartId = resp.data.data.onStock.items.length > 0 ? resp.data.data.onStock.items[0].id : null;
            }
        } catch (e) {
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        } finally {
            set_loading(false);
        }
    }


    async function get_headers() {
        try {
            const resp = await API_SERVICE.get_headers({ type: 'ON_STOCK' });
            if (resp.data && resp.data.data) {
                set_data_header(resp.data.data);
            }
        } catch (e) {
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        } finally {
            set_loading(false);
        }
    }

    async function get_cartproducts(page?){
        const params = { ...pagination, type: 'ON_STOCK' };
        if (page) {
            params['page'] = page;
        }

        try {
            const resp = await API_SERVICE.get_cartproducts(params);
            if (resp.data && resp.data.data) {
                set_onStock_products(resp.data.data);
                setTotal(resp.data.data.products.total);
                setPagination({ perpage: resp.data.data.products.perpage, page: resp.data.data.products.page });
                
            }
        } catch (e) {
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        } finally {
            set_loading(false);
        }
    }
    async function search_by_name(value) {
        const params = { ...pagination, type: 'ON_STOCK', search: value};

        try {
            const resp = await API_SERVICE.get_cartproducts(params);
            if (resp.data && resp.data.data) {
                set_onStock_products(resp.data.data);
                setTotal(resp.data.data.products.total);
                setPagination({ perpage: resp.data.data.products.perpage, page: resp.data.data.products.page });

            }
        } catch (e) {
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        } finally {
            set_loading(false);
        }
        }

    {/*} async function upload__order() {
    try {
      const resp = await API_SERVICE.upload_bulk_order();
      if (resp.data && resp.data.data){
        set_data(resp.data.data);
      }
      set_loading(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: t(API_SERVICE.handleErrors(e)),
      });
      set_loading(false);
    }
  }
*/
    }

    useEffect(() => {
        // get_cart();
        get_cartproducts();
        get_headers();
    }, []);


    if (loading) return (
        <div></div>
    );
    return (
        <div>
            <div className='centered'>
                <Title style={{ margin: 16 }} level={3}><ShoppingOutlined /> SHOPPING CART</Title>
            </div>
            <div className='series' style={{ margin: 8 }}>
                <CartBlock data={data_header} title='Dubai' get_cartproducts={get_cartproducts} onClick={() => {
                    const elem = document.getElementById('onStock');
                    elem.scrollIntoView();
                }} />
                {
                    /* <CartBlock data={data.fromSource.header} title="Ready Source" onClick={()=>{
                        const elem = document.getElementById("fromSource");
                        elem.scrollIntoView();
                        }} />
                      <CartBlock data={data.preOrder.header} title="Customize Source" onClick={()=>{
                        const elem = document.getElementById("preOrder");
                        elem.scrollIntoView();
                        }} />*/}


            </div>

            <div className="cart-search-bar">
                <Search
                    placeholder="Search by name"
                    className="search-bar-something"
                    enterButton="Search"
                    size="large"
                    onSearch={(value)=>search_by_name(value)}
                />
            </div>
            {
                (onStock_products && onStock_products.products && onStock_products.products.items && onStock_products.products.items.length) ?
                    <div className='collapse-container'>
                        <div id='onStock' className='divider-black'>Cart Summaries - Products | Dubai</div>


                        <Collapse accordion={true} defaultActiveKey={['onStock0']} expandIconPosition='right' ghost
                                  accordion>
                            <Panel className='summary-header' key={'onStock' + 0} header={
                                <div>{onStock_products.otherDetails.port.name} | {roundOf(onStock_products.otherDetails.totalCbmCart.cbmSum, 3)} CBM
                                    | Balance {onStock_products.otherDetails.cbmBalance} CBM |
                                    ${formatter.format(Number(onStock_products.otherDetails.totalCbmCart.amount.toFixed(2)))} | {onStock_products.otherDetails.totalCbmCart.totalItems} Item
                                    | {(onStock_products.otherDetails.cartContainer && onStock_products.otherDetails.cartContainer.quantity) || '0'} Container
                                    | {parseFloat(onStock_products.otherDetails.cbmBalance) > 0 ? 'Edit Cart to Checkout' : 'Checkout Now'} </div>
                            }>


                                {
                                    <CartSummary id='ON_STOCK' products={onStock_products.products.items}
                                                 boxQuantity={onStock_products.products.items[0].boxQuantity}
                                                 totalProducts={onStock_products.products.items.length}
                                                 refresh={get_cartproducts}
                                                 otherDetails={onStock_products.otherDetails} />
                                }

                                <Pagination key='4' defaultCurrent={1} total={total} pageSize={pagination.perpage}
                                            onChange={(page, size) => get_cartproducts(page)} />
                            </Panel>
                        </Collapse>
                    </div>
                    : <div id='onStock' />
            }
            {/*
        (data.fromSource && data.fromSource.items && data.fromSource.items.length)?
          <div className="collapse-container">
            <div id="fromSource" className="divider-black">Cart Summaries - Products | Ready Source</div>
            <Collapse accordion={true} defaultActiveKey={['fromSource0']} expandIconPosition="right" ghost accordion>
              {
                data.fromSource.items.map((cart,index)=>{
                  if (cart.totalCbmCartPort)
                    return (
                      <Panel className="summary-header" key={'fromSource'+index} header={
                        <div>{cart.port.name} | {cart.totalCbmCartPort.cbmSum} CBM | Balance {cart.cbmBalance} CBM | ${cart.totalCbmCartPort.amount} | {cart.totalCbmCartPort.totalItems} Item | {(cart.cartContainer && cart.cartContainer.quantity) || '0'} Container | {parseFloat(cart.cbmBalance)>0? 'Edit Cart to Checkout':'Checkout Now'} </div>
                        }>
                        <CartSummary id="FROM_SOURCE" cart={cart} refresh={get_cartproducts} />
                      </Panel>
                    )
                  else return <div/>;
                })
              }
            </Collapse>
          </div>
          :<div id="fromSource" />
      }
      {
        (data.preOrder && data.preOrder.items && data.preOrder.items.length)?
          <div className="collapse-container">
            <div id="preOrder" className="divider-black">Cart Summaries - Products | Customizable</div>
            <Collapse accordion={true} defaultActiveKey={['preOrder0']} expandIconPosition="right" ghost accordion>
              {
                data.preOrder.items.map((cart,index)=>{
                  if (cart.totalCbmCartPreorder)
                    return (
                      <Panel className="summary-header" key={'preOrder'+index} header={
                        <div>{cart.port.name} | {cart.totalCbmCartPreorder.cbmSum} CBM | Balance {cart.cbmBalance} CBM | ${cart.totalCbmCartPreorder.amount} | {cart.totalCbmCartPreorder.totalItems} Item | {(cart.cartContainer && cart.cartContainer.quantity) || '0'} Container | {parseFloat(cart.cbmBalance)>0? 'Edit Cart to Checkout':'Checkout Now'} </div>
                        }>
                        <CartSummary id="PRE_ORDER" cart={cart} refresh={get_cartproducts} />
                      </Panel>
                    )
                  else return <div/>
                })
              }
            </Collapse>
          </div>
          :<div id="preOrder" />
      */}
        </div>
    );
}

function CartBlock(props) {
    const [upload_bulk, set_upload_bulk] = React.useState(false);
    const [loading, set_loading] = React.useState(true);
    const [data, set_data] = React.useState({});
    const { t } = useTranslation();
    let cardId;

    async function get_cart() {
        try {
            const resp = await API_SERVICE.get_cart();
            if (resp.data && resp.data.data) {
                set_data(resp.data.data);
// console.log("cartid",resp.data.data.onStock.items[0].id);
                cartId = resp.data.data.onStock.items.length > 0 ? resp.data.data.onStock.items[0].id : null;
// console.log("new",cartId);
            }
            set_loading(false);
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
            set_loading(false);
        }
    }

    useEffect(() => {
        get_cart();
    }, []);
    return (
        <Row className='cart-block' justify='center'>
            <Col style={{ paddingTop: 12 }}>
                <Row justify='center'>
                    <div className='title-black'>Products |</div>
                    <div className='title-red'>{props.title}</div>
                </Row>
                <Row justify='center' gutter={20} style={{ padding: 12 }}>
                    <Col>
                        <Row align='middle'>
                            <Icon src={dimensions} />
                            <div className='title-desc'>{parseFloat(props.data.cbmSum).toFixed(3)} CBM</div>
                        </Row>
                        <Row align='middle'>
                            <Icon src={container} size={25} />
                            <div className='title-desc'>{props.data.totalContainer} Containers</div>
                        </Row>
                    </Col>
                    <Col>
                        <Row align='middle'>
                            <Icon src={basket} />
                            <div className='title-desc'>${formatter.format(Number(parseFloat(props.data.totalAmount).toFixed(2)))}</div>
                        </Row>
                        <Row align='middle'>
                            <Icon src={port} />
                            <div className='title-desc'>{props.data.totalPorts} Port</div>
                        </Row>
                    </Col>
                    <Col>
                        <Row align='middle'>
                            <Icon src={box} />
                            <div className='title-desc'>{props.data.totalItems} Items</div>
                        </Row>
                        <Row align='middle'>
                            <Icon src={dimensions} />
                            <div
                                className='title-desc'>Balance: {props.data.totalBalance && toFixed(props.data.totalBalance)} CBM
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Button type='primary' style={{ height: 40 }} block onClick={props.onClick}>View Cart</Button>
            <Button type='default' className='btn-upload' style={{ height: 40 }} block
                    onClick={() => set_upload_bulk(!upload_bulk)}>Upload Bulk Order</Button>

            <BulkUpload cartId={cartId} upload_bulk={upload_bulk} onClose={() => set_upload_bulk(false)} callback={props.get_cartproducts} />
        </Row>
    );
}

const Icon = (props) => {
    const size = props.size || 20;
    return (
        <img src={props.src} style={{
            height: size,
            width: size,
            resizeMode: 'contain',
            margin: 5,
        }} />
    );
};
