import React, { CSSProperties, useState, useEffect } from 'react';
import './index.scss';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import {
    Carousel,
    Space,
    Typography,
    Modal,
    Collapse,
    List,
    notification,
    message,
} from 'antd';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import {
    VerticalAlignBottomOutlined,
    RightOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from 'client/src/services/api-service';
import { get_port } from 'client/src/services/local-storage';
import Item from 'antd/lib/list/Item';

const { Text, Title } = Typography;
const { Panel } = Collapse;

//"port-preference" is hardcoded in multiple files.
const localkey = 'port-preference';
export default function CategorySelector(props: any) {
    const history = useHistory();
    const { t } = useTranslation();
    const stored_port = get_port();
    const [selection, select] = useState(stored_port);
    const [data, set_data] = useState([]);
    const [children, set_children] = useState([]);
    const [loading, set_loading] = React.useState(true);
    const [categoryId, setCategoryId] = useState(0);
    async function get_ports() {
        try {
            const resp = await API_SERVICE.get_categories({ page: -1 });
            console.log('data from api', resp.data);
            set_data(resp.data.data.items);
            set_loading(false);
            set_children(resp.data.data.items.children);
            console.log('children data', children);
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
            set_loading(false);
        }
    }

    async function get_download(category_id: number) {
        try {
            const resp = await API_SERVICE.get_download(category_id);
            console.log('download file', resp.data.data.item);
            window.open(resp.data.data.item, '_blank');
            message.success('Downloaded');
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    const handleCategorySelect = (
        c_name: string,
        c_id: number,
        sc_name: string,
        sc_id: number,
    ) => {
        history.push(`/home/${c_name}/${c_id}/${sc_name}/${sc_id}`);
        props.close();
    };

    useEffect(() => {
        get_ports();
    }, []);
    /* useEffect(()=>{
       if (selection.id!=stored_port.id)
         localStorage.setItem(
           localkey,
           JSON.stringify(selection)
         );
       history.push()
     },[selection])*/
    return (
        <Modal
            zIndex={1200}
            width={380}
            closable={false}
            visible={props.visible}
            footer={null}
            destroyOnClose={true}
        >
            <div className="port-selector-container">
                <div className="series">
                    <div className="title-container">
                        <div className="title">Category/Subcategory</div>
                    </div>
                    <CloseOutlined onClick={props.close} />
                </div>
                <div className="collapse-container">
                    <Collapse expandIconPosition="right" ghost accordion={true}>
                        {data.map((item: any, index: number) => {
                            return (
                                <Panel
                                    key={index}
                                    header={item.name}
                                    extra={
                                        <DownloadOutlined
                                            onClick={() =>
                                                get_download(item.id)
                                            }
                                        />
                                    }
                                >
                                    {/*<ul>
                                            {item.children.map((child:any, j: number)=>{
                                                return(
                                                    <li key={j}>{child.name}</li>
                                                )
                                            })}
                                        </ul>*/}

                                    <List
                                        size="small"
                                        split={false}
                                        dataSource={item.children}
                                        renderItem={(child: any) => (
                                            <List.Item
                                                className="cursor-pointer"
                                                key={child.id}
                                                onClick={() => {
                                                    handleCategorySelect(
                                                        item.name,
                                                        item.id,
                                                        child.name,
                                                        child.id,
                                                    );
                                                }}
                                            >
                                                {child.name}
                                            </List.Item>
                                        )}
                                    />
                                </Panel>
                            );
                        })}
                    </Collapse>
                </div>
            </div>
        </Modal>
    );
}
