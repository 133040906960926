import React, { CSSProperties, useEffect, useState } from 'react';
//import './index.scss';
import {
    Col,
    Row,
    Typography,
    Button,
    InputNumber,
    Divider,
    message,
    notification,
    Space,
} from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from '../../services/api-service';
import { get_port } from 'client/src/services/local-storage';
import { globals } from 'client/src/App';
import BulkUpload from './BulkUpload';

const { Text, Title, Link } = Typography;
export default function QuantitySelector(props) {
    console.log('quantity selector', props.quantity);

    const history = useHistory();
    const { t } = useTranslation();

    const [active, set_active] = React.useState(props.quantity ? true : false);
    const [cart_count, set_cart_count] = React.useState(props.quantity);
    const minimum_count = parseInt(props.min) || 1;
    const [count, set_count] = React.useState(props.quantity || minimum_count);
    const [loading, set_loading] = React.useState(false);
    const [upload_bulk, set_upload_bulk] = React.useState(false);
    const [add_clicked, set_add_clicked] = React.useState(false);
    const [boxQuantity, setBoxQuantity] = React.useState(props.boxQuantity);
    async function decrement() {
        // if (count>minimum_count)
        //   set_count(count-1)
        if (count % boxQuantity === 0) {
            let newCount = count;
            newCount = newCount - boxQuantity;
            if (newCount < 0) {
                return;
            }
            set_count(newCount);
        } else {
            let newCount = count;
            newCount = Math.floor(newCount / boxQuantity) * boxQuantity;
            set_count(newCount);
        }
    }
    async function increment() {
        //set_count(count+1)
        let newCount = count;
        if (count % boxQuantity === 0) {
            newCount = newCount + boxQuantity;
            set_count(newCount);
        } else {
            let newCount = count;
            newCount = Math.ceil(newCount / boxQuantity) * boxQuantity;
            set_count(newCount);
        }
    }

    async function update_count(value) {
        if (value >= minimum_count) set_count(value);
    }
    async function action() {
        if (!active) set_active(true);
        else {
            try {
                if (count > props.bulkquantity) {
                    message.error(
                        `Only ${props.bulkquantity} Quantity Available`,
                    );
                    return;
                } else {
                    set_loading(true);
                    const body = {
                        type: props.cartId,
                        portId: get_port().id,
                        productId: props.productId,
                        quantity: count,
                    };
                    const resp = await API_SERVICE.add_to_cart(body);
                    count === 0
                        ? message.success('Deleted from Cart Successfully')
                        : message.success('Updated Quantity Successfully');

                    set_count(count);
                    set_cart_count(count);
                    set_loading(false);
                    globals.refresh();
                    set_add_clicked(true);
                    if (props.onChange) props.onChange();
                    if (count === 0) set_active(false);
                }
            } catch (e) {
                console.log('loglog', e);
                notification.error({
                    message: t(API_SERVICE.handleErrors(e)),
                });
            } finally {
                set_loading(false);
            }
        }
    }

    if (props.disabled)
        return (
            <Row>
                <div>
                    <Button
                        type="primary"
                        style={{ marginTop: '12px' }}
                        disabled
                    >
                        Unavailable
                    </Button>
                </div>
            </Row>
        );
    return (
        <div style={{ width: '172px' }}>
            {active && (
                <div style={{ marginTop: '10px' }}>
                    <Row>
                        <Col>
                            <Button
                                type="default"
                                shape="circle"
                                icon={<MinusOutlined />}
                                onClick={decrement}
                                size={'middle'}
                            />
                        </Col>
                        <Col flex={'auto'} className={'text-center'}>
                            <InputNumber
                                value={count}
                                onChange={update_count}
                                style={{ width: '80px' }}
                                min={props.boxQuantity}
                                max={1000}
                                defaultValue={props.boxQuantity}
                            />
                        </Col>
                        <Col>
                            <Button
                                type="default"
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={increment}
                                size={'middle'}
                            />
                        </Col>
                    </Row>
                </div>
            )}
            {!active && (
                <Row>
                    <div>
                        <div
                            className="button-red"
                            style={{ marginTop: '10px' }}
                            onClick={() => {
                                set_count(1);
                                action();
                            }}
                        >
                            Add to Cart
                        </div>
                    </div>
                </Row>
            )}
            {active && !loading && count !== cart_count ? (
                <Row className={'centered'}>
                    <div>
                        <div
                            className="button-red"
                            style={{ marginTop: '10px' }}
                            onClick={action}
                        >
                            Update Cart
                        </div>
                    </div>
                </Row>
            ) : (
                <div />
            )}
            {
                <div>
                    {props.bulkquantity > 0 && (
                        <Button
                            type="default"
                            className="button-upload-bulk"
                            style={{ marginTop: '10px' }}
                            onClick={() => set_upload_bulk(!upload_bulk)}
                        >
                            Upload Bulk Order
                        </Button>
                    )}
                    <BulkUpload
                        upload_bulk={upload_bulk}
                        onClose={() => set_upload_bulk(false)}
                    />
                </div>
            }
            {boxQuantity > 1 && (
                <Text className="box-quantity" classtype="danger">
                    This product can be added in the{' '}
                    <b> set of {boxQuantity}</b> only.{' '}
                </Text>
            )}
        </div>
    );
}
