import * as React from 'react';
import { Suspense, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
    BellOutlined,
    CodeSandboxOutlined,
    LogoutOutlined,
    RightOutlined,
    UserOutlined,
} from '@ant-design/icons';
import { DashboardOutlined } from '@material-ui/icons';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import {
    Avatar,
    Col,
    Dropdown,
    Layout,
    Menu,
    Row,
    Space,
    Typography,
} from 'antd';
import { get_user } from 'client/src/services/local-storage';
import { useTranslation } from 'react-i18next';
import Parameters from 'shared/parameters';

import API_SERVICE from 'client/src/services/api-service';
import logo from 'shared/assets/logo.png';
import LoaderOverlay from 'shared/components/LoaderOverlay';
import openSocket from 'socket.io-client';

import MenuBookOutlinedIcon from '@material-ui/icons/MenuBookOutlined';
import { globals } from 'client/src/App';
import { access_includes } from 'client/src/services/AccessControl';
import { useLocation } from 'react-use';
import {
    ACCOUNT_MANAGEMENT,
    CATEGORIES_LIST,
    CONTAINER_TYPES,
    FRANCHISES_LIST,
    ORDER_LIST,
    PORT_AND_SUPPLIERS_LIST,
    PRODUCT_LIST,
    ROLES_LIST,
    SHIPPING_DOCUMENTS,
    UPLOAD_KNOWLEDGE,
    USER_LIST,
    VIEW_KNOWLEDGE,
} from '../../constants/RouteConstants';
import { AuthConsumer, logout } from '../../contexts/AuthContext';
import './Layout.scss';

const { Header, Footer, Sider, Content } = Layout;
const { Text } = Typography;
const { SubMenu } = Menu;

interface LayoutProps {
    selectedMenu?: string | null;
    onMenuClick?: (route: string) => void;
    children: any;
    hasHeader?: boolean;
    logout?: boolean;
    hasLink?: boolean;
    linkText?: string;
    linkUrl?: string;
}

export default function DesktopAdminLayout({
    selectedMenu,
    onMenuClick,
    children,
    hasLink,
    linkText,
    linkUrl,
}: LayoutProps) {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(FRANCHISES_LIST as string);
    const location = useLocation();
    const history = useHistory();

    const [notificationCount, setNotificationCount] = React.useState(0);
    const [notifications, setNotifications] = React.useState([]);

    const user = get_user();

    const socket = openSocket(Parameters.SocketAddr);
    socket.on(`danube_user_${user.user.id}`, (e) => {
        console.log(`danube_user_${user.user.id}:`, e);
        getNotifications();
    });

    async function getNotifications() {
        try {
            const resp = await API_SERVICE.get_notifications({ perpage: 5 });
            setNotifications(resp.data.data.items);
            const {
                data: { data },
            } = await API_SERVICE.header_count();
            if (data.notifications && data.notifications.length) {
                setNotificationCount(data.notifications[0].count);
            }
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (location.pathname) setActiveTab(location.pathname);
    }, [location]);

    React.useEffect(() => {
        if (!Object.isFrozen(globals)) {
            globals.refresh = getNotifications;
            Object.freeze(globals);
        }
        getNotifications();
    }, []);

    const handleClick = (e: any) => {
        history.push(e.key);
        return true;
    };
    const menu = (
        <Menu>
            <Menu.Item onClick={() => logout()}>
                <Space>
                    <LogoutOutlined /> <Text>Logout</Text>
                </Space>
            </Menu.Item>
        </Menu>
    );

    async function onNotificationOpened(item) {
        try {
            await API_SERVICE.open_notification(item.id);
            globals.refresh();
            if (item.sourceModel === 'User') {
                history.push('/admin/users');
            } else if (item.sourceModel === 'Order') {
                const user = get_user();
                if (user.user && user.user.role.name === 'ADMIN') {
                    history.push({
                        pathname: '/admin/orders/' + item.data.id,
                        state: {
                            order: item.data,
                            tab: item.data.rating ? '4' : '3',
                        },
                    });
                } else {
                    history.push({
                        pathname: '/orders/' + item.data.id,
                        state: {
                            order: item.data,
                            tab: item.data.rating ? '4' : '3',
                        },
                    });
                }
            } else if (item.sourceModel === 'Product') {
                history.push('/home/new-arrival');
            }
        } catch (e) {
            console.log(e);
        }
    }

    function getHeaderMenu() {
        let items = [];
        items.push(
            <Dropdown
                overlay={
                    <Menu>
                        {notifications.map((notification: any, i: number) => {
                            return (
                                <Menu.Item
                                    key={i}
                                    onClick={() =>
                                        onNotificationOpened(notification)
                                    }
                                    style={{
                                        backgroundColor: notification.isRead
                                            ? '#F2F2F2'
                                            : '#FFFFFF',
                                    }}
                                    className="notification-bar-item"
                                >
                                    <div style={{ padding: 8 }}>
                                        <Space
                                            style={{
                                                width: 300,
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Text>{notification.title}</Text>
                                        </Space>
                                    </div>
                                </Menu.Item>
                            );
                        })}
                        {notifications.length ? (
                            <Menu.Item
                                onClick={async () => {
                                    history.push('/admin/notifications');
                                }}
                                className="text-center"
                            >
                                <Space>
                                    <Text>View all notifications</Text>
                                    <RightOutlined />
                                </Space>
                            </Menu.Item>
                        ) : (
                            <Menu.Item>
                                <Space>
                                    <Text>No notifications yet</Text>
                                </Space>
                            </Menu.Item>
                        )}
                    </Menu>
                }
                trigger={['click']}
                placement="bottomRight"
                arrow
            >
                <div className="custom-nav-link">
                    <BellOutlined />
                    {notificationCount > 0 && (
                        <div style={styles.count}>{notificationCount}</div>
                    )}
                    <span className={'text'}>Notifications</span>
                </div>
            </Dropdown>,
        );
        return items;
    }

    return (
        <Layout
            hasSider={false}
            style={{ minHeight: '100vh' }}
            className={`desktop-layout`}
        >
            <Header style={{ position: 'fixed' }}>
                <Row gutter={20}>
                    <Col flex={'auto'}>
                        <Row gutter={20}>
                            <Col>
                                <div className="custom-nav-link">
                                    <Link to={'/home'}>
                                        <img src={logo} className="logo" />
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col>{getHeaderMenu()}</Col>
                            <Col>
                                <AuthConsumer>
                                    {({ isAuth, user }) => (
                                        <Dropdown
                                            overlay={menu}
                                            placement="bottomRight"
                                        >
                                            <div className="custom-nav-link account-details">
                                                <Avatar
                                                    size={30}
                                                    icon={<UserOutlined />}
                                                />
                                                <Text>{user?.name}</Text>
                                                <Text>
                                                    ({user?.role?.name})
                                                </Text>
                                            </div>
                                        </Dropdown>
                                    )}
                                </AuthConsumer>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Header>

            <Layout>
                <Sider width={250} theme={'light'}>
                    <Menu
                        style={{ width: 250 }}
                        onClick={handleClick}
                        selectedKeys={[activeTab]}
                        mode="inline"
                    >
                        <Menu.Item icon={<DashboardOutlined />} key="/admin/">
                            Dashboard
                        </Menu.Item>

                        <SubMenu
                            key="groupCatalogue"
                            icon={<CodeSandboxOutlined />}
                            title="Stock Management"
                        >
                            {access_includes([13, 14, 15, 16, 17, 18, 41]) && (
                                <Menu.Item
                                    // icon={<DirectionsBoatOutlined />}
                                    key={PRODUCT_LIST}
                                >
                                    Product Management
                                </Menu.Item>
                            )}
                            {access_includes([9, 10, 11, 12]) && (
                                <Menu.Item
                                    // icon={<DirectionsBoatOutlined />}
                                    key={CATEGORIES_LIST}
                                >
                                    Category Management
                                </Menu.Item>
                            )}
                            <Menu.Item
                                // icon={<ShoppingOutlined />}
                                key={ORDER_LIST}
                            >
                                Order Management
                            </Menu.Item>
                        </SubMenu>

                        <SubMenu
                            key="shippingManagement"
                            icon={<LocalShippingOutlinedIcon />}
                            title="Shipping Management"
                        >
                            {access_includes([5, 6, 7, 8]) && (
                                <Menu.Item
                                    // icon={<DirectionsBoatOutlined />}
                                    key={PORT_AND_SUPPLIERS_LIST}
                                >
                                    Port & Supplier Master
                                </Menu.Item>
                            )}
                            {access_includes([1, 2, 3, 4]) && (
                                <Menu.Item
                                    // icon={<DirectionsBoatOutlined />}
                                    key={CONTAINER_TYPES}
                                >
                                    Container Types
                                </Menu.Item>
                            )}
                            <Menu.Item
                                // icon={<DirectionsBoatOutlined />}
                                key={SHIPPING_DOCUMENTS}
                            >
                                Shipping Documents
                            </Menu.Item>
                        </SubMenu>

                        <Menu.Item
                            icon={<UserOutlined />}
                            key={ACCOUNT_MANAGEMENT}
                        >
                            Account Management
                        </Menu.Item>

                        <SubMenu
                            icon={<MenuBookOutlinedIcon />}
                            title="Franchise Development"
                        >
                            <Menu.Item
                                // icon={<DirectionsBoatOutlined />}
                                key={VIEW_KNOWLEDGE}
                            >
                                View Documents (Uploaded By Franchise)
                            </Menu.Item>
                            <Menu.Item
                                // icon={<DirectionsBoatOutlined />}
                                key={UPLOAD_KNOWLEDGE}
                            >
                                Upload Document
                            </Menu.Item>
                        </SubMenu>
                        <SubMenu
                            key="clientManagement"
                            icon={<UserOutlined />}
                            title="Client Management"
                        >
                            {access_includes([34, 35, 36, 37, 38, 39, 40]) && (
                                <Menu.Item key={USER_LIST}>
                                    User Management
                                </Menu.Item>
                            )}
                            {access_includes([28, 29, 30, 31, 32, 33]) && (
                                <Menu.Item key={ROLES_LIST}>
                                    Roles Management
                                </Menu.Item>
                            )}
                            <Menu.Item
                                // icon={<ShopOutlined />}
                                key={FRANCHISES_LIST}
                            >
                                Franchise Management
                            </Menu.Item>
                        </SubMenu>
                    </Menu>
                </Sider>
                <Content className={'content-layout'}>
                    <Suspense
                        fallback={<LoaderOverlay size="large" loading={true} />}
                    >
                        {children}
                    </Suspense>
                </Content>
            </Layout>
            <div className="footer-wrapper">
                <Row gutter={20} className={'secondary-footer'}>
                    <Col flex={'auto'}>
                        <Text>
                            {new Date().getFullYear()} | Danube Home | Dubai
                        </Text>
                    </Col>
                    <Col>
                        <Text>Disclaimer</Text>
                    </Col>
                    <Col>
                        <Text>Privacy Policy</Text>
                    </Col>
                </Row>
            </div>
        </Layout>
    );
}

const styles = {
    count: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 8,
        left: -12,
        backgroundColor: '#000',
        color: '#FFF',
        marginRight: -16,
        height: 16,
        width: 16,
        borderRadius: 16,
        fontSize: 8,
    },
};
