import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Menu, Modal, List, Popconfirm, notification, message, Space, Switch, Form, Typography, Input, InputNumber, Radio, Row, Col, Select, Divider, Upload, Tabs, Descriptions, Card, Checkbox } from 'antd';
import { ShopOutlined, PlusCircleOutlined, UploadOutlined, MinusSquareOutlined } from '@ant-design/icons';
import Parameters from 'shared/parameters';
//import './index.scss';
import { useHistory } from 'react-router-dom';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';
import CategorySelector from './CategorySelector';

const { SubMenu } = Menu;
const { Option } = Select;
const { Text, Title, Link } = Typography;
const { TabPane } = Tabs;
const modal_width = 950
export default function AddProduct({ onClose, details, categories }: any) {
    const history = useHistory();
    const [form] = Form.useForm();

    const [exit_flag,set_exit] = useState([]);
    const [saving, setSaving] = useState(false);
    const [category,set_category] = useState(false);
    const [category_select,set_category_select] = useState(false)
    const [color,set_color] = useState('#FFF');
    const [product_status,set_product_status] = useState(true)

    const clock_actions = {}
    async function handle_change(){
      let colorname = form.getFieldValue('color')
      if (colorname.indexOf('#')===0)
        return set_color(colorname)

      if (clock_actions.color)
        clearTimeout(clock_actions.color);
      clock_actions.color = setTimeout(async ()=> {
            let color_name = form.getFieldValue('color').toLowerCase()
            const data = await API_SERVICE.get_color({name:color_name})
            set_color(data.data.data.hex || '#FFF')
        }, 600);
    }

    async function push_field(field,value){
      const obj = {}
      obj[field] = value
      form.setFieldsValue(obj)
    }

    const [suppliers, set_suppliers] = useState([]);
    const fetch_suppliers = async () => {
        try {
            const { data: { data: { items } } } = await API_SERVICE.suppliers({ perpage: 1000 });
            set_suppliers(items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };
    useEffect(() => {
        fetch_suppliers();
    }, []);

    const [product_tags,set_product_tags] = useState([]);
    const [quantities,set_quantities] = useState([]);
    const [quantity_list,set_quantity_list] = useState([]);
    const [default_images,set_default_images] = useState([]);
    function push_supplier(id,quantity){
      suppliers.map((i)=>{
        if (i.id===id){
          set_quantity_list(quantity_list.concat([
            {"supplier":i.name, "quantity":quantity}
          ]))
          return true;
        }
      })
      set_quantities(quantities.concat([
        {"supplierId": id, "quantity": quantity,}
      ]))
    }
    function add_quantity(){
      const supplier = suppliers[form.getFieldValue('supplier')]
      const quantity = form.getFieldValue('supplier_quantity')
      if (!supplier || !quantity)
        return false;
      push_supplier(supplier.id,quantity)
    }
    function pop_quantity(index){
      const arr1 = [...quantity_list]
      const arr2 = [...quantities]
      arr1.splice(index,1)
      arr2.splice(index,1)
      set_quantity_list(arr1)
      set_quantities(arr2)
    }
    React.useEffect(()=>{
      form.setFieldsValue({
        'supplier':'',
        'supplier_quantity':''
      })
    },[quantity_list])

    React.useEffect(()=>{
      if (details){
        set_category(details.category)

        let arr = []
        if (details.isCatalogue)
          arr.push('isCatalogue')
        if (details.isSearchable)
          arr.push('isSearchable')
        details.visibility = arr

        details.is_featured = details.isFeatured
        details.is_new_arrival = details.isNewArrival
        details.is_top_selling = details.isTopSelling
        details.is_discounted_deal = details.isDiscountedDeal

        //update form fields
        form.setFieldsValue(details)

        arr = []
        let file_arr = []
        let uid_arr = []
        details.productImages.map((item,index)=>{
          let image = item;
          image.uid = index;
          image.thumbUrl = image.url;
          arr.push(image);
          file_arr.push(image.id);
          uid_arr.push(index);
        })
        set_default_images(arr)
        set_file_uids(uid_arr)
        set_files(file_arr)
        //console.log(arr)
      }
      else form.setFieldsValue({
          "visibility": [],
      })
    },[])

    React.useEffect(()=>{
      if (suppliers.length && details){
        const quantity_arr = []
        const supplier_arr = []
        details.stores.map((store)=>{
          suppliers.map((i)=>{
            if (i.id===store.supplierId){
              supplier_arr.push({"supplier":i.name, "quantity":store.quantity})
              quantity_arr.push({"supplierId": store.supplierId, "quantity": store.quantity})
              return true;
            }
          })
        })
        set_quantities(quantity_arr)
        set_quantity_list(supplier_arr)
      }
    },[suppliers])

    const [files,set_files] = useState([])
    const [file_uids,set_file_uids] = useState([])
    async function handle_upload(file){
      try {
        //console.log(file)
        const data = await API_SERVICE.upload_file(file,'PRODUCT_IMAGE')
        set_files(files.concat(data.data.data.id))
        set_file_uids(file_uids.concat(file.uid))
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
    }
    async function handle_file_remove(file){
      const arr = [...file_uids]
      let index = arr.indexOf(file.uid)
      arr.splice(index,1)
      set_file_uids(arr)
      const arr2 = [...files]
      arr2.splice(index,1)
      set_files(arr2)
    }

    /*
    React.useEffect(()=>{
      //testcases
    },[])
    */

    return (
        <Modal title={
            (<div className="series">
              <Space>
                <ShopOutlined />
                <Text>{details ? 'Edit Product' : 'Add New Product'}</Text>
              </Space>
              <div className="series" style={{width:200}}>
                <Switch onChange={(i)=>set_product_status(i)} defaultChecked checkedChildren="Active" unCheckedChildren="Inactive" />
                <Button type="primary" onClick={form.submit}>Save Product</Button>
              </div>
            </div>
            )
        }
               width={modal_width /*short and long description width is set acc to 800 modal_width*/}
               visible={true}
               closable={false}
               onOk={
                   () => {
                       form.submit();
                   }
               }
               onCancel={() =>
                 /*onClose()*/
                 set_exit(
                 <Modal
                   closable={false}
                   zIndex={1200}
                   width={300}
                   centered
                   okText="Yes"
                   cancelText="No"
                   onOk={()=>onClose()}
                   onCancel={()=>set_exit([])}
                   visible={true}>
                   <Text>Are you sure you want to exit ?</Text>
                 </Modal>)
               }>
            <Form
                size='large'
                form={form}
                initialValues={details || {}}
                onValuesChange={handle_change}
                fields={[]}
                onFinish={(value) => {
                  //category id
                  if (!category){
                    message.info('Please select a category for this product');
                    return false;
                  }
                  value.categoryId = category.id;

                  //images
                  if (!files.length){
                    message.info('Product images are required');
                    return false;
                  }
                  value.fileIds = files;
                  value.thumbnailId = files[0];

                  //active status
                  value.isActive = product_status;
                  value.detail = "";

                  //ready source quantities
                  value.quantities = quantities;
                  delete value.supplier;
                  delete value.supplier_quantity;

                  //visibility
                  value.isCatalogue = value.visibility.includes('isCatalogue')
                  value.isSearchable = value.visibility.includes('isSearchable')
                  delete value.visibility;

                  //color hexcode
                  if (value.color.indexOf('#')!==0){
                    value.color = color;
                  }

                  if (details){
                    API_SERVICE.edit_product(value,details.id).then((d) => {
                        onClose(true);
                    }).catch((e) => {
                        //console.log(e)
                        notification.error({ message: API_SERVICE.handleErrors(e) });
                    })
                  }
                  else {
                    API_SERVICE.post_product(value).then((d) => {
                        onClose(true);
                    }).catch((e) => {
                        //console.log(e)
                        notification.error({ message: API_SERVICE.handleErrors(e) });
                    })
                  }
                }}
                layout='vertical'
                requiredMark={true}
            >
                <Row gutter={20}>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Category'}
                            name={'categoryId'}

                        >
                          <div onClick={()=>set_category_select(true)}>
                            <Select placeholder="Click to select" value={category && category.name} dropdownRender={()=>null}>
                              {category && <Option value={category.id}>{category.name}</Option>}
                            </Select>
                          </div>
                        </Form.Item>
                        <Form.Item
                            label={'SKU ID'}
                            name={'skuId'}
                            rules={[
                                { required: true, message: 'SKUID required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={'Product Title'}
                            name={'name'}
                            rules={[
                                { required: true, message: 'Title required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={'CBM Value'}
                            name={'cbnValue'}
                            rules={[
                                { required: true, message: 'CBM required' },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            label={'Custom-MOQ'}
                            name={'minimumOrderQuantity'}
                        >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item
                            label={'Product Color'}
                            name={'color'}
                            rules={[
                                { required: true, message: 'Color required' },
                            ]}
                        >
                            <Input placeholder="" suffix={
                              <div style={{
                                height:'24px',
                                width:'24px',
                                border: '0.5px solid #E4E4E4',
                                backgroundColor: color,
                                }}> </div>
                            } />
                        </Form.Item>
                        <Form.Item
                            style={{marginBottom:22}}
                            label={'Product Size'}
                            name={'size'}
                        >
                            <Input />
                        </Form.Item>
                        
                        <Upload
                       
                          accept=".png,.jpg,.jpeg"
                          maxCount={4}
                          listType="picture"
                          onRemove={handle_file_remove}
                          beforeUpload={file => {
                             if (!file.type.includes('image')) {
                               message.error(`${file.name} is not a image`);
                               return Upload.LIST_IGNORE;
                             }
                             else {
                               handle_upload(file)
                               return false;
                             }
                           }}
                          className="upload-list-inline">
                          <Button icon={<UploadOutlined />}>Upload Images</Button>
                        </Upload>
                        
                        {(!details || default_images.length) &&
                        <Upload
                          defaultFileList = {
                            details? default_images:[]
                          }
                          accept=".png,.jpg,.jpeg"
                          maxCount={4}
                          listType="picture"
                          onRemove={handle_file_remove}
                          beforeUpload={file => {
                             if (!file.type.includes('image')) {
                               message.error(`${file.name} is not a image`);
                               return Upload.LIST_IGNORE;
                             }
                             else {
                               handle_upload(file)
                               return false;
                             }
                           }}
                          className="upload-list-inline">
                          {/* <Button icon={<UploadOutlined />}>Upload Images</Button> */}
                        </Upload>}
                        <br />
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Form.Item
                          label='Visibility'
                          name='visibility'>
                        <Select
                           mode="multiple"
                           allowClear>
                           <Option value="isCatalogue">Catalogue</Option>
                           <Option value="isSearchable">Search</Option>
                        </Select>
                      </Form.Item>
                      <Divider plain>Cost</Divider>
                      <div style={{display:'flex'}}>
                          <Form.Item
                              label={'Dubai Price'}
                              name={'dubaiPrice'}
                              rules={[
                                  { required: true, message: 'Required' },
                              ]}
                          >
                              <InputNumber placeholder="USD" />
                          </Form.Item>

                          <Form.Item
                              label={'Ready Source'}
                              name={'portPrice'}
                              rules={[
                                  { required: true, message: 'Required' },
                              ]}
                          >
                              <InputNumber placeholder="USD" />
                          </Form.Item>

                          <Form.Item
                              label={'Custom Source'}
                              name={'customPrice'}
                              rules={[
                                  { required: true, message: 'Required' },
                              ]}
                          >
                              <InputNumber placeholder="USD" />
                          </Form.Item>
                        </div>
                        <Divider plain>Quantity</Divider>
                        <Form.Item
                            label={'QTY (Dubai)'}
                            name={'dubaiQuantity'}>
                            <InputNumber />
                        </Form.Item>

                        {quantity_list.length? <Descriptions bordered size="small">
                          {
                            quantity_list.map((item,index)=>{
                              return (
                                <Descriptions.Item key={index} span={3}
                                  label={
                                    <div onClick={()=>pop_quantity(index)}>
                                      <MinusSquareOutlined style={{
                                        color:'#D23',
                                        marginRight:8
                                      }} /> {item.supplier}
                                    </div>
                                  }>
                                  {item.quantity} units
                                </Descriptions.Item>
                              )
                            })
                          }
                          </Descriptions>
                          :[]
                        }
                        <br/>
                        <Form.Item
                            label={'Select Supplier'}
                            name={'supplier'}
                            >
                            <Select
                             showSearch
                             optionFilterProp="children"
                             filterOption={(input, option) =>
                               option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                             }>
                             {
                                 suppliers.map((supplier,index) => {
                                     return <Select.Option key={index} value={index}>{supplier.name}</Select.Option>;
                                 })
                             }
                           </Select>
                        </Form.Item>
                          <div className="series">
                              <Form.Item
                                  label={'QTY (Ready Source)'}
                                  name={'supplier_quantity'}>
                                  <InputNumber />
                              </Form.Item>
                              <Button style={{marginTop:22}} type={'primary'} icon={<PlusCircleOutlined />} onClick={add_quantity}>
                                  Add QTY
                              </Button>
                          </div>
                    </Col>
                </Row>
                <Row style={{marginTop:22}}>
                  <Form.Item
                      name={'shortDescription'}
                      rules={[
                          { required: true, message: 'Required' },
                      ]}
                  >
                  <TextArea placeholder="Add Short Description" autoSize={{minRows: 2, maxRows: 2 }} style={{width:modal_width-48}}/>
                  </Form.Item>
                </Row>
                <Row>
                <div className="table-description-section">
                    <Tabs defaultActiveKey="1" size={'large'}>
                        <TabPane tab="Details" key="1">
                          <div className="description-container">
                            <Row gutter={16}>
                            <Col>
                              <Form.Item  name={'warranty'} label='Warranty' ><Input /></Form.Item>
                              <Form.Item  name={'installationType'} rules={[{ required: true, message: 'Required' }]} label='Installation Type'><Input /></Form.Item>
                              <Form.Item  name={'setInclude'} label='Set Includes' ><Input /></Form.Item>
                              <Row>
                                <Form.Item  name={'dimension'} label='Dimensions' ><Input /></Form.Item>
                                <Form.Item  name={'weight'} rules={[{ required: true, message: 'Required' }]} label='Net Weight' ><Input /></Form.Item>
                              </Row>
                            </Col>
                            </Row>
                          </div>
                        </TabPane>
                        <TabPane tab="Description" key="2">
                          <Form.Item
                              name={'description'}>
                          <TextArea placeholder="Add Long Description" autoSize={{minRows: 8, maxRows: 8}} style={{width:modal_width-48}}/>
                          </Form.Item>
                        </TabPane>
                    </Tabs>
                </div>
                </Row>
                <Row>
                  <Form.Item name={'is_featured'} initialValue={false} />
                  <Form.Item name={'is_new_arrival'} initialValue={false} />
                  <Form.Item name={'is_top_selling'} initialValue={false} />
                  <Form.Item name={'is_discounted_deal'} initialValue={false} />
                </Row>
                <Row style={radio_attrib}>
                  <div className="title-desc" style={{marginTop:-10,fontSize:14}}>Product Type :</div>
                  <Checkbox
                    defaultChecked={details.isFeatured}
                    onChange={(i)=>{
                      push_field('is_featured',i.target.checked)
                    }}>Featured</Checkbox>
                  <Checkbox
                    defaultChecked={details.isNewArrival}
                    onChange={(i)=>{
                      push_field('is_new_arrival',i.target.checked)
                    }}>New Arrival</Checkbox>
                  <Checkbox
                    defaultChecked={details.isTopSelling}
                    onChange={(i)=>{
                      push_field('is_top_selling',i.target.checked)
                    }}>Top Selling</Checkbox>
                  <Checkbox
                    defaultChecked={details.isDiscountedDeal}
                    onChange={(i)=>{
                      push_field('is_discounted_deal',i.target.checked)
                    }}>Discounted Deal</Checkbox>
                  {/*
                    <Radio.Group>
                     <Radio style={radio_attrib} value={"FEATURED"}>Featured</Radio>
                     <Radio style={radio_attrib} value={"NEW_ARRIVAL"}>New Arrival</Radio>
                     <Radio style={radio_attrib} value={"TOP_SELLING"}>Top Selling</Radio>
                     <Radio style={radio_attrib} value={"DISCOUNTED_DEAL"}>Discounted Deal</Radio>
                    </Radio.Group>
                  */}
                </Row>
                <div style={{float:'right'}}>
                  {exit_flag}
                </div>
            </Form>

            <CategorySelector
              data={categories}
              visible={category_select}
              selection={category}
              onSelect={set_category}
              close={()=>set_category_select(false)}/>
        </Modal>
    );
}

const radio_attrib = {
  marginTop: -16,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}
