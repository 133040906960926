import React, { useEffect, useState } from 'react';
import {
    Button,
    notification,
    Pagination,
    Space,
    Table,
    Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import {
    DownloadOutlined,
    EyeOutlined,
    PlusCircleOutlined,
    ShopOutlined,
} from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import UploadDocumentsFranchise from './UploadDocumentsFranchise';
import moment from 'moment';
import FilterFranchise from './FilterFranchise';
import Search from 'antd/es/input/Search';

const { Text } = Typography;

export default function UploadDocument(props: any) {
    const history = useHistory();
    const [filter_view, set_filter_view] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: '10',
    } as any);
    const [total, setTotal] = useState(10);
    const [data, setData] = useState();
    const [search, setSearch] = useState('');
    const [documentType, setDocumentType] = useState([]);
    const [selection_arr, set_selection] = useState([]);

    const [filter, set_filter] = useState({
        documentTypeIds: [],
        isActive: true,
    });
    const getDocumentTypes = async () => {
        try {
            const params = {
                type: 'franchise',
            };
            const data = await API_SERVICE.get_documents_type(params);
            console.log('items', data.data.data);
            setDocumentType(data.data.data);
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
                placement: 'bottomRight',
            });
        }
    };

    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            render: (a: any, v: any, index: number) => (
                <Text>
                    {(pagination.page - 1) * pagination.perpage + index + 1}
                </Text>
            ),
        },
        {
            title: 'Document Title',
            key: 'title',
            dataIndex: 'title',
        },
        {
            title: 'Document Type',
            key: 'documentType',
            dataIndex: 'documentType',
            render: (a: any) => a?.name,
        },
        {
            title: 'Uploaded At',
            key: 'updatedAt',
            render: (d: any) => {
                return moment(d?.updatedAt).format('DD/MM/YYYY');
            },
        },
        {
            title: 'Actions',
            key: 'action',
            render: (a: any, value: any) => {
                return (
                    <Space>
                        <Button
                            type="default"
                            shape="circle"
                            onClick={() => window.open(a.file.url, '_blank')}
                            icon={<EyeOutlined />}
                        />
                        <Button
                            type="default"
                            shape="circle"
                            onClick={() => window.open(a.file.url, '_blank')}
                            icon={<DownloadOutlined />}
                        />
                    </Space>
                );
            },
        },
    ];

    const refresh = async () => {
        setFetching(true);
        set_selection([]);
        const params = {
            page: pagination.page,
            perpage: pagination.perpage,
        };
        //@ts-ignore
        if (search) params['q'] = search;
        if (filter.documentTypeIds) {
            //@ts-ignore
            params['documentType'] = filter.documentTypeIds;
        }
        try {
            const {
                data: { data },
            } = await API_SERVICE.get_uploaded_franchise(params);
            setData(data.items);
            setTotal(data.total);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };
    useEffect(() => {
        refresh();
        getDocumentTypes();
    }, []);
    useEffect(() => {
        refresh();
    }, [props.location, pagination, filter]);
    return (
        <div className={'franchise-wrapper page-wrapper'}>
            <AdminListLayout
                titleIcon={<ShopOutlined />}
                title={'Upload Documents'}
                titleAction={
                    <Button
                        type={'primary'}
                        onClick={() => setAddNew(true)}
                        icon={<PlusCircleOutlined />}
                    >
                        Add New
                    </Button>
                }
                titleSearch={
                    <div className="search-bar">
                        <Search
                            placeholder="Search any Document here"
                            size="large"
                            onSearch={(e) => {
                                setPagination({ ...pagination, page: 1 });
                                setSearch(e);
                            }}
                        />
                    </div>
                }
                subheader={[
                    <Button
                        key="2"
                        type={'primary'}
                        onClick={() => set_filter_view(true)}
                        icon={<PlusCircleOutlined />}
                    >
                        Filter
                    </Button>,
                ]}
            >
                {addNew && (
                    <UploadDocumentsFranchise
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }}
                    />
                )}

                <Table
                    loading={fetching}
                    scroll={{ x: true }}
                    dataSource={data}
                    pagination={false}
                    columns={columns}
                />

                <div style={{ float: 'right', padding: '20px' }}>
                    <Pagination
                        key="4"
                        defaultPageSize={pagination.perpage}
                        showSizeChanger
                        defaultCurrent={1}
                        total={total}
                        onChange={(page, size) =>
                            setPagination({ page: page, perpage: size })
                        }
                    />
                </div>
                {console.log('doc', documentType)}
                <FilterFranchise
                    documentType={documentType}
                    selection={filter}
                    onChange={set_filter}
                    visible={filter_view}
                    close={() => set_filter_view(false)}
                />
            </AdminListLayout>

            {/* <Modal
              closable={false}
              zIndex={1200}
              width={300}
              centered
              okText="Yes"
              cancelText="No"
            //   onOk={delete_user}
            //   onCancel={()=>setDeleteUser(-1)}
            //   visible={(deleteUser>-1)}>
              <Text>Are you sure you want to delete ?</Text>
            </Modal> */}
        </div>
    );
}
