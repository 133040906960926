import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Space, Descriptions, Typography, Dropdown, InputNumber, Menu, Upload, Pagination, Table, Row, Col, Tag, Checkbox, Select, Modal, Radio, notification, message } from 'antd';
import { DeleteOutlined, UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import API_SERVICE from '../../../services/api-service';

const { Text, Title, Link } = Typography;
export default function ORDER_COMPLETE({ details }: any) {
    const total_amount = details.totalCbmOrder? details.totalCbmOrder.portAmount : 0
    const recieved_amount = details.orderPaymentFile? details.orderPaymentFile.amount : 0
    const pending = total_amount - recieved_amount

    return (
      <div style={{
        paddingTop: 8,
        }} >
        <Descriptions column={1} size="small" layout="horizontal">
          <Descriptions.Item label="Status"> Order Complete </Descriptions.Item>
          <Descriptions.Item label="Total Order Value"> ${total_amount} </Descriptions.Item>
          <Descriptions.Item label="Payment Received"> ${recieved_amount} </Descriptions.Item>
          <Descriptions.Item label="Payment Pending"> ${pending} </Descriptions.Item>
        </Descriptions>
        <br/>
        <Button
          type="primary"
          style={{
            marginBottom: 16,
          }}
          icon={<DownloadOutlined />}
          onClick={()=>{
            const paymentFile = details.paymentFile
            if (paymentFile)
              window.open(paymentFile.url, '_blank').focus();
          }}>
          Payment Documents
        </Button>
        <br/>
        <Button
          type="primary"
          style={{
            marginBottom: 16,
          }}
          icon={<DownloadOutlined />}
          onClick={()=>{
            const shipmentFile = details.shipmentFile
            if (shipmentFile)
              window.open(shipmentFile.url, '_blank').focus();
          }}>
          Shipping Documents
        </Button>

      </div>
    )
}
