import React, { CSSProperties, useState, useEffect } from 'react';
import './index.scss';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import { Carousel, Space, Typography, Modal, Collapse, List, Checkbox, Tree, Card, notification } from 'antd';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import {CloseOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CategorySelector from './CategorySelector';

const { Text, Title } = Typography;
const { Panel } = Collapse;

export default function ColumnSelector(props: any) {
    const history = useHistory();
    const { t } = useTranslation();

    const custom_titles = {
      'quantity': 'Quantity',
      'pricing': 'Pricing'
    }
    const skiplist = ['select','sno','name','action']

    function handle_change(column,column_index) {
      const selection = [ ...props.selection ]
      let index = -1
      selection.map((item,i)=>{
        if (item.key===column.key){
          index = i;
          return true;
        }
      })
      if (index === -1)
        selection.splice(column_index, 0, column)
      else selection.splice(index, 1);
      props.onChange(selection)
    }

    return (
        <Modal className="selector" zIndex={1200} width={400} closable={false} visible={props.visible} footer={null}>
        <div>
          <div className="series">
            <div className="title-container">
              <div className="title-desc">Manage Columns</div>
            </div>
            <CloseOutlined onClick={props.close} />
          </div>
          <Card style={{marginTop:16,marginBottom:16}}>
          {
            props.columns.map((item,index)=>{
              const column = { ...item }
              if (custom_titles[column.key])
                column.title = custom_titles[column.key]
              if (skiplist.includes(column.key))
                return null;
              return (
                <div key={index}>
                <Checkbox
                  defaultChecked={true}
                  onChange={(i)=>handle_change(item,index)}
                  > {column.title}</Checkbox>
                </div>
              )
            })
          }
          </Card>
        </div>
        </Modal>
    );
}
