import * as React from 'react';

import { useTranslation } from 'react-i18next';

import './index.scss';
import { Col, Row, Typography } from 'antd';

const { Title } = Typography;

interface LayoutProps {
    children: any;
    title?: any,
    titleIcon?: any,
    titleSearch?: any,
    titleAction?: any,
    onMenuClick?: (route: string) => void;
    panel? : any;
    subheader?: any;
}

export default function AdminListLayout({ children, titleSearch, titleIcon, titleAction, title, panel, subheader }: LayoutProps) {
    const { t } = useTranslation();

    return (
        <div className={'admin-list-layout'}>
            <Row className='title-bar' align={'middle'} gutter={20} wrap={false}>
                <Col flex={'auto'}>
                    <Row gutter={20} align={'middle'}>
                        {
                            titleIcon && <Col className={'icon'}>
                                {titleIcon}
                            </Col>
                        }
                        {
                            title && <Col>
                                <Title level={4}> {title}</Title>
                            </Col>
                        }
                        {
                            panel && panel.map((view,index)=>
                              <Col key={index}>
                                  {view}
                              </Col>
                            )
                        }
                        {
                            titleSearch && <Col flex={'auto'}>
                                {titleSearch}
                            </Col>
                        }
                    </Row>
                </Col>
                {
                    titleAction && <Col>
                        {titleAction}
                    </Col>
                }
            </Row>
            {
              subheader &&
                <Row className='title-bar' align={'middle'} gutter={20} wrap={false}>
                  {
                    subheader.map((item,index)=>
                      <Col key={index}>
                        {item}
                      </Col>
                  )}
                </Row>
            }
            {
                children
            }
        </div>
    );
}
