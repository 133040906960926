import React, { useState } from 'react';
import './index.scss';
import { Button, Col, Descriptions, notification, Row, Upload } from 'antd';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { LoadingOutlined, PlusOutlined, ShopOutlined } from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import { get_user } from '../../services/local-storage';

export default function FranchiseProfile(props: any) {
    const user = get_user().user;
    const franchise = user && user.franchise;

    const [signature, set_signature] = useState(user?.signature);
    const [stamp, set_stamp] = useState(user?.stamp);
    const [loading, set_loading] = useState(false);


    async function handle_upload(file: any, type: string) {
        try {
            const data = await API_SERVICE.upload_file(file, 'DOCUMENT');
            if (type === 'stamp') {
                set_stamp(data.data.data);
            } else {
                set_signature(data.data.data);
            }
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    }

    async function saveSignature() {
        try {
            await API_SERVICE.franchise_save_signature(user.id, {
                stampId: stamp.id,
                signatureId: signature.id,
            });
            notification.success({ message: 'Digital Signatures Saved Successfully' });
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    }

    const UploadButton = (props: any) => (
        <div className='upload-button'>
            {loading ? <LoadingOutlined /> : <PlusOutlined style={{ fontSize: 20 }} />}
            <div style={{ marginTop: 5 }}>Upload</div>
            <div>{props.title}</div>
        </div>
    );

    return (
        <Row>
            <Col className='franchise-profile-container'>
                <AdminListLayout
                    titleIcon={<ShopOutlined />}
                    title={'Franchise Profile'}
                >
                    <div className='container'>
                        <Descriptions column={1} bordered={true} layout='horizontal'>
                            <Descriptions.Item label='Franchise Code'>{franchise.code}</Descriptions.Item>
                            <Descriptions.Item label='Country Flag'><img src={franchise.file && franchise.file.url}
                                                                         className='flag' /></Descriptions.Item>
                            <Descriptions.Item label='Franchise Name'>{franchise.name}</Descriptions.Item>
                            <Descriptions.Item label='Street Address'>{franchise.address}</Descriptions.Item>
                            <Descriptions.Item
                                label='Country'>{franchise.country && franchise.country.name}</Descriptions.Item>
                            <Descriptions.Item label='Email'>{user.email}</Descriptions.Item>
                            <Descriptions.Item label='Contact No.'>{user.phone}</Descriptions.Item>
                        </Descriptions>
                    </div>
                </AdminListLayout>
            </Col>
            <Col className='franchise-profile-container'>
                <AdminListLayout
                    titleIcon={<ShopOutlined />}
                    title={'Digital Signatures'}
                >
                    <div className='container'>
                        <Row gutter={20}>
                            <Col>
                                <Upload
                                    accept='.png,.jpg,.jpeg'
                                    itemRender={() => []}
                                    listType='picture'
                                    onRemove={() => set_signature(null)}
                                    beforeUpload={file => {
                                        if (!file.type.includes('image')) {
                                            message.error(`${file.name} is not a png file`);
                                            return Upload.LIST_IGNORE;
                                        } else {
                                            handle_upload(file, 'sign');
                                            return false;
                                        }
                                    }}
                                    className='avatar-uploader'>
                                    {signature ?
                                        <div>
                                            <img src={signature.url} style={{ height: '200px', width: '220px' }} />
                                            <div className='upload-title'>Signature</div>
                                        </div>
                                        :
                                        <UploadButton title='Signature' />
                                    }
                                </Upload>
                            </Col>
                            <Col>
                                <Upload
                                    accept='.png,.jpg,.jpeg'
                                    itemRender={() => []}
                                    listType='picture'
                                    onRemove={() => set_stamp(false)}
                                    beforeUpload={file => {
                                        if (!file.type.includes('image')) {
                                            message.error(`${file.name} is not a png file`);
                                            return Upload.LIST_IGNORE;
                                        } else {
                                            handle_upload(file, 'stamp');
                                            return false;
                                        }
                                    }}
                                    className='avatar-uploader'>
                                    {stamp ?
                                        <div>
                                            <img src={stamp.url} style={{ height: '200px', width: '220px' }} />
                                            <div className='upload-title'>Stamp</div>
                                        </div>
                                        :
                                        <UploadButton title='Stamp' />
                                    }
                                </Upload>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col xs={24}>
                                <Button color='primary' className='ant-btn-primary float-right'
                                        style={{ float: 'right' }} onClick={saveSignature}>
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </AdminListLayout>
            </Col>
        </Row>
    );
}
