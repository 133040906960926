import {get_permissions} from './local-storage';

export default function AccessControl(props){
  const user_permissions = get_permissions()
  if (user_permissions.includes(props.id) || !props.id)
    return props.children
  else if (props.closed)
    return props.closed
  else return false
}

export function access_includes(arr){
  const user_permissions = get_permissions()
  let access = false
  if (arr){
    arr.map((id)=>{
      if (user_permissions.includes(id)){
        access = true;
        return false
      }
    })
  }
  return access;
}
