import React, { CSSProperties, useState, useEffect } from 'react';
import './index.scss';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import HomeHeaderRowTwo from './HomeHeaderRowTwo';
import HomeHeaderRowThree from './HomeHeaderRowThree';
import {
    Carousel,
    Space,
    Typography,
    Spin,
    notification,
    Button,
    message,
} from 'antd';
import { Link } from 'react-router-dom';
import ProductCardCarousel from 'shared/components/ProductCardCarousel/ProductCardCarousel';
import CategoryCarousel from 'shared/components/CategoryCarousel/CategoryCarousel';
import MiniCart from '../MiniCart/MiniCart';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from 'client/src/services/api-service';
import { globals } from 'client/src/App';

import { get_port } from 'client/src/services/local-storage';

const { Text, Title } = Typography;
const contentStyle: CSSProperties = {
    height: '320px',
    color: '#fff',
    lineHeight: '320px',
    textAlign: 'center',
    background: '#364d79',
};
export default function HomePage(props: any) {
    const history = useHistory();
    const { t } = useTranslation();
    const category = props.match.params.category;
    const category_id = props.match.params.category_id;
    const [port_id, set_port_id] = useState(1);
    const [products, set_products] = useState([]);
    const [fetching, set_fetching] = useState(false);
    const [home_products, set_home_products] = useState(false);

    async function get_products() {
        //set_products([])
        const port = get_port();
        try {
            set_fetching(true);
            const params = {
                categoryId: category_id,
                portId: port.id,
            };
            const resp = await API_SERVICE.get_products(params);
            set_products(resp.data.data);
            set_fetching(false);
        } catch (e) {
            set_fetching(false);
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    async function get_home_products(params = {}) {
        try {
            const resp = await API_SERVICE.get_trending_products(params);
            set_home_products(resp.data.data);
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    async function get_download(category_id: number) {
        try {
            const resp = await API_SERVICE.get_download(category_id);
            console.log('download file', resp.data.data.item);
            window.open(resp.data.data.item, '_blank');
            message.success('Downloaded');
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    useEffect(() => {
        if (category_id) get_products();
        get_home_products();
    }, [category_id]);

    return (
        <div className={'home-page-wrapper'}>
            <Spin spinning={fetching} size="large" tip="Getting products">
                {!category_id ? (
                    <div>
                        <CategoryCarousel />
                        {home_products &&
                            trending.items.map((item, index) => (
                                <ProductCardCarousel
                                    key={index}
                                    category_id={category_id}
                                    refresh={get_home_products}
                                    products={home_products[item]}
                                    title={
                                        <div
                                            className={
                                                'product-card-carousel-header'
                                            }
                                        >
                                            <Space align={'center'} size={20}>
                                                <Title
                                                    style={{ margin: 0 }}
                                                    level={3}
                                                >
                                                    {trending.titles[index]}
                                                </Title>
                                                <Link
                                                    to={
                                                        'home/' +
                                                        trending.routes[index]
                                                    }
                                                >
                                                    View All
                                                </Link>
                                            </Space>
                                        </div>
                                    }
                                />
                            ))}
                    </div>
                ) : (
                    <div>
                        <div className="centered">
                            <Title
                                style={{
                                    margin: 0,
                                    marginTop: 42,
                                    marginBottom: 8,
                                }}
                                level={3}
                            >
                                {(category || ' ').toUpperCase()}
                            </Title>
                            <div className="btn-download">
                                <Button
                                    type="primary"
                                    danger
                                    onClick={() => get_download(category_id)}
                                >
                                    {' '}
                                    Download
                                </Button>
                            </div>
                        </div>
                        {products.map((item: any, index) => (
                            <ProductCardCarousel
                                key={index}
                                refresh={get_products}
                                products={item.products}
                                title={
                                    <div
                                        className={
                                            'product-card-carousel-header'
                                        }
                                    >
                                        <Space align={'center'} size={20}>
                                            <Title
                                                style={{ margin: 0 }}
                                                level={4}
                                            >
                                                {item.name.toUpperCase()}
                                            </Title>
                                            <Link
                                                to={`${category_id}/${item.name}/${item.id}`}
                                            >
                                                View All
                                            </Link>
                                        </Space>
                                    </div>
                                }
                            />
                        ))}
                    </div>
                )}
                {fetching ? <div style={{ height: 400 }} /> : ''}
            </Spin>

            <MiniCart />
        </div>
    );
}

const trending = {
    items: ['newArrival', 'featured', 'topSelling', 'discountedDeal'],
    titles: [
        'NEW ARRIVAL',
        'FEATURED PRODUCTS',
        'TOP SELLING',
        'DISCOUNTED DEALS',
    ],
    routes: ['new-arrival', 'featured', 'top-selling', 'discounted-deal'],
};
