import React, { CSSProperties, useEffect, useState } from 'react';
import {
    Button,
    Space,
    Descriptions,
    Typography,
    Dropdown,
    InputNumber,
    Menu,
    Upload,
    Pagination,
    Table,
    Row,
    Col,
    Tag,
    Checkbox,
    Select,
    Modal,
    notification,
    message,
} from 'antd';
import {
    DeleteOutlined,
    UploadOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import API_SERVICE from '../../services/api-service';

import PENDING_PAYMENT from './Actions/PENDING_PAYMENT';
import PERFORMA_SIGN_OFF_REQUIRED from './Actions/PERFORMA_SIGN_OFF_REQUIRED';
import PROCESSING_ORDER from './Actions/PROCESSING_ORDER';
import ORDER_DELIVERED from './Actions/ORDER_DELIVERED';
import READY_TO_SHIP from './Actions/READY_TO_SHIP';
import ORDER_SHIPPED from './Actions/ORDER_SHIPPED';
import ORDER_COMPLETE from './Actions/ORDER_COMPLETE';

const { Text, Title, Link } = Typography;
export default function OrderAction({
    details,
    callback,
    setActiveTabKey,
}: any) {
    console.log('statusssss', details?.status);

    switch (details?.status) {
        case 'ORDER_COMPLETE':
            return <ORDER_COMPLETE details={details} />;
        case 'ORDER_DELIVERED':
            return <ORDER_DELIVERED details={details} />;
        case 'ORDER_SHIPPED':
            return <ORDER_SHIPPED details={details} />;
        case 'READY_TO_SHIP':
            return <READY_TO_SHIP details={details} />;
        case 'PENDING_PAYMENT':
            return (
                <PENDING_PAYMENT
                    details={details}
                    callback={callback}
                    setActiveTabKey={setActiveTabKey}
                />
            );
        case 'PERFORMA_SIGN_OFF_REQUIRED':
            return (
                <PERFORMA_SIGN_OFF_REQUIRED
                    details={details}
                    callback={callback}
                    setActiveTabKey={setActiveTabKey}
                />
            );
        case 'PROCESSING_ORDER':
            return <PROCESSING_ORDER details={details} />;
        case 'ORDER_PLACED':
            return (
                <div>
                    <b>Status</b> : Pending Proforma from {details?.admin?.name}
                </div>
            );
        default:
            return <div />;
    }
}
