  import React, { CSSProperties, useState, useEffect } from 'react';
import './index.scss';
import { Button, Divider, Row, Space, Typography, Select, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { ArrowDropUp, ArrowDropDown, } from '@material-ui/icons';
import API_SERVICE from '../../services/api-service';
import { useTranslation } from 'react-i18next';
import {get_port} from 'client/src/services/local-storage';

import port_icon from 'shared/assets/port.png';

export const mini_cart = {
  refresh: ()=>false
}

const { Text, Title, Link } = Typography;
export default function MiniCart() {
  const history = useHistory();
  const { t } = useTranslation();
  const [expand,set_expand] = useState(true)
  const [data,set_data] = React.useState(null)
  const [ports,set_ports] = React.useState([])
  const [loading,set_loading] = React.useState(true)
  //const port = get_port()
  const preferences = JSON.parse(
    localStorage.getItem('minicart') || '{"port-preference":0}'
  )
  const [selected_port,set_port] = React.useState(preferences['port-preference'])

  async function get_cart() {
    try {
      set_loading(true);
      const params = {}
      if (selected_port)
        params.portId = selected_port
      const resp = await API_SERVICE.mini_cart(params);
      if (resp.data && resp.data.data){
        set_data(resp.data.data.items);
      }
      set_loading(false);
    } catch (e) {
      console.log(e)
      notification.error({
        message: t(API_SERVICE.handleErrors(e)),
      });
      set_loading(false);
    }
  }

  async function get_ports() {
    try {
      const resp = await API_SERVICE.ports({q:'inUse'});
      if (resp.data && resp.data.data){
        set_ports(resp.data.data.items);
      }
    } catch (e) {
      console.log(e)
      notification.error({
        message: t(API_SERVICE.handleErrors(e)),
      });
    }
  }

  React.useEffect(()=>{
    get_ports()
    mini_cart.refresh = get_cart
  },[])

  React.useEffect(()=>{
    get_cart()
    localStorage.setItem("minicart", JSON.stringify({
      'port-preference': selected_port,
    }))
  },[selected_port])

  if (data)
  return (
    <div className="mini-cart-container" id="area">
      <Button type="primary" onClick={()=>set_expand(!expand)}>Total CBM of your Cart </Button>
      {expand && <div className="footer">
          <div className="details-section">

                  <div className="port">
                    <div className="title-desc">Dubai</div>
                    <div className="title-red">{parseFloat(data.onStock.cbmSum || '0.0').toFixed(5)} CBM</div>
                  </div>

          </div>
          {/*(port && port.name)?
            <Row align="middle">
              <img src={port_icon} style={{
                height: 22,
                width: 22,
                resizeMode: 'contain',
                margin: 8,
              }} />
              <div style={{fontSize: 18,}} >{port.name}</div>
            </Row>
            :[]
          */}

          {/*<Row align="middle">
            <img src={port_icon} style={{
              height: 22,
              width: 22,
              resizeMode: 'contain',
              margin: 8,
            }} />
            <Select defaultValue={selected_port} style={{ minWidth: 150 }} onChange={set_port} getPopupContainer={() => document.getElementById('area')}>
              <Select.Option value={0}>All Ports</Select.Option>
              {
                ports.map((port,index)=>{
                  return (
                    <Select.Option key={index} value={port.id}>{port.name}</Select.Option>
                  )
                })
              }
            </Select>
          </Row>
{/*}
          <div className="details-section">
                  <div className="port">
                    <div className="title-desc">Ready Source</div>
                    <div className="title-red">{parseFloat(data.fromSource.cbmSum || '0.0').toFixed(5)} CBM</div>
                  </div>
          </div>
          <div className="details-section">
                  <div className="port">
                    <div className="title-desc">Customize Source</div>
                    <div className="title-red">{parseFloat(data.preOrder.cbmSum || '0.0').toFixed(5)} CBM</div>
                  </div>
          </div>
   */}

          <Button type="primary" style={{width:160}} onClick={()=>history.push('/cart')}>  CHECKOUT  </Button>
        </div>}
    </div>
  )
  else return <div/>
}
