import * as React from 'react';
import { Suspense } from 'react';
import { Link, useHistory } from 'react-router-dom';
import logo from 'shared/assets/logo.png';

import {
    Avatar,
    Col,
    Dropdown,
    Layout,
    Menu,
    Row,
    Space,
    Typography,
} from 'antd';
import {
    BellOutlined,
    HeartOutlined,
    HomeOutlined,
    LogoutOutlined,
    ShopOutlined,
    ShoppingOutlined,
    UserOutlined,
    RightOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import API_SERVICE from '../../../client/src/services/api-service';
import openSocket from 'socket.io-client';
import { get_user } from 'client/src/services/local-storage';
import Parameters from 'shared/parameters';
import { globals } from 'client/src/App';

import {
    BallotOutlined,
    DashboardOutlined,
    EmailOutlined,
    LocationOnOutlined,
    PhoneOutlined,
    StoreOutlined,
} from '@material-ui/icons';

import LoaderOverlay from 'shared/components/LoaderOverlay';

import './Layout.scss';
import HomeHeaderRowTwo from 'client/src/views/home/HomeHeaderRowTwo';

//_
import { AuthConsumer, logout } from '../../contexts/AuthContext';

const { Header, Footer, Content } = Layout;
const { Text } = Typography;
const { SubMenu } = Menu;

interface LayoutProps {
    selectedMenu?: string | null;
    onMenuClick?: (route: string) => void;
    children: any;
    hasHeader?: boolean;
    logout?: boolean;
    hasLink?: boolean;
    linkText?: string;
    linkUrl?: string;
}

export default function DesktopLayout({
    selectedMenu,
    onMenuClick,
    children,
    hasLink,
    linkText,
    linkUrl,
}: LayoutProps) {
    const history = useHistory();
    const { t } = useTranslation();

    const [popup, set_popup] = React.useState([]);
    const [counters, set_counters] = React.useState({
        cartCount: 0,
        wishlistCount: 0,
    });
    const [notificationCount, setNotificationCount] = React.useState(0);
    const [notifications, setNotifications] = React.useState([]);

    const user = get_user();

    const socket = openSocket(Parameters.SocketAddr);
    socket.on(`danube_user_${user.user.id}`, (e) => {
        console.log(`danube_user_${user.user.id}:`, e);
        globals.refresh();
    });

    async function getNotifications() {
        try {
            const resp = await API_SERVICE.get_notifications({ perpage: 5 });
            setNotifications(resp.data.data.items);
        } catch (e) {
            console.log(e);
        }
    }

    async function onNotificationOpened(item) {
        try {
            await API_SERVICE.open_notification(item.id);
            globals.refresh();
            if (item.sourceModel === 'User') {
                history.push('/admin/users');
            } else if (item.sourceModel === 'Order') {
                const user = get_user();
                if (user.user && user.user.role.name === 'ADMIN') {
                    history.push({
                        pathname: '/admin/orders/' + item.data.id,
                        state: {
                            order: item.data,
                            tab: item.data.rating ? '4' : '3',
                        },
                    });
                } else {
                    history.push({
                        pathname: '/orders/' + item.data.id,
                        state: {
                            order: item.data,
                            tab: item.data.rating ? '4' : '3',
                        },
                    });
                }
            } else if (item.sourceModel === 'Product') {
                history.push('/home/new-arrival');
            }
        } catch (e) {
            console.log(e);
        }
    }

    function getProfileMenu(user: any): any {
        const profileMenu = [];

        if (user.role.name === 'FRANCHISE_OWNER') {
            profileMenu.push(
                <>
                    <Menu.Item
                        onClick={() => history.push('/franchise-profile')}
                    >
                        <Space>
                            <ShopOutlined /> <Text>Franchise Profile</Text>
                        </Space>
                    </Menu.Item>
                    {/* <Menu.Item onClick={() => history.push('/users')}>
                        <Space>
                            <UserOutlined /> <Text>User Management</Text>
                        </Space>
                    </Menu.Item> */}
                    <Menu.Item onClick={() => history.push('/orders')}>
                        <Space>
                            <ShoppingOutlined /> <Text>Orders</Text>
                        </Space>
                    </Menu.Item>
                    <Menu.Item onClick={() => history.push('/addresses')}>
                        <Space>
                            <HomeOutlined /> <Text>Manage Addresses</Text>
                        </Space>
                    </Menu.Item>
                </>,
            );
        }

        profileMenu.push(
            <Menu.Item onClick={() => logout()}>
                <Space>
                    <LogoutOutlined /> <Text>Logout</Text>
                </Space>
            </Menu.Item>,
        );

        return <Menu>{profileMenu}</Menu>;
    }

    function getMenu(user: any) {
        let menus = [
            <>
                <Col>
                    <div
                        onClick={() => history.push('/dashboard')}
                        className="custom-nav-link"
                    >
                        <DashboardOutlined />
                        <span className={'text'}>Dashboard</span>
                    </div>
                </Col>
                {/* <Col>
                    <div
                        onClick={() => history.push('/training')}
                        className="custom-nav-link"
                    >
                        <BallotOutlined />
                        <span className={'text'}> Training</span>
                    </div>
                </Col> */}
                <Col>
                    <div
                        onClick={() => history.push('/franchise-development')}
                        className="custom-nav-link"
                    >
                        <BallotOutlined />
                        <span className={'text'}> Franchise Development </span>
                    </div>
                </Col>
                <Col>
                    <div
                        onClick={() => history.push('/cart')}
                        className="custom-nav-link"
                    >
                        <StoreOutlined />
                        {counters.cartCount > 0 && (
                            <div style={styles.count}>{counters.cartCount}</div>
                        )}
                        <span className={'text'}>Cart</span>
                    </div>
                </Col>
                <Col>
                    <div
                        onClick={() => history.push('/wishlist')}
                        className="custom-nav-link"
                    >
                        <HeartOutlined />
                        {counters.wishlistCount > 0 && (
                            <div style={styles.count}>
                                {counters.wishlistCount}
                            </div>
                        )}
                        <span className={'text'}>Wishlist</span>
                    </div>
                </Col>
                <Col>
                    {
                        <Dropdown
                            overlay={
                                <Menu>
                                    {notifications.map(
                                        (notification: any, i: number) => {
                                            return (
                                                <Menu.Item
                                                    key={i}
                                                    onClick={() =>
                                                        onNotificationOpened(
                                                            notification,
                                                        )
                                                    }
                                                    style={{
                                                        backgroundColor:
                                                            notification.isRead
                                                                ? '#F2F2F2'
                                                                : '#FFFFFF',
                                                    }}
                                                    className="notification-bar-item"
                                                >
                                                    <div style={{ padding: 8 }}>
                                                        <Space
                                                            style={{
                                                                width: 300,
                                                                overflow:
                                                                    'hidden',
                                                            }}
                                                        >
                                                            <Text>
                                                                {
                                                                    notification.title
                                                                }
                                                            </Text>
                                                        </Space>
                                                    </div>
                                                </Menu.Item>
                                            );
                                        },
                                    )}
                                    {notifications.length ? (
                                        <Menu.Item
                                            onClick={async () => {
                                                history.push('/notifications');
                                            }}
                                            className="text-center"
                                        >
                                            <Space>
                                                <Text>
                                                    View all notifications
                                                </Text>
                                                <RightOutlined />
                                            </Space>
                                        </Menu.Item>
                                    ) : (
                                        <Menu.Item>
                                            <Space>
                                                <Text>
                                                    No notifications yet
                                                </Text>
                                            </Space>
                                        </Menu.Item>
                                    )}
                                </Menu>
                            }
                            trigger={['click']}
                            placement="bottomRight"
                            arrow
                        >
                            <div className="custom-nav-link">
                                <BellOutlined />
                                {notificationCount > 0 && (
                                    <div style={styles.count}>
                                        {notificationCount}
                                    </div>
                                )}
                                <span className={'text'}>Notifications</span>
                            </div>
                        </Dropdown>
                    }
                </Col>
                <Col>
                    <div
                        onClick={() => history.push('/orders')}
                        className="custom-nav-link"
                    >
                        <ShoppingOutlined />
                        <span className={'text'}>My Orders </span>
                    </div>
                </Col>
            </>,
        ];
        if (user.role.name === 'FRANCHISE_STAFF') {
            menus = [
                <Col>
                    <div
                        onClick={() => history.push('/training')}
                        className="custom-nav-link"
                    >
                        <BallotOutlined />
                        <span className={'text'}> Training</span>
                    </div>
                </Col>,
            ];
        }

        menus.push(
            <Col>
                <Dropdown
                    overlay={getProfileMenu(user)}
                    placement="bottomRight"
                >
                    <div className="custom-nav-link account-details">
                        <Avatar
                            size={45}
                            src={user.file ? user.file.url : null}
                            icon={<UserOutlined />}
                        />
                        <Text>{user.name}</Text>
                        <Text>({user.role.name})</Text>
                    </div>
                </Dropdown>
            </Col>,
        );

        return menus;
    }

    async function refresh() {
        try {
            const resp = await API_SERVICE.header_count();
            const {
                data: { data },
            } = await API_SERVICE.header_count();
            if (data) {
                set_counters(data);
                if (data.notifications && data.notifications.length) {
                    setNotificationCount(data.notifications[0].count);
                }
            }
            getNotifications();
        } catch (e) {}
    }

    React.useEffect(() => {
        refresh();
        if (!Object.isFrozen(globals)) {
            globals.refresh = refresh;
            Object.freeze(globals);
        }
    }, []);

    return (
        <Layout
            hasSider={false}
            style={{ minHeight: '100vh' }}
            className={`desktop-layout`}
        >
            <Header style={{ position: 'fixed' }}>
                <Row gutter={20}>
                    <Col flex={'auto'}>
                        <Row gutter={20}>
                            <Col>
                                <div className="custom-nav-link">
                                    <Link to={'/'}>
                                        <img src={logo} className="logo" />
                                    </Link>
                                </div>
                            </Col>
                            <Col>
                                <AuthConsumer>
                                    {({ isAuth, user }) => (
                                        <div className="custom-nav-link">
                                            <div className="login-details">
                                                {user.role.name ===
                                                    'FRANCHISE_OWNER' ||
                                                user.role.name ===
                                                    'FRANCHISE_USER' ? (
                                                    <Space size={5}>
                                                        <div className="linear">
                                                            <img
                                                                src={
                                                                    user
                                                                        .franchise
                                                                        .file
                                                                        ? user
                                                                              .franchise
                                                                              .file
                                                                              .url
                                                                        : ''
                                                                }
                                                                style={{
                                                                    height: '30px',
                                                                    margin: '8px',
                                                                }}
                                                            />
                                                            <div className="login-rows">
                                                                <div className="row-1">
                                                                    {
                                                                        user
                                                                            .franchise
                                                                            .name
                                                                    }
                                                                </div>
                                                                <div className="row-2">
                                                                    Franchise
                                                                    Code:{' '}
                                                                    {
                                                                        user
                                                                            .franchise
                                                                            .code
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Space>
                                                ) : (
                                                    <Space size={5} />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </AuthConsumer>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <AuthConsumer>
                                {({ isAuth, user }) => getMenu(user)}
                            </AuthConsumer>
                        </Row>
                    </Col>
                </Row>
            </Header>

            <Content className={'content-layout'}>
                <AuthConsumer>
                    {({ isAuth, user }) =>
                        user.role.name === 'FRANCHISE_STAFF' ? (
                            []
                        ) : (
                            <HomeHeaderRowTwo />
                        )
                    }
                </AuthConsumer>
                {/*<HomeHeaderRowThree/>*/}
                <Suspense
                    fallback={<LoaderOverlay size="large" loading={true} />}
                >
                    {children}
                </Suspense>
            </Content>
            <div className="footer-wrapper">
                <Row gutter={20} className={'primary-footer'}>
                    <Col flex={'auto'} className={'text-center links'}>
                        <div className={'link'}>
                            <LocationOnOutlined /> <Text>Visit Us</Text>
                        </div>
                        <div className={'link'}>
                            <EmailOutlined /> <Text>Email Us</Text>
                        </div>
                        <div className={'link'}>
                            <PhoneOutlined /> <Text>Call Us</Text>
                        </div>
                    </Col>
                </Row>
                <Row gutter={20} className={'secondary-footer'}>
                    <Col flex={'auto'}>
                        <Text>
                            {new Date().getFullYear()} | Danube Home | Dubai
                        </Text>
                    </Col>
                    <Col>
                        <Text>Disclaimer</Text>
                    </Col>
                    <Col>
                        <Text>Privacy Policy</Text>
                    </Col>
                </Row>
            </div>
            {popup}
        </Layout>
    );
}

const styles = {
    count: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 8,
        left: -12,
        backgroundColor: '#000',
        color: '#FFF',
        marginRight: -16,
        height: 16,
        width: 16,
        borderRadius: 16,
        fontSize: 8,
    },
};
