import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import { Button, Menu, notification, Space, Table, Tag, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { ShopOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import ContainerCreateOrEditPage from './ContainerCreateOrEditPage';
import Paragraph from 'antd/es/typography/Paragraph';
import AccessControl from 'client/src/services/AccessControl'

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function ContainersPage(props: any) {

    const history = useHistory();
    const [containers, setContainers] = useState([] as any[]);
    const [search, setSearch] = useState('' as string);
    const [addNew, setAddNew] = useState(false);
    const [editContainer, setEditContainer] = useState(null as any);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({ page: 1, perpage: '20' } as any);
    const [total, setTotal] = useState(0);
    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            align: 'center',
            render: (a: any, v: any, index: number) => <Text>{(pagination.page-1)*(pagination.perpage)+ index + 1}</Text>,
        },
        {
            title: 'Name',
            key: 'name',
            align: 'center',
            render: (a: any) => <Paragraph>{a.description}</Paragraph>,
        },
        {
            title: 'Height',
            key: 'height',
            align: 'center',
            dataIndex: 'height',
        },
        {
            title: 'CBM',
            key: 'cbm',
            align: 'center',
            dataIndex: 'cbm',
        },
        {
            title: 'Round-Off CBM',
            key: 'roundOffCbm',
            align: 'center',
            dataIndex: 'roundOffCbm',
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'isActive',
            render: (a: boolean, value: any) =>
            /*
              <div onClick={async (e) => {
                try {
                    setFetching(true);
                    await API_SERVICE.containerUpdate(value.id, {
                        ...value,
                        isActive: !value.isActive,
                    });
                    refresh();
                } catch (e) {
                    notification.error({ message: API_SERVICE.handleErrors(e) });
                } finally {
                    setFetching(false);
                }
            }
            }>
            */
            <div>
                {a ? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>}
            </div>,
        },
        /*
        {
            title: 'Actions',
            key: 'action',
            render: (a: any, value: any) => {
                return <AccessControl id={3} closed={
                  <Space>
                      <Button type='default' shape='circle' icon={<EditOutlined />} disabled />
                  </Space>
                }>
                <Space>
                    <Button type='default' shape='circle' icon={<EditOutlined />}
                            onClick={() => {
                                setEditContainer(value);
                            }} />
                </Space>
                </AccessControl>;
            },
        },
        */
    ];
    const refresh = async () => {
        setFetching(true);
        const params = { ...pagination };
        if (search) {
            params['q'] = search;
        }
        try {
            const { data: { data } } = await API_SERVICE.containers(params);
            setContainers(data.items);
            setTotal(data.total);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };
    useEffect(() => {
        refresh();
    }, [props.location, pagination, search]);
    return (
        <div className={'franchise-wrapper page-wrapper'}>
            <AdminListLayout titleIcon={<ShopOutlined />} title={'All Container Types'}
                             titleSearch={
                               <AccessControl id={1}>
                                 <div className='search-bar'>
                                   <Search
                                       placeholder='Search for container types here'
                                       size='large'
                                       onSearch={
                                           (e) => {
                                               setSearch(e);
                                           }
                                       }
                                   />
                                </div>
                              </AccessControl>}
                            /*
                             titleAction={
                               <AccessControl id={2}>
                                 <Button type={'primary'} onClick={() => setAddNew(true)} icon={<PlusCircleOutlined />}>
                                     Add New
                                 </Button>
                              </AccessControl>
                             }
                             */
            >
                {
                    addNew && <ContainerCreateOrEditPage onClose={
                        (e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }
                    } />
                }
                {
                    editContainer && <ContainerCreateOrEditPage
                        details={editContainer}
                        onClose={
                            (e: boolean) => {
                                if (e) {
                                    refresh();
                                }
                                setEditContainer(null);
                            }
                        } />
                }
                <AccessControl id={1}>
                  <Table loading={fetching} scroll={{ x: true }} pagination={{
                      onChange: (page, perpage) => {
                          setPagination({ ...pagination, page: page, perpage: perpage || pagination.perpage });
                      },
                      pageSize: pagination.perpage,
                      hideOnSinglePage: true,
                      total: total,
                  }} dataSource={containers} columns={columns} />
                </AccessControl>
            </AdminListLayout>
        </div>
    );
}
