import React, { CSSProperties, useState } from 'react';
import './index.scss';
import { Space, Typography, notification, message } from 'antd';
import ProductCardCarousel from 'shared/components/ProductCardCarousel/ProductCardCarousel';
import { HeartOutlined, HeartFilled } from '@ant-design/icons';
import API_SERVICE from 'client/src/services/api-service';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {globals} from 'client/src/App';

const { Text, Title } = Typography;
export default function Wishlist(props: any) {
    const history = useHistory();
    const { t } = useTranslation();
    const [data,set_data] = useState([])

    async function get_wishlist(){
      try {
          const resp = await API_SERVICE.get_wishlist();
          console.log(resp.data.data.items)
          set_data(resp.data.data.items)
      } catch (e) {
        console.log(e)
          notification.error({
              message: t(API_SERVICE.handleErrors(e)),
          });
      }
    }

    async function remove_from_wishlist(product_id: number) {
      try {
          const resp = await API_SERVICE.remove_from_wishlist(product_id);
          message.success("Removed from wishlist")
          get_wishlist()
          globals.refresh()
      } catch (e) {
        console.log(e)
          notification.error({
              message: t(API_SERVICE.handleErrors(e)),
          });
      }
    }

    React.useEffect(()=>{
      get_wishlist()
    },[])

    const parent = {
      category: 'Wishlist',
      subcategory: ''
    }

    return (
        <div>
          <div className="centered">
            <Title style={{margin:16}} level={3}><HeartOutlined/> WISHLIST</Title>
          </div>
          <div className="product-grid">
          {
            data.map((product:any,i)=>
              <div key={i}>
                <div className={'product-card'}>
                    <div className="product-header">
                      <div>Min {product.minimumOrderQuantity}</div>
                      <div>{product.cbnValue} CBM</div>
                      <div style={{color:'#DC202B'}} onClick={()=>remove_from_wishlist(product.id)}><HeartFilled /></div>
                    </div>
                    <img src={product.thumbnail? product.thumbnail.url:''} className="image"
                      onClick={() => {
                        history.push('/products/'+product.id)
                      }} />
                    <div className="details-section">
                        <div>
                            <Text className={'product-code'}>{product.skuId}</Text>
                        </div>
                        <div>
                            <Text className={'product-name'}>{product.name}</Text>
                        </div>
                    </div>
                </div>
              </div>
            )
          }
          </div>
        </div>
    );
}
