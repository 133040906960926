import React, { useState } from 'react';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { Row, Col, Carousel, Typography, notification } from 'antd';
import ProductCardCarousel from 'shared/components/ProductCardCarousel/ProductCardCarousel';
import LoginForm from './LoginForm';

import './Login.scss';
import { useTranslation } from 'react-i18next';

import logo from 'shared/assets/logo.png';
import banner from './assets/banner.png';

//STATIC
import card_stock from './assets/card_stock.png';
import card_shipping from './assets/card_shipping.png';
import card_account from './assets/card_account.png';
import card_training from './assets/card_training.png';
import card_client from './assets/card_client.png';

import { AuthConsumer } from 'shared/contexts/AuthContext';
import API_SERVICE from 'client/src/services/api-service';

const { Title } = Typography;

interface Props {
    location: any;
}

const contentStyle = {
    height: '373px',
    color: '#fff',
    lineHeight: '373px',
    textAlign: 'center',
    background: '#364d79',
};

export default function Login({ location }: Props) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { from } = location.state || { from: { pathname: '/' } };
    return (
        <AuthConsumer>
            {({ isAuth, updateAuthToken, user }) => {
                return isAuth ? (
                    <Redirect
                        to={
                            user.role.name === 'FRANCHISE_STAFF'
                                ? '/training'
                                : from
                        }
                    />
                ) : (
                    <div className="login">
                        <Row
                            gutter={20}
                            justify={'center'}
                            className="login-form-row"
                        >
                            <Col flex="1" className="banner">
                                <img src={banner} />
                            </Col>
                            <Col flex="400px">
                                <div className="login-form-wrapper">
                                    <div className="logo-container">
                                        <img src={logo} />
                                    </div>
                                    <Title level={4}>
                                        Login with username and password
                                    </Title>
                                    <LoginForm
                                        onLogin={async (
                                            email: string,
                                            password: string,
                                        ) => {
                                            setLoading(true);
                                            try {
                                                const {
                                                    data: { data },
                                                } = await API_SERVICE.login(
                                                    email,
                                                    password,
                                                );
                                                updateAuthToken(data);
                                            } catch (e) {
                                                notification.error({
                                                    message: t(
                                                        API_SERVICE.handleErrors(
                                                            e,
                                                        ),
                                                    ),
                                                });
                                                setLoading(false);
                                            }
                                        }}
                                        loading={loading}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={20} style={{ marginTop: '16px' }}>
                            {[
                                card_stock,
                                card_shipping,
                                card_account,
                                card_training,
                                card_client,
                            ].map((item, index) => (
                                <Col key={index} className={'service-card'}>
                                    <div />
                                    <img src={item} className="image" />
                                    <h3 className="text-center text-truncate">
                                        {
                                            [
                                                'STOCK MANAGEMENT',
                                                'SHIPPING MANAGEMENT',
                                                'ACCOUNT MANAGEMENT',
                                                'FRANCHISE DEVELOPMENT',
                                                'CLIENT MANAGEMENT',
                                            ][index]
                                        }
                                    </h3>
                                </Col>
                            ))}
                        </Row>
                    </div>
                );
            }}
        </AuthConsumer>
    );
}
