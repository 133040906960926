import React, { CSSProperties, useState, useEffect } from 'react';
import './index.scss';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import { Carousel, Space, Typography, Modal, Collapse, List, Checkbox, Tree, notification } from 'antd';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import {CloseOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CategorySelector from './CategorySelector';

const { Text, Title } = Typography;
const { Panel } = Collapse;

export default function ProductFilter(props: any) {
    const history = useHistory();
    const { t } = useTranslation();

    const [categories,set_categories] = useState([]);
    const [status,set_status] = useState(true)

    const selection = { ...props.selection }
    async function push_category(id) {
      const index = selection.categoryIds.indexOf(id)
      if (index===-1)
        selection.categoryIds.push(id)
      else selection.categoryIds.splice(index,1)
    }
    async function push_status(id,checked) {
      set_status(id)
    }

    function onclose() {
      props.onChange(selection)
      props.close()
    }

    React.useEffect(()=>{
      const d = props.categories.map((c) => {
          return {
              ...c, title: c.name, key: c.id, children: c.children.map((innerC) => {
                  return {
                      ...innerC,
                      key: innerC.id,
                      title: innerC.name,
                      children: innerC.children.map((subC) => {
                          return { ...subC, key: subC.id, title: subC.name };
                      }),
                  };
              }),
          };
      });
      set_categories(JSON.parse(JSON.stringify(d)));
    },[props.categories])

    React.useEffect(()=>{
      selection.isActive = String(status);
    },[status])

    return (
        <Modal className="selector" zIndex={1200} width={400} closable={false} visible={props.visible} footer={null}>
        <div>
          <div className="series">
            <div className="title-container">
              <div className="title-desc">Product Filters</div>
            </div>
            <CloseOutlined onClick={onclose} />
          </div>
          <div className="collapse-container">
            <Collapse>
             <Panel header="Status" key="1">
                <div style={{marginLeft:24}}>
                  <div>
                    <Checkbox
                      checked={status}
                      onChange={(i)=>push_status(true,i)}
                      > Active</Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      checked={!status}
                      onChange={(i)=>push_status(false,i)}
                      > Disabled</Checkbox>
                  </div>
                </div>
             </Panel>
             {categories.length && <Panel header="Category" key="2">
               <Tree

                   checkable
                   onCheck={async (e: any, v: any) => {
                       const { id, checked } = v.node;
                       push_category(id)
                   }}
                   onSelect={(e) => console.log(e)}
                   treeData={categories}
               />
             </Panel>}
           </Collapse>
          </div>
        </div>
        </Modal>
    );
}
