import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Space, Descriptions, Typography, Dropdown, InputNumber, Upload, Modal, notification, message } from 'antd';
import { UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import API_SERVICE from '../../../services/api-service';

export default function PERFORMA_SIGN_OFF_REQUIRED({ details, callback, setActiveTabKey }: any) {
  const [file_id,set_file_id] = useState(false)
  const [loading,set_loading] = React.useState(false)
  const [amount,set_amount] = React.useState(0)


  async function handle_upload(file,type){
    set_loading(true)
    try {
      const data = await API_SERVICE.upload_file(file,type)
      set_file_id(data.data.data.id)
    }
    catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
    set_loading(false)
  }

  async function download_invoice(type){
    try {
      const data = await API_SERVICE.download_invoice(details.id, type)
      window.open(data.data.data.url, '_blank').focus();
    }
    catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  }

  async function cancel_pi(){
    try {
      await API_SERVICE.cancel_pi(details.id);
      callback();
      setActiveTabKey('2');
    }
    catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  }

 {/* async function download_pi_pdf(){
    try {
      const data = await API_SERVICE.order_download_pdf(details.orderNumber)
      window.open(data.data.data.url, '_blank').focus();
    }
    catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  }

  async function download_pi_excel(){
    try {
      const data = await API_SERVICE.order_download_excel(details.orderNumber)
      window.open(data.data.data.url, '_blank').focus();
    }
    catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  }
*/}

  async function upload_pi(){
    if (!file_id)
      return message.info("Signed performa required")
    set_loading(true)
    try {
      const body = {
        invoiceFileId: file_id
      }
      const data = await API_SERVICE.upload_pi(details.id,body)
      message.info("Order Confirmed")
      window.location.assign('/orders')

    }
    catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
      set_loading(false)
    }
  }

  return (
    <div>
      <div><b>Status</b> : Performa Invoice Recieved from {details?.admin?.name}</div>

      {/*<Button
        type="primary"
        style={{
          marginTop: 16,
          marginBottom: 16,
        }}
        icon={<DownloadOutlined />}
        onClick={()=>{
          download_invoice()
        }}>Proforma Invoice</Button>*/}
        <Space>
        <Button
          type="primary"
          className="p-4"
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
          icon={<DownloadOutlined />}
          onClick={()=>{
            download_invoice()
          }}>Download PI PDF</Button>

        <Button
          type="primary"
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
          icon={<DownloadOutlined />}
          onClick={()=>{
            download_invoice({type:'excel'})
          }}>Download PI Excel</Button>

          <Button
          type="primary"
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
          onClick={()=>{
            cancel_pi()
          }}>Cancel PI</Button>
          </Space>

      <br/>
      <b>Sign & Upload</b> :
      <div style={{
        marginTop: 8,
        width: 200,
        }} >

        <Upload
          accept=".png,.jpg,.jpeg,.pdf"
          maxCount={1}
          beforeUpload={file => {
            handle_upload(file,'DOCUMENT')
            return false;
           }}
          onRemove={()=>set_file_id(false)}
          className="upload-list-inline">
          {
            (!file_id && !loading)?
            <Button icon={<UploadOutlined />}>Upload Signed Performa</Button>
            :null
          }
        </Upload>
      </div>
      <Button
        type="primary"
        style={{
          marginTop: 16,
          width: 140,
        }}
        disabled={loading}
        onClick={()=>{
          upload_pi()
        }}>Confirm Order</Button>
    </div>
  )
}

const styles = {
  label: {
    width: 120,
    paddingTop: 5,
    textAlign: 'center',
    backgroundColor: '#F9F9F9',
    border: '1px solid #C7C7C7'
  }
}
