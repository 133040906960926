import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Space, Descriptions, Typography, Dropdown, InputNumber, Menu, Upload, Pagination, Table, Row, Col, Tag, Checkbox, Select, Modal, Radio, notification, message } from 'antd';
import { DeleteOutlined, UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import API_SERVICE from '../../../services/api-service';

const { Text, Title, Link } = Typography;
export default function PAYMENT_SIGNOFF({ details }: any) {
    const [file_id,set_file_id] = useState(false)
    const [amount,set_amount] = useState(0)
    const [loading,set_loading] = React.useState(false)
    const [sign,set_sign] = React.useState(null)

    const form_width = 242

    async function submit(){

      if (sign===null)
        return message.info("Please confirm payment sign-off")
      if (!file_id)
        return message.info("Payment receipt required")
      set_loading(true)
      try {
        const data = await API_SERVICE.payment_sign_off(details.id,
          {
            "paymentFileId" : file_id,
            "receivedAmount" : amount,
            'isPaymentSignOff': sign
          }
        )
        window.location.assign('/admin/orders')
      }
      catch (e) {
        console.log(e)
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
      set_loading(false)
    }

    async function handle_upload(file){
      try {
        const data = await API_SERVICE.upload_file(file,'DOCUMENT')
        set_file_id(data.data.data.id)
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
    }

    async function download_invoice(){
      try {
        const data = await API_SERVICE.download_invoice(details.id)
        window.open(data.data.data.url, '_blank').focus();
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
    }

    return (
      <div style={{
        paddingTop: 8,
        paddingBottom: 32,
        }} >
        <div><b>Status</b> : Payment Sign-Off Required</div>
        <br/>
        <div style={{display:'flex'}}>
          <div style={{flex:1}}>
              <Button
                type="primary"
                style={{
                  marginBottom: 16,
                }}
                icon={<DownloadOutlined />}
                onClick={()=>{
                  download_invoice()
                }}>Performa Invoice</Button>
              <br/>
              <b>Uploaded by Client</b> :
              <div style={{
                marginTop: 8,
                }} >
                <Button
                  type="primary"
                  style={{
                    marginBottom: 16,
                  }}
                  icon={<DownloadOutlined />}
                  onClick={()=>{
                    const signed_invoice = details.performaInvoice
                    if (signed_invoice)
                      window.open(signed_invoice.url, '_blank').focus();
                  }}>Signed Performa Invoice</Button>
              </div>
          </div>

          <div style={{flex:1}}>
              <b>Payment by Client</b> :
              <br/>
              <Button
                type="primary"
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                }}
                icon={<DownloadOutlined />}
                onClick={()=>{
                  const orderPaymentFile = details.orderPaymentFile
                  if (orderPaymentFile && orderPaymentFile.file)
                    window.open(orderPaymentFile.file.url, '_blank').focus();
                }}>Payment Submitted Doc</Button>
              <br/>
              <b>${details.orderPaymentFile && details.orderPaymentFile.amount} out of ${details.totalCbmOrder && details.totalCbmOrder.portAmount}</b>
              <br/>
              <div style={{
                marginTop: 16,
              }}>
                Mark this Payment as Sign-Off for Shipping :
              </div>
              <Radio.Group onChange={(e)=>set_sign(e.target.value)}>
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
          </div>

          <div style={{flex:1}} >
              <Upload
                accept=".png,.jpg,.jpeg,.pdf"
                maxCount={1}
                beforeUpload={file => {
                  handle_upload(file)
                  return false;
                 }}
                className="upload-list-inline">
                <Button icon={<UploadOutlined />}>Upload Payment Receipt/Note</Button>
              </Upload>
              <div style={{
                marginTop: 12,
                width: form_width,
              }} >
                <div>Amount Recieved:</div>
                <InputNumber
                  value={amount}
                  onChange={set_amount}
                  placeholder="Enter in USD"
                  />
              </div>
              <Button
                type="primary"
                style={{
                  marginTop: 16,
                  width: form_width,
                }}
                disabled={loading}
                onClick={()=>{
                  submit()
                }} >
                Upload & Mark as Received
                </Button>
          </div>

        </div>

      </div>
    )
}
