import React, { CSSProperties, useEffect, useState } from 'react';
import {
    Button,
    Space,
    Descriptions,
    Typography,
    Dropdown,
    InputNumber,
    Menu,
    Upload,
    Pagination,
    Table,
    Row,
    Col,
    Tag,
    Checkbox,
    Select,
    Modal,
    notification,
    message,
} from 'antd';
import {
    DeleteOutlined,
    UploadOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import API_SERVICE from '../../../services/api-service';

const { Text, Title, Link } = Typography;
export default function READY_TO_SHIP({ details }: any) {
    const [loading, set_loading] = React.useState(false);
    const [file_id, set_file_id] = React.useState(false);

    async function submit() {
        set_loading(true);
        try {
            const data = await API_SERVICE.ready_to_ship(details.id, {
                shipmentFileId: file_id,
            });
            window.location.assign('/admin/orders');
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
        set_loading(false);
    }

    async function handle_upload(file) {
        try {
            const data = await API_SERVICE.upload_file(file, 'DOCUMENT');
            set_file_id(data.data.data.id);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    }

    return (
        <div>
            <div>
                <b>Status</b> : Ready to Ship
            </div>

            <div
                style={{
                    marginTop: 8,
                    width: 200,
                }}
            >
                <br />
                <Upload
                    accept=".png,.jpg,.jpeg,.pdf,.zip"
                    maxCount={1}
                    beforeUpload={(file) => {
                        handle_upload(file);
                        return false;
                    }}
                    className="upload-list-inline"
                >
                    <Button icon={<UploadOutlined />}>
                        Upload Shipping Documents & Details
                    </Button>
                </Upload>
            </div>
            <Button
                type="primary"
                style={{
                    marginTop: 16,
                }}
                disabled={loading}
                onClick={() => {
                    submit();
                }}
            >
                Mark Order as Shipped
            </Button>
        </div>
    );
}
