import React from 'react';
import './index.scss';
import { Button, Col, Divider, message, Modal, notification, Popconfirm, Row, Select, Typography,Input } from 'antd';
import { DeleteOutlined, HeartFilled, HeartOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from '../../services/api-service';
import Counter from './Counter';
import { globals } from 'client/src/App';

function toFixed(n, i = 2) {
    const str = '' + n;
    const index = str.indexOf('.');
    if (index > -1) {
        return str.substring(0, (index + 1) + i);
    }
    return str;
}

const { Text, Title, Link } = Typography;
export default function CartSummary(props) {
    console.log("props received" + props);

    const cartMap = {};
    const history = useHistory();
    const { t } = useTranslation();
    const products = props.products;
    let boxQuantity=props.boxQuantity;
    console.log("boxquantity received" + boxQuantity );
    // const cart = props.cart
    // const product = props.cart
    const otherDetails = props.otherDetails;
    const formatter = new Intl.NumberFormat('en-US');
    // const container = cart.cartContainer || {};
    const [checkout_flag, set_checkout_flag] = React.useState(false);
    const [addresses, set_addresses] = React.useState([]);
    const [addressId, set_addressId] = React.useState(null);

    async function checkout() {
        try {
            if (!addressId)
                return message.info('Select address first');
            set_checkout_flag(false);
            const resp = await API_SERVICE.checkout_cart(otherDetails.id, addressId);
            globals.refresh();
            message.info('Order placed successfully');
            history.push('/orders');
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    async function get_addresses() {
        try {
            set_checkout_flag(false);
            const resp = await API_SERVICE.get_addresses();
            set_addresses(resp.data.data.items);
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    async function add_to_wishlist(product_id) {
        try {
            const resp = await API_SERVICE.add_to_wishlist(product_id);
            message.success('Added to wishlist');
            props.refresh();
            globals.refresh();
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    async function get_cart_download(cart_id) {
        try {
            const resp = await API_SERVICE.cart_download(cart_id);
            window.open(resp.data.data.item, '_blank');
            message.success('Downloaded cart');
            props.refresh();
            globals.refresh();
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    async function remove_from_wishlist(product_id) {
        try {
            const resp = await API_SERVICE.remove_from_wishlist(product_id);
            message.success('Removed from wishlist');
            props.refresh();
            globals.refresh();
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    // async function search_by_name(product_id) {
    //     try {
    //         const resp = await API_SERVICE.    (product_id);
    //         message.success('Removed from wishlist');
    //         props.refresh();
    //         globals.refresh();
    //     } catch (e) {
    //         console.log(e);
    //         notification.error({
    //             message: t(API_SERVICE.handleErrors(e)),
    //         });
    //     }
    // }

    async function remove_product(product) {
        try {
            const body = {
                'type': props.id,
                'portId': product.portId,
                'productId': product.id,
                'quantity': 0,
            };
            const resp = await API_SERVICE.add_to_cart(body);
            props.refresh();
            globals.refresh();
        } catch (e) {
            console.log('loglog', e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    function cart_object() {
        const mapping = {
            'ON_STOCK': 'totalCbmCart',
            'FROM_SOURCE': 'totalCbmCartPort',
            'PRE_ORDER': 'totalCbmCartPreorder',
        };
        return cartMap[mapping[props.id]] || {
            cbmSum: 0.0,
            amount: 0.0,
        };
    }

    function ifAboutToFillBucket(value) {
        let cartSize = 67;
        let rem = value % cartSize;
        if ((67 - rem) > 3) {
            return false;
        }
        return true;
    }

    React.useEffect(() => {
        get_addresses();
    }, []);

    return (
        <>
        
            <div className='flex'>
                <div style={{ flex: 1 }}>
                    <div>
                        <Row align='middle' gutter={20}>
                            <Col span={12}>
                                <div className='title-black' style={{ marginLeft: 38 }}>Items</div>
                            </Col>
                            <Col span={4}>
                                <center>
                                    <div className='title-black'>Qty</div>
                                </center>
                            </Col>
                            <Col span={4}>
                                <center>
                                    <div className='title-black'>CBM</div>
                                </center>
                            </Col>
                            <Col span={4}>
                                <center>
                                    <div className='title-black'>Amount</div>
                                </center>
                            </Col>
                        </Row>
                        <Divider style={{ margin: 12 }} />
                        {
                            products.map((product, productIndex) => {
                                return <>
                                    <Row gutter={20}>
                                        <Col span={12}>
                                            <Row gutter={10} align='middle'>
                                                <Col>
                                                    <DeleteOutlined className='action-icon'
                                                                    onClick={() => remove_product(product)} />
                                                    {(!product.isWishlisted) ?
                                                        <div className='action-icon'><HeartOutlined
                                                            onClick={() => add_to_wishlist(product.id)} /></div>
                                                        : <div className='action-icon'><HeartFilled
                                                            onClick={() => remove_from_wishlist(product.id)} /></div>
                                                    }
                                                </Col>
                                                <Col>
                                                    <img src={product.thumbnail ? product.thumbnail.url : ''}
                                                         className='thumbnail' />
                                                </Col>
                                                <Col style={{ flex: 1 }}>
                                                    <div>
                                                        <div className='title-black'>{product.name}</div>
                                                        SKU: {product.skuId} | MOQ: {product.minimumOrderQuantity}
                                                        <br />
                                                        ${
                                                        product[{
                                                            'ON_STOCK': 'dubaiPrice',
                                                            'FROM_SOURCE': 'portPrice',
                                                            'PRE_ORDER': 'customPrice',
                                                        }[props.id]]
                                                    }/unit | {product.cbnValue}CBM/unit
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={4}>
                                            <Counter
                                                key={product.id}
                                                id={props.id}
                                                portId={product.portId}
                                                quantity={product.cartDetails.quantity}
                                                productId={product.id}
                                                onChange={props.refresh}
                                                boxQuantity={product.boxQuantity}
                                            />
                                        </Col>
                                        <Col span={4}>
                                            <center>
                                                <div
                                                    className='title-desc'>{product.cartDetails && product.cartDetails.productCbn && (product.cartDetails.productCbn).toFixed(3)} CBM
                                                </div>
                                            </center>
                                        </Col>
                                        <Col span={4}>
                                            <center>
                                                <div
                                                    className='title-desc'>${formatter.format(Number(toFixed(product?.cartDetails?.amount)))}</div>
                                            </center>
                                        </Col>
                                    </Row>
                                    {productIndex !== (products.length - 1) && <Divider style={{ margin: 12 }} />}
                                </>;
                            })
                        }

                        <Divider style={{ margin: 12 }} />
                        <Row gutter={20}>
                            <Col span={16} />
                            <Col span={4}>
                                <center>
                                    <div
                                        className='title-black'>{(otherDetails.totalCbmCart.cbmSum).toFixed(3)} CBM
                                    </div>
                                </center>
                            </Col>
                            <Col span={4}>
                                <center>
                                    <div
                                        className='title-black'>${formatter.format(Number(otherDetails.totalCbmCart.amount.toFixed(2)))}</div>
                                </center>
                            </Col>
                        </Row>
                    </div>
                </div>

                {
                    <div className='checkout-container'>
                        <Col style={{ padding: 12 }}>
                            <center>
                                <div className='title-black'>Checkout Summary</div>
                            </center>
                            <Col className='details'>
                                <div className='title-desc'>Shipping Volume</div>
                                <br />
                                <Row justify='space-between'>
                                    <div className='text-desc'>Containers :</div>
                                    <div
                                        className='text-desc'>{otherDetails.cartContainer && (`${otherDetails.cartContainer.quantity} X ${otherDetails.cartContainer.container.description} = ${(otherDetails.cartContainer.container.cbm * otherDetails.cartContainer.quantity).toFixed(3)} CBM`)}</div>
                                </Row>
                            </Col>
                            <Row justify='space-between'>
                                <div className='title-desc'>Items Sub-Total</div>
                                <div className='title-desc'>${formatter.format(Number(otherDetails.totalCbmCart.amount.toFixed(2)))}</div>
                            </Row>
                        </Col>

                        {
                            (toFixed(cart_object().cbmSum) >= 24 && toFixed(cart_object().cbmSum) < 27)
                                ? <Popconfirm
                                    title={`${toFixed(27 - toFixed(cart_object().cbmSum))} CBM still available in container. Do you still want to proceed?`}
                                    onConfirm={() => set_checkout_flag(true)}>
                                    <Button type='primary' className='action-button-red'>
                                        CHECKOUT
                                    </Button>
                                </Popconfirm>
                                :
                                (toFixed(cart_object().cbmSum) >= 64 && ifAboutToFillBucket(cart_object().cbmSum))
                                    ? <Popconfirm
                                        title={`${toFixed(67 - (toFixed(cart_object().cbmSum) % 67))} CBM still available in container. Do you still want to proceed?`}
                                        onConfirm={() => set_checkout_flag(true)}>
                                        <Button type='primary' className='action-button-red'>
                                            CHECKOUT
                                        </Button>
                                    </Popconfirm>
                                    : <Button type='primary' className='action-button-red'
                                              onClick={() => set_checkout_flag(true)}>CHECKOUT</Button>
                        }
                        <Button type='default' className='action-button-download' block
                                onClick={() => get_cart_download(otherDetails.id)}>DOWNLOAD CART </Button>
                    </div>

                }
            </div>

            {
                (addresses && addresses.length) ?
                    <Modal
                        visible={checkout_flag}
                        width={550}
                        onCancel={() => set_checkout_flag(false)}
                        onOk={checkout}>
                        <div>Select address to place order :</div>
                        <br />
                        <Select style={{ width: 500 }} onChange={set_addressId}>
                            {
                                addresses.map((i, index) => {
                                    return <Select.Option
                                        value={i.id}>{i.houseNumber ? i.houseNumber : ''}{i.lineOne ? ', ' + i.lineOne : ''}{i.lineTwo ? ', ' + i.lineTwo : ''}{i.city ? ', ' + i.city : ''}{i.state ? ', ' + i.state : ''}{(i.country && i.country.name) ? ', ' + i.country.name : ''}{i.port ? ', ' + i.port : ''}{i.zipCode ? ', ' + i.zipCode : ''}</Select.Option>;
                                })
                            }
                        </Select>
                    </Modal>
                    : <Modal
                        visible={checkout_flag}
                        width={400}
                        onCancel={() => set_checkout_flag(false)}
                        onOk={() => {
                            window.location.assign('/addresses');
                        }}
                        okText='Add address'>
                        <div>Add address to place order</div>
                        <br />
                    </Modal>
            }

        </>
    );

}
