import React, {useState} from 'react';
import './index.scss';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import { Checkbox, Col, Row, Input, Typography ,} from 'antd';
import { DirectionsBoatOutlined, CloseOutlined } from '@material-ui/icons';

import PortSelector from './PortSelector';
import CategorySelector from './CategorySelector';
import {get_port} from 'client/src/services/local-storage';
import { useHistory } from 'react-router-dom';

const { Search } = Input;

const { Text, Link } = Typography;
export default function HomeHeaderRowTwo(props: any) {
    const history = useHistory();
    const [port_select,set_port_select] = useState(false)
     const [category_select,set_category_select] = useState(false)
    const [port,set_port] = useState(get_port())
    const [category,set_category]=useState(get_port())
    return (
        <div className="home-header-row-2">
            <Row gutter={20} align={'middle'}>
                <Col>
                    <Text className='viewby'>View: By  
                   <Link className="home-header-row-2-category" onClick={()=>set_category_select(true)}>
                            { "Category"}
                   </Link>
                    
                    
                    </Text>
                </Col>
                <Col>
                        <Link onClick={()=>{
                          localStorage.setItem('port-preference','')
                          set_category({})
                          }} >
                          {port.name && <CloseOutlined style={{fontSize:16}} />}
                        </Link>
                      </Col>
                
                <Col>
                    <Row align="middle" gutter={4}>
                      {/*<Col>
                        <DirectionsBoatOutlined/>
                        &nbsp;
                        <Link onClick={()=>set_port_select(true)}>
                            {port.name || "Availability at Port"}
                        </Link>
                      </Col>
                      <Col>
                        <Link onClick={()=>{
                          localStorage.setItem('port-preference','')
                          set_port({})
                          }} >
                          {port.name && <CloseOutlined style={{fontSize:16}} />}
                        </Link>
                        </Col>*/}
                    </Row>
                </Col>
                {/*<Col>*/}
                {/*    <DirectionsBoatOutlined/>*/}
                {/*    &nbsp;*/}
                {/*    <Link href="#">*/}
                {/*        CBM*/}
                {/*    </Link>*/}
                {/*</Col>*/}
                <Col flex={'auto'}>
                    <div className="search-bar">
                        <Search
                            placeholder="Looking for something?"
                            className="search-bar-something"
                            enterButton="Search"
                            size="large"
                            onSearch={(i) => {
                                history.push('/home/search?q='+i);
                            }}
                            prefix={<DirectionsBoatOutlined/>}
                        />
                    </div>
                </Col>
               {/* <Col>
                    <Checkbox defaultChecked={true}>Dubai</Checkbox>
                </Col>
                <Col>
                    <Checkbox defaultChecked={true}>Ready Source</Checkbox>
                </Col>
                <Col>
                    <Checkbox defaultChecked={true}>Customize Source</Checkbox>
                </Col>
                */}
            </Row>
            <PortSelector
              visible={port_select}
              close={()=>{
                set_port_select(false)
                set_port(get_port())
              }} />
             <CategorySelector
              visible={category_select}
              close={()=>{
                set_category_select(false)
                set_category(get_port())
              }} />
        </div>
    );
}
