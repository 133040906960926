import React, { useEffect, useState } from 'react';
import {
    Button,
    notification,
    Pagination,
    Space,
    Table,
    Typography,
} from 'antd';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import {
    DownloadOutlined,
    EyeOutlined,
    PlusCircleOutlined,
    ShopOutlined,
} from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import DocumentFilter from './DocumentFilter';
import moment from 'moment';
import { handleBlob } from 'shared/services/Utility';
import Search from 'antd/es/input/Search';

const { Text } = Typography;

export default function ViewDocument(props: any) {
    const [search, setSearch] = useState('');
    const [filter_view, set_filter_view] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: '10',
    } as any);
    const [total, setTotal] = useState(10);
    const [data, setData] = useState();
    const [documentType, setDocumentType] = useState([]);
    const [franchiseList, setFranchiseList] = useState([]);

    const [filter, set_filter] = useState({
        documentTypeIds: [],
        franchiseListIds: [],
        isActive: true,
    });

    const columns = [
        {
            title: 'S.No',
            dataIndex: 'sno',
            key: 'sNO',
            render: (a: any, v: any, index: number) => (
                <Text>
                    {(pagination.page - 1) * pagination.perpage + index + 1}
                </Text>
            ),
        },
        {
            title: 'Document Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Document Type',
            key: 'type',
            dataIndex: 'documentType',
            render: (a: any) => a?.name,
        },
        {
            title: 'Franchise Name',
            key: 'franchiseName',
            dataIndex: 'user',
            render: (a: any) => a?.franchise?.name,
        },
        {
            title: 'Uploaded At',
            key: 'updatedAt',
            render: (d: any) => {
                return moment(d?.updatedAt).format('DD/MM/YYYY');
            },
        },
        {
            title: 'Actions',
            key: 'action',
            render: (a: any) => {
                return (
                    <Space>
                        <Button
                            type="default"
                            shape="circle"
                            onClick={() => window.open(a?.file?.url, '_blank')}
                            icon={<EyeOutlined />}
                        />

                        <Button
                            type="default"
                            shape="circle"
                            onClick={() => {
                                forceDownloadFile(a.fileId, a?.file?.name);
                            }}
                            icon={<DownloadOutlined />}
                        />
                    </Space>
                );
            },
        },
    ];

    const forceDownloadFile = async (fileId: number, fileName: string) => {
        try {
            const params = {
                fileId,
            };
            const { data } = await API_SERVICE.forceDownloadFile(params);
            handleBlob(data, fileName);
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
                placement: 'bottomRight',
            });
        }
    };

    const refresh = async () => {
        setFetching(true);
        const params = {
            page: pagination.page,
            perpage: pagination.perpage,
        };
        //@ts-ignore
        if (search) params['q'] = search;
        if (filter.documentTypeIds) {
            //@ts-ignore
            params['documentTypeIds'] = filter.documentTypeIds;
        }
        if (filter.franchiseListIds) {
            //@ts-ignore
            params['franchiseIds'] = filter.franchiseListIds;
        }
        try {
            const {
                data: { data },
            } = await API_SERVICE.get_view_documents_uploaded_by_franchise(
                params,
            );
            setData(data.items);
            setTotal(data.total);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };
    const getDocumentTypes = async () => {
        try {
            const params = {
                type: 'franchise',
            };
            const data = await API_SERVICE.get_documents_type(params);
            setDocumentType(data.data.data);
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
                placement: 'bottomRight',
            });
        }
    };
    const getFranchiseList = async () => {
        try {
            const params = {
                type: 'admin',
            };
            const {
                data: { data },
            } = await API_SERVICE.get_franchise_list();
            setFranchiseList(data);
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
                placement: 'bottomRight',
            });
        }
    };
    useEffect(() => {
        // refresh();
        getDocumentTypes();
        getFranchiseList();
    }, []);

    useEffect(() => {
        refresh();
    }, [props.location, pagination, filter, search]);

    return (
        <div className={'franchise-wrapper page-wrapper'}>
            <AdminListLayout
                titleIcon={<ShopOutlined />}
                title={'View Documents (Uploaded by Franchise)'}
                titleSearch={
                    <div className="search-bar">
                        <Search
                            placeholder="Search any Document here"
                            size="large"
                            onSearch={(e) => {
                                setPagination({ ...pagination, page: 1 });
                                setSearch(e);
                            }}
                        />
                    </div>
                }
                subheader={[
                    <Button
                        key="2"
                        type={'primary'}
                        onClick={() => set_filter_view(true)}
                        icon={<PlusCircleOutlined />}
                    >
                        Filter
                    </Button>,
                ]}
            >
                <Table
                    loading={fetching}
                    scroll={{ x: true }}
                    dataSource={data}
                    pagination={false}
                    columns={columns}
                />
                <div style={{ float: 'right', padding: '20px' }}>
                    <Pagination
                        key="4"
                        defaultPageSize={pagination.perpage}
                        showSizeChanger
                        defaultCurrent={1}
                        total={total}
                        onShowSizeChange={(current, pageSize) => {
                            setPagination({
                                page: current,
                                perpage: pageSize,
                            });
                        }}
                        onChange={(page, size) =>
                            setPagination({ page: page, perpage: size })
                        }
                    />
                </div>
                <DocumentFilter
                    documentType={documentType}
                    franchiseList={franchiseList}
                    selection={filter}
                    onChange={set_filter}
                    visible={filter_view}
                    close={() => set_filter_view(false)}
                />
            </AdminListLayout>
        </div>
    );
}
