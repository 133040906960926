import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import {
    Button,
    Menu,
    notification,
    Space,
    Table,
    Tag,
    Typography,
    Modal,
} from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import {
    UserOutlined,
    ShopOutlined,
    EditOutlined,
    DeleteOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
//import UserCreateOrEditPage from './UserCreateOrEditPage';
import UserProfile from './UserProfile';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function ProfileList(props: any) {
    const history = useHistory();
    const [franchises, setFranchises] = useState([] as any[]);
    const [search, setSearch] = useState('' as string);
    const [addNew, setAddNew] = useState(false);
    const [editUser, setEditUser] = useState(null as any);
    const [deleteUser, setDeleteUser] = useState(null as any);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: '20',
    } as any);
    const [total, setTotal] = useState(0);
    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
        },
        /*
        {
            title: 'Unique ID',
            key: 'sNO',
            render: (a: any, v: any, index: number) => <Text>{a.id}</Text>,
        },
        {
            title: 'Username',
            key: 'sNO',
            render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
        },
        */
        {
            title: 'Full Name & Role',
            key: 'name',
            render: (a: any) => (
                <Space size={0}>
                    <Text>
                        {a.name || 'NA'}
                        <br />
                        <i>{a.role ? a.role.name.replace(/_/g, ' ') : 'NA'}</i>
                    </Text>
                </Space>
            ),
        },
        {
            title: 'Contact Details',
            key: 'contactDetails',
            render: (a: any) => (
                <Space direction={'vertical'} size={0}>
                    <Text>{a.phone || 'NA'}</Text>
                    <Text>{a.email || 'NA'}</Text>
                </Space>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'isActive',
            render: (a: boolean, value: any) => (
                <div
                    style={{ cursor: 'none', pointerEvents: 'none' }}
                    onClick={async (e) => {
                        try {
                            setFetching(true);
                            await API_SERVICE.userUpdate(value.id, {
                                ...value,
                                isActive: !value.isActive,
                            });
                            refresh();
                        } catch (e) {
                            notification.error({
                                message: API_SERVICE.handleErrors(e),
                            });
                        } finally {
                            setFetching(false);
                        }
                    }}
                >
                    {a ? (
                        <Tag color="green">Active</Tag>
                    ) : (
                        <Tag color="red">Inactive</Tag>
                    )}
                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'action',
            render: (a: any, value: any) => {
                const disabled = a.role && a.role.name === 'FRANCHISE_OWNER';
                if (disabled)
                    return (
                        <Space size={0}>
                            <EditOutlined
                                className="action-icon"
                                style={{ color: '#C7C7C7' }}
                            />
                            <DeleteOutlined
                                className="action-icon"
                                style={{ color: '#C7C7C7' }}
                            />
                        </Space>
                    );
                return (
                    <Space size={0}>
                        <EditOutlined
                            className="action-icon"
                            onClick={() => {
                                setEditUser(value);
                            }}
                        />
                        <DeleteOutlined
                            className="action-icon"
                            onClick={() => {
                                setDeleteUser(value);
                            }}
                        />
                    </Space>
                );
            },
        },
    ];
    const refresh = async () => {
        setFetching(true);
        const params = { ...pagination };
        if (search) {
            params['q'] = search;
        }
        try {
            const {
                data: { data },
            } = await API_SERVICE.get_franchise_users(params);
            console.log(data.items);
            setFranchises(data.items);
            setTotal(data.total);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };
    useEffect(() => {
        refresh();
    }, [props.location, pagination, search]);
    return (
        <div className={'franchise-wrapper page-wrapper'}>
            <AdminListLayout
                titleIcon={<UserOutlined />}
                title={'Manage Franchise Users'}
                /*titleSearch={
                <div className='search-bar'>
                   <Search
                       placeholder='Search for Users here'
                       size='large'
                       onSearch={
                           (e) => {
                               setSearch(e);
                           }
                       }
                   />
                </div>}*/
                titleAction={
                    <Button
                        type={'primary'}
                        onClick={() => setAddNew(true)}
                        icon={<PlusCircleOutlined />}
                    >
                        Add New
                    </Button>
                }
            >
                {addNew && (
                    <UserProfile
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }}
                    />
                )}
                {editUser && (
                    <UserProfile
                        details={editUser}
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setEditUser(null);
                        }}
                    />
                )}
                {deleteUser && (
                    <Modal
                        closable={false}
                        zIndex={1200}
                        width={300}
                        centered
                        okText="Yes"
                        cancelText="No"
                        onOk={async () => {
                            try {
                                await API_SERVICE.userDelete(deleteUser.id);
                                refresh();
                            } catch (e) {
                                notification.error({
                                    message: API_SERVICE.handleErrors(e),
                                });
                            } finally {
                                setDeleteUser(null);
                            }
                        }}
                        onCancel={() => setDeleteUser(null)}
                        visible={true}
                    >
                        <Text>Delete this user ?</Text>
                    </Modal>
                )}
                <Table
                    loading={fetching}
                    scroll={{ x: true }}
                    pagination={{
                        onChange: (page, perpage) => {
                            setPagination({
                                ...pagination,
                                page: page,
                                perpage: perpage || pagination.perpage,
                            });
                        },
                        pageSize: pagination.perpage,
                        hideOnSinglePage: true,
                        total: total,
                    }}
                    dataSource={franchises}
                    columns={columns}
                />
            </AdminListLayout>
        </div>
    );
}
