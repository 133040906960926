import React, { useEffect, useState } from 'react';
// import './index.scss';
import {
    Button,
    notification,
    Pagination,
    Space,
    Table,
    Tag,
    Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import {
    DownloadOutlined,
    EyeOutlined,
    PlusCircleOutlined,
    ShopOutlined,
} from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import DocumentFilter from './DocumentFilter';
import UploadDocumentPage from './UploadDocumentPage';
import moment from 'moment';
import Search from 'antd/es/input/Search';

const { Text } = Typography;

export default function UploadDocument(props: any) {
    const history = useHistory();
    const [search, setSearch] = useState('');
    const [filter_view, set_filter_view] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: '10',
    } as any);
    const [total, setTotal] = useState(10);
    const [data, setData] = useState();
    const [documentType, setDocumentType] = useState([]);
    const [franchiseList, setFranchiseList] = useState([]);
    const [selection_arr, set_selection] = useState([]);

    const [filter, set_filter] = useState({
        documentTypeIds: [],
        franchiseListIds: [],
        isActive: true,
    });
    const _textTruncate = (title: string) => {
        return title.substring(0, 120) + '...';
    };

    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            render: (a: any, v: any, index: number) => (
                <Text>
                    {(pagination.page - 1) * pagination.perpage + index + 1}
                </Text>
            ),
        },
        {
            title: 'Document Title',
            key: 'title',
            dataIndex: 'title',
            // render: (a: any) => <Space size={0}>
            //     <Text>
            //       {a.name || 'NA'}<br/>
            //       {a.role? a.role.name : 'NA'}
            //     </Text>
            // </Space>,
        },
        {
            title: 'Document Type',
            key: 'type',
            dataIndex: 'documentType',
            render: (a: any) => a?.name,
        },
        {
            title: 'Franchise Name',
            key: 'franchiseName',
            dataIndex: 'documentsFranchises',
            ellipsis: true,
            width: 20,
            render: (a: any) => {
                return (
                    <div
                        className="text-truncate"
                        style={{
                            width: '250px',
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                            overflow: 'hidden',
                        }}
                    >
                        {a
                            .map(
                                (item: {
                                    user: { franchise: { name: any } };
                                }) => {
                                    return item?.user?.franchise?.name;
                                },
                            )
                            .join(', ')}
                    </div>
                );
            },
        },
        {
            title: 'Uploaded At',
            key: 'updatedAt',
            render: (d: any) => {
                return moment(d?.updatedAt).format('DD/MM/YYYY');
            },
        },
        {
            title: 'Status',
            dataIndex: 'isActive',
            // align: 'center',
            render: (a: boolean, value: any) => (
                <div
                    onClick={async (e) => {
                        try {
                            setFetching(true);
                            const params = {
                                isActive: !value.isActive ? 1 : 0,
                            };
                            await API_SERVICE.franchise_status(
                                value.id,
                                params,
                            );
                            refresh();
                        } catch (e) {
                            notification.error({
                                message: API_SERVICE.handleErrors(e),
                            });
                        } finally {
                            setFetching(false);
                        }
                    }}
                >
                    {a ? (
                        <Tag color="green">Active</Tag>
                    ) : (
                        <Tag color="red">Inactive</Tag>
                    )}
                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'action',
            render: (a: any, value: any) => {
                return (
                    <Space>
                        <Button
                            type="default"
                            shape="circle"
                            onClick={() => window.open(a.file.url, '_blank')}
                            icon={<EyeOutlined />}
                        />

                        <Button
                            type="default"
                            shape="circle"
                            onClick={() => window.open(a.file.url, '_blank')}
                            icon={<DownloadOutlined />}
                        />
                    </Space>
                );
            },
        },
    ];
    const refresh = async () => {
        setFetching(true);
        set_selection([]);
        const params = {
            page: pagination.page,
            perpage: pagination.perpage,
        };
        //@ts-ignore
        if (search) params['q'] = search;
        if (filter.documentTypeIds) {
            //@ts-ignore
            params['documentTypeIds'] = filter.documentTypeIds;
        }
        if (filter.franchiseListIds) {
            //@ts-ignore
            params['franchiseIds'] = filter.franchiseListIds;
        }
        try {
            const {
                data: { data },
            } = await API_SERVICE.get_uploaded_documents(params);
            setData(data.items);
            setTotal(data.total);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };
    const getDocumentTypes = async () => {
        try {
            const params = {
                type: 'admin',
            };
            const data = await API_SERVICE.get_documents_type(params);
            setDocumentType(data.data.data);
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
                placement: 'bottomRight',
            });
        }
    };
    const getFranchiseList = async () => {
        try {
            const params = {
                type: 'admin',
            };

            const {
                data: { data },
            } = await API_SERVICE.get_franchise_list();
            setFranchiseList(data);
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
                placement: 'bottomRight',
            });
        }
    };
    useEffect(() => {
        refresh();
        getDocumentTypes();
        getFranchiseList();
    }, []);
    useEffect(() => {
        if (!addNew) refresh();
    }, [props.location, pagination, filter, addNew, search]);
    return (
        <div className={'franchise-wrapper page-wrapper'}>
            <AdminListLayout
                titleIcon={<ShopOutlined />}
                title={'Upload Documents'}
                titleAction={
                    <Button
                        type={'primary'}
                        onClick={() => setAddNew(true)}
                        icon={<PlusCircleOutlined />}
                    >
                        Add New
                    </Button>
                }
                titleSearch={
                    <div className="search-bar">
                        <Search
                            placeholder="Search any Document here"
                            size="large"
                            onSearch={(e) => {
                                setPagination({ ...pagination, page: 1 });
                                setSearch(e);
                            }}
                        />
                    </div>
                }
                subheader={[
                    <Button
                        key="2"
                        type={'primary'}
                        onClick={() => set_filter_view(true)}
                        icon={<PlusCircleOutlined />}
                    >
                        Filter
                    </Button>,
                ]}
            >
                {addNew && (
                    <UploadDocumentPage
                        onClose={(e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }}
                    />
                )}

                <Table
                    loading={fetching}
                    scroll={{ x: true }}
                    dataSource={data}
                    pagination={false}
                    columns={columns}
                />
                <div style={{ float: 'right', padding: '20px' }}>
                    <Pagination
                        key="4"
                        defaultPageSize={pagination.perpage}
                        showSizeChanger
                        defaultCurrent={1}
                        total={total}
                        onShowSizeChange={(current, pageSize) => {
                            setPagination({
                                page: current,
                                perpage: pageSize,
                            });
                        }}
                        onChange={(page, size) =>
                            setPagination({ page: page, perpage: size })
                        }
                    />
                </div>
                <DocumentFilter
                    documentType={documentType}
                    franchiseList={franchiseList}
                    selection={filter}
                    onChange={set_filter}
                    visible={filter_view}
                    close={() => set_filter_view(false)}
                />
            </AdminListLayout>
        </div>
    );
}
