import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Space, Descriptions, Typography, Dropdown, InputNumber, Upload, Modal, notification, message } from 'antd';
import { UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import API_SERVICE from '../../../services/api-service';

export default function PROCESSING_ORDER({ details }: any) {
  const [file_id,set_file_id] = useState(false)
  const [loading,set_loading] = React.useState(false)
  const [amount,set_amount] = React.useState(0)


  async function handle_upload(file,type){
    set_loading(true)
    try {
      const data = await API_SERVICE.upload_file(file,type)
      set_file_id(data.data.data.id)
    }
    catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
    set_loading(false)
  }

  async function download_invoice(){
    try {
      const data = await API_SERVICE.download_invoice(details.id)
      window.open(data.data.data.url, '_blank').focus();
    }
    catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
    }
  }

  async function payment_status_update(){
    try {
      if (!file_id)
        return message.info("Payment details required")
      set_loading(true)
      const data = await API_SERVICE.payment_status_update(details.id,{
        "fileId" : file_id,
        "amount": amount
      })
      window.location.assign('/orders')
    }
    catch (e) {
      notification.error({ message: API_SERVICE.handleErrors(e) });
      set_loading(false)
    }
  }

  return (
    <div>
      <div><b>Status</b> : Payment Submitted and Confirmed. Order signed-off for Shipping</div>

      <Button
        type="primary"
        style={{
          marginTop: 16,
          marginBottom: 16,
        }}
        icon={<DownloadOutlined />}
        onClick={()=>{
          download_invoice()
        }}>Performa Invoice</Button>

      <Button
        type="primary"
        style={{
          margin: 16
        }}
        icon={<DownloadOutlined />}
        onClick={()=>{
          const signed_invoice = details.performaInvoice
          if (signed_invoice)
            window.open(signed_invoice.url, '_blank').focus();
        }}>Signed Performa Invoice</Button>

      <br/>
      {/*
      <b>Payment Details</b> :
      <div style={{
        marginTop: 8,
        width: 205,
        }} >

        <Upload
          accept=".png,.jpg,.jpeg,.pdf"
          maxCount={1}
          beforeUpload={file => {
            handle_upload(file,'DOCUMENT')
            return false;
           }}
          onRemove={()=>set_file_id(false)}
          className="upload-list-inline">
          {
            (!file_id && !loading)?
            <Button icon={<UploadOutlined />}>Upload Payment Details</Button>
            :null
          }
        </Upload>

        <div style={{
            marginTop: 16,
            display:'flex'
          }} >
          <div style={styles.label}>Amt Paid</div>
          <InputNumber
            onChange={set_amount}
            placeholder="Enter in USD"
            />
        </div>
      </div>
      <Button
        type="primary"
        style={{
          marginTop: 16,
          marginBottom: 12,
        }}
        disabled={loading}
        onClick={()=>{
          payment_status_update()
        }}>Mark as Paid</Button>
      */}
      <div onClick={()=>{
        const orderPaymentFile = details.orderPaymentFile
        if (orderPaymentFile && orderPaymentFile.file)
          window.open(orderPaymentFile.file.url, '_blank').focus();
        }}>
        <Typography.Link>Download Payment Docs</Typography.Link>
      </div>
    </div>
  )
}

const styles = {
  label: {
    width: 120,
    paddingTop: 5,
    textAlign: 'center',
    backgroundColor: '#F9F9F9',
    border: '1px solid #C7C7C7'
  }
}
