import React from 'react';
import './index.scss';
import { Button, Typography } from 'antd';
import { FundProjectionScreenOutlined } from '@ant-design/icons';
import { Link } from '@material-ui/core';

const { Title } = Typography;
export default function Training(props: any) {

    React.useEffect(() => {
    }, []);

    return (
        <div className='centered' style={{ marginTop: 64 }} >
            <Title style={{ margin: 16 }} level={3}><FundProjectionScreenOutlined /> Training </Title>

            <Link href={'http://training.dhoneapp.com/'} target={"_blank"}>
                <Button htmlType={'submit'} type={'primary'} style={{ marginTop: '20px' }}>
                    Launch the Training Console
                </Button>
            </Link>
        </div>

    );
}
