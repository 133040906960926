import { Button, Pagination, Typography } from 'antd';
import { globals } from 'client/src/App';
import API_SERVICE from 'client/src/services/api-service';
import { get_user } from 'client/src/services/local-storage';
import React, { useState } from 'react';
import './index.scss';

import { useHistory } from 'react-router-dom';

const { Text, Title } = Typography;
export default function Notifications(props: any) {
    const history = useHistory();
    const [notifications, setNotifications] = React.useState([]);
    const [pagination, setPagination] = useState({ page: 1, perpage: '10' });
    const [total, setTotal] = useState(10);

    async function getNotifications() {
        try {
            const params = { ...pagination };
            const {
                data: { data },
            } = await API_SERVICE.get_notifications(params);
            setNotifications(data.items);
            setTotal(data.total);
        } catch (e) {
            console.log(e);
        }
    }

    async function onNotificationOpened(item) {
        await API_SERVICE.open_notification(item.id);
        globals.refresh();
        try {
            if (item.sourceModel === 'User') {
                history.push('/admin/users');
            } else if (item.sourceModel === 'Order') {
                const user = get_user();
                if (user.user && user.user.role.name === 'ADMIN') {
                    history.push({
                        pathname: '/admin/orders/' + item.data.id,
                        state: {
                            order: item.data,
                            tab: item.data.rating ? '4' : '3',
                        },
                    });
                } else {
                    history.push({
                        pathname: '/orders/' + item.data.id,
                        state: {
                            order: item.data,
                            tab: item.data.rating ? '4' : '3',
                        },
                    });
                }
            } else if (item.sourceModel === 'Product') {
                history.push('/home/new-arrival');
            }
        } catch (e) {
            console.log(e);
        }
    }

    React.useEffect(() => {
        getNotifications();
    }, [pagination]);

    return (
        <div>
            <div className="centered">
                <Title style={{ margin: 16 }} level={3}>
                    Notifications
                </Title>
                {notifications.map((notification: any, i: number) => (
                    <Button
                        size="large"
                        key={i}
                        className="notification-item"
                        onClick={() => onNotificationOpened(notification)}
                        style={{
                            backgroundColor: notification.isRead
                                ? '#F2F2F2'
                                : '#FFFFFF',
                        }}
                    >
                        <Text>{notification.title}</Text>
                    </Button>
                ))}
                <Pagination
                    onChange={(page, size) =>
                        setPagination({
                            page: page,
                            perpage: pagination.perpage,
                        })
                    }
                    total={total}
                    showSizeChanger={false}
                    defaultCurrent={1}
                    style={{ margin: 16 }}
                />
            </div>
        </div>
    );
}
