import React, { CSSProperties, useState, useEffect } from 'react';
// import './index.scss';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import {
    Carousel,
    Space,
    Typography,
    Modal,
    Collapse,
    List,
    Checkbox,
    Tree,
    notification,
} from 'antd';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import { CloseOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from 'services/api-service';

const { Text, Title } = Typography;
const { Panel } = Collapse;

export default function DocumentFilter(props: any) {
    const history = useHistory();
    const { t } = useTranslation();

    const [documentType, setDocumentType] = useState([]);
    const [franchiseList, setFranchiseList] = useState([]);

    const [status, set_status] = useState(true);

    const selection = { ...props.selection };
    async function push_category(id) {
        const index = selection.documentTypeIds.indexOf(id);
        if (index === -1) selection.documentTypeIds.push(id);
        else selection.documentTypeIds.splice(index, 1);
    }
    async function push_franchise(id) {
        const index = selection.franchiseListIds.indexOf(id);
        if (index === -1) selection.franchiseListIds.push(id);
        else selection.franchiseListIds.splice(index, 1);
    }

    async function push_status(id, checked) {
        set_status(id);
    }

    function onclose() {
        props.onChange(selection);
        props.close();
    }

    React.useEffect(() => {
        const d = props.documentType.map((c) => {
            return {
                ...c,
                title: c.name,
                key: c.id,
            };
        });
        setDocumentType(JSON.parse(JSON.stringify(d)));
    }, [props.documentType]);

    React.useEffect(() => {
        console.log('pppfra', props.franchiseList);
        const d = props.franchiseList.map((c: any) => {
            console.log(c?.user?.id);
            return {
                ...c,
                title: c?.user?.name,
                key: c?.user?.id,
            };
        });
        setFranchiseList(JSON.parse(JSON.stringify(d)));
    }, [props.franchiseList]);

    React.useEffect(() => {
        selection.isActive = String(status);
    }, [status]);

    return (
        <Modal
            className="selector"
            zIndex={1200}
            width={400}
            closable={false}
            visible={props.visible}
            footer={null}
        >
            <div>
                <div className="series">
                    <div className="title-container">
                        <div className="title-desc">Document Filters</div>
                    </div>
                    <CloseOutlined onClick={onclose} />
                </div>
                <div className="collapse-container">
                    <Collapse>
                        <Panel header="Document Type" key="1">
                            <Tree
                                checkable
                                onCheck={async (e: any, v: any) => {
                                    const { id, checked } = v.node;
                                    push_category(id);
                                }}
                                onSelect={(e) => console.log(e)}
                                treeData={documentType}
                            />
                        </Panel>

                        <Panel header="Franchise Name" key="2">
                            <Tree
                                checkable
                                onCheck={async (e: any, v: any) => {
                                    const { id, checked, user } = v.node;
                                    push_franchise(user.id);
                                }}
                                onSelect={(e) => console.log(e)}
                                treeData={franchiseList}
                            />
                        </Panel>
                    </Collapse>
                </div>
            </div>
        </Modal>
    );
}
