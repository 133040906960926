/* eslint-disable jsx-a11y/alt-text */
import {
    BrightnessHighOutlined,
    DirectionsBoatOutlined,
    ViewQuiltOutlined,
} from '@material-ui/icons';
import {
    Breadcrumb,
    Button,
    Card,
    Col,
    Descriptions,
    Divider,
    Menu,
    Row,
    Tabs,
    Typography,
    message,
    notification,
} from 'antd';
import API_SERVICE from 'client/src/services/api-service';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import dimensions from 'shared/assets/dimensions.png';
import MiniCart, { mini_cart } from '../MiniCart/MiniCart';
import QuantitySelector from './QuantitySelector';
import './index.scss';

import { get_port } from 'client/src/services/local-storage';

import { globals } from 'client/src/App';

const { SubMenu } = Menu;
const { TabPane } = Tabs;
const { Text, Title, Link } = Typography;
export default function ProductDetailsPage(props: any) {
    console.log('props received', props);

    const history = useHistory();
    const { t } = useTranslation();
    const productId = props.match.params.productId;
    const [product, set_product] = useState(false);
    const [image_index, set_image_index] = useState(0);
    const port = get_port();
    const [port_quantity, set_port_quantity] = useState(null);
    let boxQuantity: any;

    async function get_port_quantity() {
        try {
            const resp = await API_SERVICE.port_quantity(productId, port.id);
            if (resp.data && resp.data.data)
                set_port_quantity(resp.data.data['product-quantity']);
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    async function get_product() {
        try {
            const params = { id: productId };
            if (port && port.id) params.portId = port.id;
            const resp = await API_SERVICE.get_products(params);
            //const items = resp.data.data.items;
            const data = resp.data.data;
            console.log('product details', data.boxQuantity);

            if (data.color) data.color = await format_color(data.color);
            data.productImages.map((image, index) => {
                if (image.id === data.thumbnailId) set_image_index(index);
            });
            set_product(data);
            boxQuantity = product.boxQuantity;
            //console.log(data)
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    async function format_color(color) {
        let color_name = '';
        if (color.indexOf('#') === 0) {
            const hexcode = color.toLowerCase();
            try {
                const data = await API_SERVICE.get_color({
                    hex: hexcode.slice(1),
                });
                color_name = data.data.data.name || color;
                return color_name;
            } catch {
                return color;
            }
        } else return color;
    }

    async function add_to_wishlist(product_id: number) {
        try {
            const resp = await API_SERVICE.add_to_wishlist(product_id);
            message.success('Added to wishlist');
            get_product();
            globals.refresh();
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    async function remove_from_wishlist(product_id: number) {
        try {
            const resp = await API_SERVICE.remove_from_wishlist(product_id);
            message.success('Removed from wishlist');
            get_product();
            globals.refresh();
        } catch (e) {
            console.log(e);
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    useEffect(() => {
        get_product();
        get_port_quantity();
    }, []);

    if (!product) return <div />;
    return (
        <div className={'details-page-wrapper'}>
            <div className="breadcrumbs-wrapper">
                <Row>
                    <Breadcrumb separator=">">
                        <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                        {product && product.parent && (
                            <>
                                <Breadcrumb.Item
                                    href={`/home/${product.parent.name}/${product.parent.id}`}
                                >
                                    {product.parent.name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={`/home/${product.parent.name}/${product.parent.id}/${product.category.name}/${product.category.id}`}
                                >
                                    {product.category.name}
                                </Breadcrumb.Item>
                            </>
                        )}
                        <Breadcrumb.Item>{product.name}</Breadcrumb.Item>
                    </Breadcrumb>
                </Row>
            </div>
            <div className={'layout-wrapper'}>
                <Row gutter={[20, 20]}>
                    <div className="title">
                        <Title level={3}>{product.name}</Title>
                    </div>
                </Row>
                <Row gutter={[20, 20]}>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <div className="image-layout-section">
                            <div className="thumbnails-wrapper">
                                {product.productImages &&
                                    product.productImages.map((i, index) => {
                                        if (!product.productImages[index])
                                            return (
                                                <div
                                                    key={index}
                                                    className="thumbnail"
                                                />
                                            );
                                        else
                                            return (
                                                <img
                                                    key={index}
                                                    src={
                                                        product.productImages[
                                                            index
                                                        ].url
                                                    }
                                                    className="thumbnail"
                                                    onClick={() =>
                                                        set_image_index(index)
                                                    }
                                                    style={
                                                        index === image_index
                                                            ? {
                                                                  border: '2px solid #C7232A',
                                                              }
                                                            : null
                                                    }
                                                />
                                            );
                                    })}
                            </div>
                            <div className="large-image">
                                <img
                                    src={
                                        product.productImages &&
                                        product.productImages[image_index] &&
                                        product.productImages[image_index].url
                                    }
                                    className="large-image"
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} md={24} sm={24} xs={24}>
                        <div className="details-section">
                            <Row gutter={10}>
                                <Col flex={'auto'} className={'sections'}>
                                    <Title level={5}>In Stock </Title>
                                    <Text type="danger">
                                        <strong>{product.dubaiQuantity}</strong>
                                    </Text>{' '}
                                    <Text>QTY</Text>
                                    <Title level={3} style={{ margin: 0 }}>
                                        ${product.dubaiPrice}/Unit
                                    </Title>
                                    <div>
                                        <Text>
                                            {' '}
                                            <DirectionsBoatOutlined />
                                            &nbsp;Dubai-HQ
                                        </Text>
                                    </div>
                                    <div className="row">
                                        <img
                                            src={dimensions}
                                            style={styles.icon}
                                        />
                                        <Text>
                                            {' '}
                                            {product.cbnValue} CBM / Unit
                                        </Text>
                                    </div>
                                    <QuantitySelector
                                        disabled={!product.dubaiQuantity}
                                        cartId="ON_STOCK"
                                        productId={product.id}
                                        quantity={
                                            product.productCount &&
                                            product.productCount.onStock
                                        }
                                        bulkquantity={product.dubaiQuantity}
                                        boxQuantity={product.boxQuantity}
                                        min={1}
                                        onChange={() => {
                                            mini_cart.refresh();
                                        }}
                                    />
                                </Col>
                                {/*}  <Col flex={'auto'} className={'sections'}>
                                    <Title level={5}>Available at Source</Title>
                                    <Text type="danger"><strong>{port_quantity}</strong></Text> <Text>QTY</Text>
                                    <Title level={3} style={{ margin: 0 }}>${product.portPrice}/Unit</Title>
                                    <div>
                                        <Text> <DirectionsBoatOutlined/></Text>&nbsp;
                                        <Link href="#">
                                            {port.name}
                                        </Link>
                                    </div>
                                    <div className="row">
                                        <img src={dimensions} style={styles.icon}/>
                                        <Text> {product.cbnValue} CBM / Unit</Text>
                                    </div>
                                    <QuantitySelector
                                      cartId="FROM_SOURCE"
                                      productId={product.id}
                                      quantity={product.productCount && product.productCount.fromSource}
                                      min={1}
                                      onChange={()=>{
                                        mini_cart.refresh()
                                      }} />
                                    </Col>*/}
                                {/* <Col flex={'auto'} className={'sections'}>
                                    <Title level={5}>Customizable</Title>
                                    <Text type="danger"><strong>{product.minimumOrderQuantity}</strong></Text> <Text>MOQ</Text>
                                    <Title level={3} style={{ margin: 0 }}>${product.customPrice}/Unit</Title>
                                    <div>
                                        <Text> <DirectionsBoatOutlined/></Text>&nbsp;
                                        <Link href="#">
                                            {port.name}
                                        </Link>
                                    </div>
                                    <div className="row">
                                        <img src={dimensions} style={styles.icon}/>
                                        <Text> {product.cbnValue} CBM / Unit</Text>
                                    </div>
                                    <QuantitySelector
                                      cartId="PRE_ORDER"
                                      productId={product.id}
                                      quantity={product.productCount && product.productCount.preOrder}
                                      min={product.minimumOrderQuantity}
                                      onChange={()=>{
                                        mini_cart.refresh()
                                      }} />
                                    </Col>*/}
                            </Row>
                            <Divider />
                            <Row gutter={10}>
                                <Col flex={'auto'}>
                                    <div className={'row-with-icon'}>
                                        <Text>
                                            <strong>SKU:</strong>{' '}
                                            {product.skuId}
                                        </Text>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {!product.isWishlisted ? (
                                            <Button
                                                style={{ color: '#C7232A' }}
                                                onClick={() =>
                                                    add_to_wishlist(product.id)
                                                }
                                            >
                                                Add to wishlist
                                            </Button>
                                        ) : (
                                            <Button
                                                style={{ color: '#C7232A' }}
                                                onClick={() =>
                                                    remove_from_wishlist(
                                                        product.id,
                                                    )
                                                }
                                            >
                                                Remove from wishlist
                                            </Button>
                                        )}
                                    </div>
                                    <br />
                                    <div className={'row-with-icon'}>
                                        <BrightnessHighOutlined />{' '}
                                        <Text>
                                            <strong>Warranty:</strong>{' '}
                                            {product.warranty}
                                        </Text>
                                    </div>
                                    <div className={'row-with-icon'}>
                                        <ViewQuiltOutlined />{' '}
                                        <Text>
                                            <strong>Assembly Required:</strong>{' '}
                                            Yes
                                        </Text>
                                    </div>
                                    <div>
                                        <Text>
                                            <strong>Key Features:</strong>
                                        </Text>
                                        <div
                                            style={{ paddingTop: '8px' }}
                                            dangerouslySetInnerHTML={{
                                                __html: product.description,
                                            }}
                                        />
                                        {/*<div style={{ paddingTop: '8px' }}>
                                            {product.description}
                                        </div>*/}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[20, 20]}>
                    <Col flex={'auto'}>
                        <div className="table-description-section">
                            <Tabs defaultActiveKey="1" size={'large'}>
                                <TabPane tab="Details" key="1">
                                    <div className="description-container">
                                        <Descriptions
                                            layout="horizontal"
                                            size="small"
                                            bordered
                                        >
                                            <Descriptions.Item
                                                label="Installation Type"
                                                span={3}
                                            >
                                                {product.installationType}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Item Category"
                                                span={3}
                                            >
                                                {product.category.name}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Color"
                                                span={3}
                                            >
                                                {product.color}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Dimensions"
                                                span={3}
                                            >
                                                {product.dimension}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Gross Weight (in KG)"
                                                span={3}
                                            >
                                                {product.weight}
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label="Set Includes"
                                                span={3}
                                            >
                                                {product.setInclude}
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </div>
                                </TabPane>
                                <TabPane tab="Description" key="2">
                                    <Card>
                                        <div className="description-container">
                                            {product.shortDescription}
                                        </div>
                                    </Card>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </div>

            <MiniCart />
        </div>
    );
}

const styles = {
    icon: {
        height: '14px',
        width: '14px',
    },
};
