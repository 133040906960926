import React, { useEffect, useState } from 'react';
import {
    Button,
    notification,
    Pagination,
    Space,
    Table,
    Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import {
    DownloadOutlined,
    EyeOutlined,
    PlusCircleOutlined,
    ShopOutlined,
} from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import UploadDocumentsFranchise from './UploadDocumentsFranchise';
import moment from 'moment';
import FilterFranchise from './FilterFranchise';
import { handleBlob } from 'shared/services/Utility';
import Search from 'antd/es/input/Search';

const { Text } = Typography;

export default function KnowledgeBaseFranchise(props: any) {
    const history = useHistory();
    const [search, setSearch] = useState('');
    const [filter_view, set_filter_view] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({
        page: 1,
        perpage: '10',
    } as any);
    const [total, setTotal] = useState(10);
    const [data, setData] = useState();
    const [documentType, setDocumentType] = useState([]);
    const [filter, set_filter] = useState({
        documentTypeIds: [],
        isActive: true,
    });

    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            render: (a: any, v: any, index: number) => (
                <Text>
                    {(pagination.page - 1) * pagination.perpage + index + 1}
                </Text>
            ),
        },
        {
            title: 'Document Title',
            key: 'title',
            dataIndex: 'title',
        },
        {
            title: 'Document Type',
            key: 'documentType',
            dataIndex: 'documentType',
            render: (a: any) => a?.name,
        },
        {
            title: 'Uploaded At',
            key: 'updatedAt',
            render: (d: any) => {
                return moment(d?.updatedAt).format('DD/MM/YYYY');
            },
        },
        {
            title: 'Actions',
            key: 'action',
            render: (a: any, value: any) => {
                return (
                    <Space>
                        <Button
                            type="default"
                            shape="circle"
                            onClick={() => window.open(a.file.url, '_blank')}
                            icon={<EyeOutlined />}
                        />
                        <Button
                            type="default"
                            shape="circle"
                            onClick={() => {
                                forceDownloadFile(a.fileId, a?.file?.name);
                            }}
                            icon={<DownloadOutlined />}
                        />
                    </Space>
                );
            },
        },
    ];

    const forceDownloadFile = async (fileId: number, fileName: string) => {
        try {
            const params = {
                fileId,
            };
            const { data } = await API_SERVICE.forceDownloadFile(params);
            handleBlob(data, fileName);
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
                placement: 'bottomRight',
            });
        }
    };

    const getDocumentTypes = async () => {
        try {
            const params = {
                type: 'admin',
            };
            const data = await API_SERVICE.get_documents_type(params);
            setDocumentType(data.data.data);
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
                placement: 'bottomRight',
            });
        }
    };

    const refresh = async () => {
        setFetching(true);
        const params = {
            page: pagination.page,
            perpage: pagination.perpage,
        };
        //@ts-ignore
        if (search) params['q'] = search;
        if (filter.documentTypeIds) {
            //@ts-ignore
            params['documentTypeIds'] = filter.documentTypeIds;
        }
        try {
            const {
                data: { data },
            } = await API_SERVICE.get_view_documents_uploaded_by_admin(params);
            setData(data.items);
            setTotal(data.total);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        // refresh();
        getDocumentTypes();
    }, []);

    useEffect(() => {
        refresh();
    }, [props.location, pagination, filter, search]);

    return (
        <div className={'franchise-wrapper page-wrapper'}>
            <AdminListLayout
                titleIcon={<ShopOutlined />}
                title={'View Documents(Uploaded By Admin)'}
                titleAction={
                    <Button
                        type={'primary'}
                        onClick={() => history.push('/upload-documents')}
                        icon={<PlusCircleOutlined />}
                    >
                        Go To Uploads
                    </Button>
                }
                titleSearch={
                    <div className="search-bar">
                        <Search
                            placeholder="Search any Document here"
                            size="large"
                            onSearch={(e) => {
                                setPagination({ ...pagination, page: 1 });
                                setSearch(e);
                            }}
                        />
                    </div>
                }
                subheader={[
                    <Button
                        key="2"
                        type={'primary'}
                        onClick={() => set_filter_view(true)}
                        icon={<PlusCircleOutlined />}
                    >
                        Filter
                    </Button>,
                ]}
            >
                {addNew && (
                    <UploadDocumentsFranchise
                        onClose={(e: boolean) => {
                            if (e) {
                                // refresh();
                            }
                            setAddNew(false);
                        }}
                    />
                )}

                <Table
                    loading={fetching}
                    scroll={{ x: true }}
                    dataSource={data}
                    pagination={false}
                    columns={columns}
                />
                <div style={{ float: 'right', padding: '20px' }}>
                    <Pagination
                        key="4"
                        showSizeChanger
                        onShowSizeChange={(current, pageSize) => {
                            setPagination({
                                page: current,
                                perpage: pageSize,
                            });
                        }}
                        defaultCurrent={1}
                        total={total}
                        onChange={(page, size) =>
                            setPagination({ page: page, perpage: size })
                        }
                    />
                </div>
                <FilterFranchise
                    documentType={documentType}
                    selection={filter}
                    onChange={set_filter}
                    visible={filter_view}
                    close={() => set_filter_view(false)}
                />
            </AdminListLayout>
        </div>
    );
}
