import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import {
    Button,
    Col,
    Menu,
    notification,
    Row,
    Space,
    Checkbox,
    Tag,
    Typography,
    Form,
    Input,
    Select,
    Collapse,
    Table,
    Divider, Tree, List, Switch,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { SafetyCertificateOutlined, LockOutlined, PlusCircleOutlined } from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';
import Paragraph from 'antd/es/typography/Paragraph';

import AccessControl from 'client/src/services/AccessControl';

const { SubMenu } = Menu;
const { Panel } = Collapse;
const { Text, Title, Link } = Typography;
export default function RolesPage(props: any) {
    const [selectedRoleIndex, setSelectedRoleIndex] = useState(-1);
    const [roles, setRoles] = useState([] as any[]);
    const [all_permissions,set_all_permissions] = useState([])
    const [role_permissions,set_role_permissions] = useState([])
    const [checkedKeys, setCheckedKeys] = useState([] as any[]);
    const [fetching, setFetching] = useState(false);
    const refresh = async () => {
        setFetching(true);
        try {
            const { data: { data: { items } } } = await API_SERVICE.roles({ perpage: 1000 });
            setRoles(items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };
    const get_all_permissions = async () => {
        try {
            const data = await API_SERVICE.permissions();
            set_all_permissions(data.data.data[0])
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    async function toggle_access(id) {
      const roleId = roles[selectedRoleIndex].id
      let arr = role_permissions;
      const index = role_permissions.indexOf(id)
      if (index>-1)
        arr.splice(index,1)
      else arr.push(id)
      await API_SERVICE.update_permissions(roleId,arr);
      refresh();
    }
    function access_control(obj) {
      if (obj)
        return <Checkbox onChange={()=>toggle_access(obj.id)} checked={role_permissions.includes(obj.id)} />
      else return <Checkbox disabled />
    }
    const permission_columns = {
      name: 'Permission rights',
      Show: 'Enable/Disable',
    }
    function create_column(id) {
      const obj = {
        key: id,
        title: permission_columns[id] || id,
      }
      if (id=='name')
        obj.render = (a: any) => a[id].capitalize()
      else {
        obj.align = 'center'
        obj.render = (a: any) => access_control(a[id])
      }
      return obj
    }


    useEffect(() => {
      if (selectedRoleIndex>-1)
        set_role_permissions(roles[selectedRoleIndex].permissionIds || [])
        //console.log(roles[selectedRoleIndex].permissions)
    },[selectedRoleIndex])
    useEffect(() => {
        refresh();
        get_all_permissions();
    }, []);


    return (
        <div className={'categories-wrapper page-wrapper'}>
            <Row className={'main-wrapper'}>
                <Col span={12} className={'main-section'}>
                    <div className='header-section'>
                        <Row gutter={20} align={'middle'}>
                            <Col>
                                <Title level={3}> <LockOutlined /></Title>
                            </Col>
                            <Col>
                                <Title level={4}> All User Roles</Title>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={12} className={'main-section'}>
                    <div className='header-section'>
                        <Row gutter={20} align={'middle'}>
                            {
                                roles[selectedRoleIndex] && <Col>
                                    <Space>
                                        <SafetyCertificateOutlined />
                                        <Text>Role Permissions -{roles[selectedRoleIndex].name}</Text>
                                    </Space>
                                </Col>
                            }
                        </Row>
                    </div>

                </Col>
            </Row>
            <Row className={'main-wrapper'}>
                <Col span={12} className={'main-section'}>
                    <div className='sub-section'>
                        <AccessControl id={29}>
                          <RolesForm refresh={refresh} />
                        </AccessControl>
                        <Divider />
                        <AccessControl id={28}>
                          <RolesList setActiveIndex={setSelectedRoleIndex} refresh={refresh} roles={roles} />
                        </AccessControl>
                    </div>
                </Col>
                <Col span={12} className={'main-section'}>
                    <div className='sub-section'>
                        {
                            roles[selectedRoleIndex] && <Paragraph>
                                {
                                    roles[selectedRoleIndex].description
                                }
                            </Paragraph>
                        }
                    </div>
                    <AccessControl id={32}>
                    <div className='sub-section'>
                        {(selectedRoleIndex>-1 && all_permissions) && <div>
                              <Collapse accordion ghost>
                                {Object.keys(all_permissions).map((item,index)=>  {

                                  const sub_modules = []
                                  const operations = []
                                  const table_columns = [create_column('name')]
                                  Object.keys(all_permissions[item]).map((sub_module,module_index)=>{
                                    const obj = { name: sub_module }
                                    all_permissions[item][sub_module].map((permission,permission_index)=>{
                                      obj[permission.name] = permission

                                      if (!operations.includes(permission.name)){
                                        operations.push(permission.name)
                                        table_columns.push(create_column(permission.name))
                                      }

                                    })
                                    sub_modules.push(obj)
                                  })

                                  return (
                                    <Panel header={item.title()} key={index}>
                                      <Table pagination={false} dataSource={sub_modules} columns={table_columns} />
                                    </Panel>
                                  )}
                                )}
                              </Collapse>
                            </div>
                        }
                    </div>
                    </AccessControl>
                </Col>
            </Row>
        </div>
    );
}

const RolesForm = ({ refresh }: any) => {
    const [saving, setSaving] = useState(false);
    const [form] = Form.useForm();
    return <Form
        size='large'
        fields={[]}
        form={form}
        onFinish={(value) => {
            const reqObj: any = value;
            setSaving(true);
            API_SERVICE.roleCreate({ ...reqObj, isActive: true }).then((d) => {
                refresh();
                form.resetFields(['name', 'description']);
            }).catch((e) => {
                notification.error({ message: API_SERVICE.handleErrors(e) });
            }).finally(() => {
                setSaving(false);
            });
        }}
        layout='vertical'
        requiredMark={true}>
        <Row gutter={20} align={'middle'}>
            <Col flex={'auto'}>
                <Form.Item
                    label={'Role name'}
                    name={'name'}
                    rules={[
                        { required: true, message: 'Name required' },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col>
                <Button loading={saving} htmlType={'submit'} type={'primary'} style={{ marginTop: '20px' }}
                        icon={<PlusCircleOutlined />}>
                    Add
                </Button>
            </Col>
            <Col span={24}>
                <Form.Item
                    label={'Description'}
                    name={'description'}
                    rules={[
                        { required: true, message: 'Description required' },
                    ]}
                >
                    <TextArea rows={2} />
                </Form.Item>
            </Col>
        </Row>
    </Form>;
};

const RolesList = ({ refresh, roles, setActiveIndex }: any) => {
    const [saving, setSaving] = useState(false);
    const updateRole = async (role: any) => {
        const reqObj: any = role;
        setSaving(true);
        API_SERVICE.roleUpdate(role.id, { ...reqObj, isActive: role.isActive }).then((d) => {
            refresh();
        }).catch((e) => {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }).finally(() => {
            setSaving(false);
        });
    };
    return <List
        itemLayout='horizontal'
        dataSource={roles}
        renderItem={(item: any, index) => (
            <List.Item
                onClick={() => setActiveIndex(index)}
                actions={[
                    <AccessControl id={30} closed={item.isActive? <Tag color='#DC202B'>Active</Tag> : <Tag color='lightgrey'>Inactive</Tag>}>
                    <Switch checkedChildren={'Active'} unCheckedChildren={'Inactive'} onChange={(e) => {
                        updateRole({ ...item, isActive: e });
                    }}
                            checked={item.isActive} />
                    </AccessControl>,
                ]}
            >
                {
                    item.name
                }
            </List.Item>)}>
    </List>;
};
