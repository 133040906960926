import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import { Button, Menu, notification, Space, Table, Tag, Typography, Pagination } from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { ShopOutlined, EditOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import FranchiseCreateOrEditPage from './FranchiseCreateOrEditPage';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function FranchisePage(props: any) {

    const history = useHistory();
    const [franchises, setFranchises] = useState([] as any[]);
    const [search, setSearch] = useState('' as string);
    const [addNew, setAddNew] = useState(false);
    const [editFranchise, setEditFranchise] = useState(null as any);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({ page: 1, perpage: '10' } as any);
    const [total, setTotal] = useState(10);
    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            render: (a: any, v: any, index: number) => <Text>{(pagination.page-1)*(pagination.perpage)+ index + 1}</Text>,
        },
        {
            title: 'Code',
            key: 'code',
            render: (a: any) => <Text>{a.code || 'NA'}</Text>,
        },
        {
            title: 'Franchise Name',
            key: 'name',
            render: (a: any) => <div className="linear">
                <img src={a.file? a.file.url:''} className="flag-thumbnail" />
                <Text>{a.name}</Text>
              </div>,
        },
        {
            title: 'Address',
            key: 'address',
            dataIndex: 'address',
            width: 200,
        },
        {
            title: 'Owner Details',
            key: 'franchiseOwner',
            dataIndex: 'franchiseOwner',
            render: (a: any) => {
                return <Space size={0} direction={'vertical'}>
                    <Text>{a.name}</Text>
                    <Text>{a.phone}</Text>
                    <Text>{a.email}</Text>
                </Space>;
            },
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'isActive',
            render: (a: boolean, value: any) => <div onClick={async (e) => {
                try {
                    setFetching(true);
                    await API_SERVICE.franchiseUpdate(value.id, {
                        ...value,
                        isActive: !value.isActive,
                    });
                    refresh();
                } catch (e) {
                    notification.error({ message: API_SERVICE.handleErrors(e) });
                } finally {
                    setFetching(false);
                }
            }
            }>
                {a ? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>}
            </div>,
        },
        {
            title: 'Actions',
            key: 'action',
            render: (a: any, value: any) => {
                return <Space>
                    <Button type='default' shape='circle' icon={<EditOutlined />}
                            onClick={() => {
                                setEditFranchise(value);
                            }} />
                </Space>;
            },
        },
    ];
    const refresh = async () => {
        setFetching(true);
        const params = { ...pagination };
        if (search) {
            params['q'] = search;
        }
        try {
            const { data: { data } } = await API_SERVICE.franchises(params);
            setFranchises(data.items);
            setTotal(data.total);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };
    useEffect(() => {
        refresh();
    }, [props.location, pagination, search]);
    return (
        <div className={'franchise-wrapper page-wrapper'}>
            <AdminListLayout titleIcon={<ShopOutlined />} title={'All Franchises'}
                             titleSearch={<div className='search-bar'>
                                 <Search
                                     placeholder='Search for Franchises here'
                                     size='large'
                                     onSearch={
                                         (e) => {
                                             setSearch(e);
                                         }
                                     }
                                 />
                             </div>}
                             titleAction={
                                 <Button type={'primary'} onClick={() => setAddNew(true)} icon={<PlusCircleOutlined />}>
                                     Add New
                                 </Button>
                             }
            >
                {
                    addNew && <FranchiseCreateOrEditPage onClose={
                        (e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }
                    } />
                }
                {
                    editFranchise && <FranchiseCreateOrEditPage
                        details={editFranchise}
                        onClose={
                            (e: boolean) => {
                                if (e) {
                                    refresh();
                                }
                                setEditFranchise(null);
                            }
                        } />
                }
                <Table loading={fetching} scroll={{ x: true }} pagination={false} dataSource={franchises} columns={columns} />
                <div style={{float:'right'}}>
                  <Pagination showSizeChanger defaultCurrent={1} total={total} onChange={(page,size)=>setPagination({ page:page, perpage: size })} />
                </div>
            </AdminListLayout>
        </div>
    );
}
