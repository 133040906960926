import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Modal, notification, Row, Select, Space, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { ShopOutlined } from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import FileUpload from 'shared/components/FileUpload';

const { Text } = Typography;

export default function UploadDocumentsFranchise({ onClose }: any) {
    const [form] = Form.useForm();
    const [documentType, setDocumentType] = useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState();
    const [documentId, setDocumentId] = useState();
    const [document, setDocument] = useState();
    const history = useHistory();
    const documentUpdate = ({ id, url }) => {
        setDocumentId(id);
        setDocument(url);
    };

    const getDocumentTypes = async () => {
        try {
            const params = {
                type: 'franchise',
            };
            const data = await API_SERVICE.get_documents_type(params);
            setDocumentType(data.data.data);
        } catch (error) {
            notification.error({
                message: API_SERVICE.handleErrors(error),
                placement: 'bottomRight',
            });
        }
    };
    const handleChange = (value: any) => {
        setSelectedDocumentType(value);
    };

    useEffect(() => {
        getDocumentTypes();
    }, []);

    return (
        <Modal title={
            (<Space>
                <ShopOutlined />
                <Text>{'Upload Document'}</Text>
            </Space>)
        }
               width={500}
               visible={true}
               onOk={
                   () => {
                       form.submit();
                   }
               }
               onCancel={() => onClose()}>

            <Form
                size='large'
                form={form}
                fields={[]}
                onFinish={async (data: any) => {
                    try {
                        const params = {
                            documentTypeId: selectedDocumentType,
                            fileId: documentId,
                            title: data.title,
                        };
                        await API_SERVICE.upload_documents_franchise(params);
                        notification.success({
                            message: 'Uploaded Successfully',
                            placement: 'bottomRight',
                        });
                        onClose(true);
                    } catch (err) {
                        notification.error({
                            message: API_SERVICE.handleErrors(err),
                            placement: 'bottomRight',
                        });
                    }
                }}
                layout='vertical'
                requiredMark={true}
            >
                <Row gutter={20}>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Document Title'}
                            name={'title'}
                            rules={[
                                { required: true, message: 'Document Type required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Select Document Type'}
                            name={'type'}
                            rules={[
                                { required: true, message: 'Document Type required' },
                            ]}
                        >
                            <Select
                                onChange={handleChange}
                                options={documentType.map((item: any) => {
                                    return {
                                        value: item.id,
                                        label: item.name,
                                    };
                                })}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label='Upload File'>
                            <FileUpload
                                type={'DOCUMENT'}
                                callback={documentUpdate}
                                style={{ display: 'block' }}
                                onChange={(id: any) => {
                                    setDocumentId(id);
                                }}
                            >
                                <div className='fileUploadDesign'>Please Choose File</div>
                            </FileUpload>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}
