import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import { Button, Menu, notification, Space, Table, Tag, Typography, Modal, Pagination } from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { ShopOutlined, EditOutlined, PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import UserCreateOrEditPage from './UserCreateOrEditPage';

import AccessControl from 'client/src/services/AccessControl';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function UsersPage(props: any) {

    const history = useHistory();
    const [franchises, setFranchises] = useState([] as any[]);
    const [search, setSearch] = useState('' as string);
    const [addNew, setAddNew] = useState(false);
    const [editUser, setEditUser] = useState(null as any);
    const [fetching, setFetching] = useState(false);
    const [pagination, setPagination] = useState({ page: 1, perpage: '10' } as any);
    const [total, setTotal] = useState(10);
    const [deleteUser, setDeleteUser] = useState(-1)
    const columns = [
        {
            title: 'S.No',
            key: 'sNO',
            render: (a: any, v: any, index: number) => <Text>{(pagination.page-1)*(pagination.perpage)+ index + 1}</Text>,
        },
        {
            title: 'Name & Role',
            key: 'name',
            render: (a: any) => <Space size={0}>
                <Text>
                  {a.name || 'NA'}<br/>
                  {a.role? a.role.name : 'NA'}
                </Text>
            </Space>,
        },
        {
          title: 'Franchise',
          key: 'franchise',
          render: (a: any) => <Space size={0}>
              <Text>{a.franchise? a.franchise.name : 'NA'}</Text>
          </Space>,
        },
        {
            title: 'Contact Details',
            key: 'contactDetails',
            render: (a: any) => <Space direction={'vertical'} size={0}>
                <Text>{a.phone || 'NA'}</Text>
                <Text>{a.email || 'NA'}</Text>
            </Space>,
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'isActive',
            render: (a: boolean, value: any) =>
            <AccessControl id={36} closed={a? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>}>
            <div onClick={async (e) => {
                try {
                    setFetching(true);
                    await API_SERVICE.userUpdate(value.id, {
                        ...value,
                        isActive: !value.isActive,
                    });
                    refresh();
                } catch (e) {
                    notification.error({ message: API_SERVICE.handleErrors(e) });
                } finally {
                    setFetching(false);
                }
            }
            }>
                {a ? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>}
            </div>
            </AccessControl>,
        },
        {
            title: 'Actions',
            key: 'action',
            render: (a: any, value: any) => {
                return <Space>
                    <AccessControl id={36} closed={
                      <Button type='default' shape='circle' icon={<EditOutlined />} disabled />
                    }>
                    <Button type='default' shape='circle' icon={<EditOutlined />}
                            onClick={() => {
                                setEditUser(value);
                            }} />
                    <Button type='default' shape='circle' icon={<DeleteOutlined />}
                            onClick={() => {
                                setDeleteUser(a.id);
                            }} />
                    </AccessControl>
                </Space>;
            },
        },
    ];
    const refresh = async () => {
        setFetching(true);
        const params = { ...pagination };
        if (search) {
            params['q'] = search;
        }
        try {
            const { data: { data } } = await API_SERVICE.users(params);
            setFranchises(data.items);
            setTotal(data.total);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    const delete_user = async () => {
        try {
            await API_SERVICE.userDelete(deleteUser);
        } catch (e) {
          console.log(e)
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setDeleteUser(-1)
            refresh();
        }
    };

    useEffect(() => {
        refresh();
    }, [props.location, pagination, search]);
    return (
        <div className={'franchise-wrapper page-wrapper'}>
            <AdminListLayout
              titleIcon={<ShopOutlined />}
              title={'All Users'}
              titleSearch={
                <AccessControl id={34}>
                <div className='search-bar'>
                   <Search
                       placeholder='Search for Users here'
                       size='large'
                       onSearch={
                           (e) => {
                               setSearch(e);
                           }
                       }
                   />
                </div>
                </AccessControl>}
              titleAction={
                <AccessControl id={35}>
                 <Button type={'primary'} onClick={() => setAddNew(true)} icon={<PlusCircleOutlined />}>
                     Add New
                 </Button>
                </AccessControl>
                 }>
                {
                    addNew && <UserCreateOrEditPage onClose={
                        (e: boolean) => {
                            if (e) {
                                refresh();
                            }
                            setAddNew(false);
                        }
                    } />
                }
                {
                    editUser && <UserCreateOrEditPage
                        details={editUser}
                        onClose={
                            (e: boolean) => {
                                if (e) {
                                    refresh();
                                }
                                setEditUser(null);
                            }
                        } />
                }
                <AccessControl id={34}>
                  <Table loading={fetching} scroll={{ x: true }} pagination={false} dataSource={franchises} columns={columns} />
                  <div style={{float:'right'}}>
                    <Pagination key="4" showSizeChanger defaultCurrent={1} total={total} onChange={(page,size)=>setPagination({ page:page, perpage: size })} />
                  </div>
                </AccessControl>
            </AdminListLayout>

            <Modal
              closable={false}
              zIndex={1200}
              width={300}
              centered
              okText="Yes"
              cancelText="No"
              onOk={delete_user}
              onCancel={()=>setDeleteUser(-1)}
              visible={(deleteUser>-1)}>
              <Text>Are you sure you want to delete ?</Text>
            </Modal>

        </div>
    );
}
