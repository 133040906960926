import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Space, Descriptions, Typography, Dropdown, InputNumber, Menu, Upload, Pagination, Table, Row, Col, Tag, Checkbox, Select, Modal, notification, message } from 'antd';
import { DeleteOutlined, UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import API_SERVICE from '../../../services/api-service';

const { Text, Title, Link } = Typography;
export default function PENDING_CONFIRMATION({ details, callback, setActiveTabKey }) {
    const [shipping_charges,set_shipping_charges] = useState(details.shipmentCharge)
    const [other_charges,set_other_charges] = useState(details.otherTax)
    const [loading,set_loading] = React.useState(false)

    async function download_invoice(type){
      try {
        const data = await API_SERVICE.download_invoice(details.id, type)
        window.open(data.data.data.url, '_blank').focus();
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
    }

    async function cancel_pi(){
      try {
        await API_SERVICE.cancel_pi(details.id);
        callback();
        setActiveTabKey('2');
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
    }

    return (
      <div>
        <div><b>Status</b> : Pending Confirmation from Client</div>
       { /*<Button
          type="primary"
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
          icon={<DownloadOutlined />}
          onClick={()=>{
            download_invoice()
          }}>Proforma Invoice</Button>*/}
<Space>
        <Button
          type="primary"
          className="p-4"
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
          icon={<DownloadOutlined />}
          onClick={()=>{
            download_invoice()
          }}>Download PI PDF</Button>

        <Button
          type="primary"
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
          icon={<DownloadOutlined />}
          onClick={()=>{
            download_invoice({type:'excel'})
          }}>Download PI Excel</Button>

          <Button
          type="primary"
          style={{
            marginTop: 16,
            marginBottom: 16,
          }}
          onClick={()=>{
            cancel_pi()
          }}>Cancel PI</Button>

          </Space>
        <br/>
        <b>Uploaded by Client</b> :
        <div style={{
          marginTop: 8,
          }} >
          <Button
            type="primary"
            style={{
              marginBottom: 16,
            }}
            icon={<DownloadOutlined />}
            disabled={details.adminStatus!='PENDING_PAYMENT'}
            onClick={()=>{
              const signed_invoice = details.performaInvoice
              if (signed_invoice)
                window.open(signed_invoice.url, '_blank').focus();
            }}>Signed Performa Invoice</Button>
          </div>
      </div>
    )
}
