import { ShoppingOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import {
    Col,
    Descriptions,
    Input,
    Modal,
    Progress,
    Row,
    Tabs,
    notification,
} from 'antd';
import { useEffect, useState } from 'react';
import API_SERVICE from '../../services/api-service';
import { roundOf } from '../../services/utility';
import './index.scss';

import invoice from 'shared/assets/invoice.png';

import { useTranslation } from 'react-i18next';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import OrderAction from './OrderAction';
import OrderProducts from './OrderProducts';
import { useLocation } from 'react-router-dom';

const { TabPane } = Tabs;

export default function OrderDetails(props) {
    const { t } = useTranslation();
    const orderId = props.match.params.order_id;
    const { state } = useLocation();

    const [details, set_details] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState('1');
    // const notRated =
    //     details && details.status === 'ORDER_COMPLETE' && !details.rating;
    const [ratingFlag, setRatingFlag] = useState();
    const container = details?.orderContainer;
    const [orderRating, setRating] = useState(details?.rating || '0');
    const [orderRemark, setOrderRemark] = useState('');
    const formatter = new Intl.NumberFormat('en-US');

    async function getOrderDetailsById() {
        try {
            const {
                data: { data },
            } = await API_SERVICE.get_order_details(orderId);
            set_details(data);
        } catch (e) {
            notification.error({
                message: t(API_SERVICE.handleErrors(e)),
            });
        }
    }

    useEffect(() => {
        getOrderDetailsById();
        if (state && state.notRated) {
            setRatingFlag(true);
        }
    }, [orderId]);

    if (!details) return <div>Invalid Order ID</div>;

    return (
        <div className={'franchise-wrapper page-wrapper'}>
            <AdminListLayout
                titleIcon={<ShoppingOutlined />}
                title={'Order ID # ' + details.orderNumberNew}
                titleSearch={
                    <div
                        style={{
                            textTransform: 'capitalize',
                            paddingLeft: 16,
                        }}
                    >
                        {details.status &&
                            details.status.replace(/_/g, ' ').toLowerCase()}
                        <br />
                        <Progress
                            showInfo={false}
                            percent={details.progressClient}
                            strokeColor="#DC202B"
                            trailColor="#00000042"
                            style={{
                                width: 320,
                            }}
                        />
                    </div>
                }
            >
                <div className="container">
                    <Tabs
                        defaultActiveKey="1"
                        size={'large'}
                        activeKey={activeTabKey}
                        onChange={(key) => {
                            setActiveTabKey(key);
                        }}
                    >
                        <TabPane tab="Details" key="1">
                            <div
                                style={{
                                    display: 'flex',
                                    paddingTop: 24,
                                    paddingBottom: 24,
                                }}
                            >
                                <div
                                    style={{
                                        width: '300px',
                                        borderRight: '1px solid #C7C7C7',
                                    }}
                                >
                                    <Descriptions
                                        column={1}
                                        size="small"
                                        layout="horizontal"
                                    >
                                        <Descriptions.Item label="Placed on">
                                            {' '}
                                            {
                                                details?.createdAt?.split(
                                                    'T',
                                                )[0]
                                            }{' '}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Total CBM">
                                            {' '}
                                            {roundOf(
                                                details?.totalCbmOrder?.cbmSum,
                                                3,
                                            )}{' '}
                                            CBM
                                        </Descriptions.Item>
                                        <Descriptions.Item label="No. of Containers">
                                            {' '}
                                            {container
                                                ? `${container.container.description} x ${container.quantity}`
                                                : ''}{' '}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Placed By">
                                            {' '}
                                            <div>
                                                {details.user &&
                                                    details.user.phone}
                                            </div>{' '}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="E-mail">
                                            {' '}
                                            <div>
                                                {details.user &&
                                                    details.user.email}
                                            </div>{' '}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Order Type">
                                            {' '}
                                            {details.type}{' '}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Total Amount">
                                            {' '}
                                            $
                                            {details.amount &&
                                                formatter.format(
                                                    Number(
                                                        roundOf(
                                                            details.amount.sum,
                                                        ),
                                                    ),
                                                )}{' '}
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Port Name">
                                            {' '}
                                            {details.port &&
                                                details.port.name}{' '}
                                        </Descriptions.Item>
                                        {/*<Descriptions.Item label="Franchise Name">
                            <div>
                              <Text>{details.user.franchise.name}</Text>
                              <img
                                src={details.user.franchise.file && details.user.franchise.file.url}
                                style={{height: 24, marginLeft:8}} />
                            </div>
                          </Descriptions.Item>*/}
                                    </Descriptions>
                                </div>

                                <div>
                                    <div style={{ display: 'flex' }}>
                                        <img
                                            alt=""
                                            src={invoice}
                                            style={{
                                                height: 100,
                                                width: 100,
                                                resizeMode: 'contain',
                                                margin: 16,
                                            }}
                                        />
                                        <div>
                                            <div
                                                style={{ margin: 8 }}
                                                className="title-desc"
                                            >
                                                Order Address
                                            </div>
                                            <div style={{ padding: 8 }}>
                                                <div>
                                                    {details?.user?.phone}
                                                </div>
                                                <div>
                                                    {details?.user?.email}
                                                </div>
                                                <div>
                                                    {details?.franchiseAddress}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*
                        <br/>
                        <div style={{display: 'flex'}}>
                          <DirectionsBoatOutlined style={{fontSize:80, margin:16}} />
                          <div>
                            <div className="title-desc">Shipping Address</div>
                            <div style={{padding:8}}>
                              <div>{details.adminDetail.phone}</div>
                              <div>{details.adminDetail.email}</div>
                              <div>{details.adminDetail.address}</div>
                            </div>
                          </div>
                        </div>
                        */}
                                </div>
                            </div>
                        </TabPane>
                        <TabPane tab="Items" key="2">
                            <OrderProducts details={details} />
                        </TabPane>
                        <TabPane tab="Action" key="3">
                            <OrderAction
                                details={details}
                                callback={getOrderDetailsById}
                                setActiveTabKey={setActiveTabKey}
                            />
                        </TabPane>
                        {details.rating && (
                            <TabPane tab="View Feedback" key="4">
                                <div className="rating-container">
                                    <div>Rated {details.rating}/5</div>
                                    <Row gutter={10}>
                                        {[1, 2, 3, 4, 5].map((i) => (
                                            <Col key={i}>
                                                {i <= details.rating ? (
                                                    <StarFilled
                                                        style={{
                                                            color: '#FFD700',
                                                        }}
                                                    />
                                                ) : (
                                                    <StarOutlined
                                                        style={{
                                                            color: '#C7C7C7',
                                                        }}
                                                    />
                                                )}
                                            </Col>
                                        ))}
                                    </Row>
                                </div>
                                <br />
                                {details.remark && (
                                    <Input.TextArea
                                        rows={4}
                                        readOnly={true}
                                        style={{
                                            marginTop: 8,
                                            backgroundColor: '#FFF',
                                        }}
                                        value={details.remark}
                                    />
                                )}
                            </TabPane>
                        )}
                    </Tabs>
                </div>
                {ratingFlag && (
                    <Modal
                        title="Please rate this order !"
                        width={400}
                        visible={true}
                        closable={false}
                        maskClosable={false}
                        onOk={() => {
                            if ([1, 2, 3, 4, 5].includes(orderRating)) {
                                try {
                                    const data = API_SERVICE.rate_order(
                                        details.id,
                                        orderRating,
                                        orderRemark,
                                    );
                                } catch (e) {
                                    notification.error({
                                        message: API_SERVICE.handleErrors(e),
                                    });
                                } finally {
                                    setRatingFlag(false);
                                }
                            }
                        }}
                        okText="Submit"
                        cancelText="Skip"
                        onCancel={() => setRatingFlag(false)}
                    >
                        <div className="rating-container">
                            <div>{orderRating}/5</div>
                            <Row gutter={10}>
                                {[1, 2, 3, 4, 5].map((i) => (
                                    <Col key={i}>
                                        {i <= orderRating ? (
                                            <StarFilled
                                                style={{ color: '#FFD700' }}
                                            />
                                        ) : (
                                            <StarOutlined
                                                style={{ color: '#C7C7C7' }}
                                                onClick={() => setRating(i)}
                                            />
                                        )}
                                    </Col>
                                ))}
                            </Row>
                        </div>
                        <br />
                        <div>Feedback (optional)</div>
                        <Input.TextArea
                            rows={4}
                            style={{ marginTop: 8 }}
                            onChange={(e) => setOrderRemark(e.target.value)}
                        />
                    </Modal>
                )}
            </AdminListLayout>
        </div>
    );
}
