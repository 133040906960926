import React from 'react';

export function get_port(){
  //Do not modify key "port-preference"
  const port = localStorage.getItem('port-preference');
  if (port) return JSON.parse(port);
  else return {
    id: 1,
    name: ''
  };
}

export function get_user(){
  const user = localStorage.getItem('user');
  if (user) return JSON.parse(user)
  else return false
}


export function get_permissions(){
  const user = localStorage.getItem('user');
  try {
    const permissions = JSON.parse(user).user.permissions
    if (permissions)
      return permissions
    else return []
  }
  catch {
    return []
  }
}

export async function refresh_cart(){
  const timestamp = new Date().getTime()
  localStorage.setItem('cart_update',String(timestamp))
}
