import React, { CSSProperties, useEffect, useState } from 'react';
//import './index.scss';
import {
    Col,
    Row,
    Typography,
    Button,
    InputNumber,
    Divider,
    notification,
} from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from '../../services/api-service';
import {get_port} from 'client/src/services/local-storage';

const { Text, Title, Link } = Typography;
export default function Counter(props: any) {
  const history = useHistory();
  const { t } = useTranslation();
  const [count,set_count] = React.useState(props.quantity)
  const minimum_count = parseInt(props.min) || 1
  const [loading,set_loading] = React.useState(false)
const [boxQuantity,setBoxQuantity] = React.useState(props.boxQuantity);
console.log("props received",boxQuantity);
  // async function decrement(){
  //   if (count>minimum_count)
  //     set_count(count-1)
  // }
  // async function increment(){
  //   set_count(count+1)
  // }

  async function decrement(){
    // if (count>minimum_count)
    //   set_count(count-1)
    if(count % boxQuantity === 0){
        let newCount = count;
        newCount=newCount-boxQuantity;
        set_count(newCount);
      }
      else{
        let newCount = count;
        newCount=Math.floor(newCount/boxQuantity)*boxQuantity;
          set_count(newCount);
      }
    
  }
  async function increment(){
    //set_count(count+1)
    let newCount = count;
    if(count % boxQuantity === 0){

        newCount=newCount+boxQuantity;
      set_count(newCount);
    }
    else{
        let newCount = count;
        newCount=(Math.floor(newCount/boxQuantity)+1)*boxQuantity;
        set_count(newCount);
    }
  }

  async function update_count(){
      try {
          set_loading(true);
          const body = {
            "type": props.id,
            "portId": props.portId,
            "productId": props.productId,
            "quantity": count
          }
          const resp = await API_SERVICE.add_to_cart(body);
          if (props.onChange)
            props.onChange()
          set_loading(false);

        }
        catch (e) {
          console.log('loglog',e)
          notification.error({
            message: t(API_SERVICE.handleErrors(e)),
          });
          set_loading(false);
        }

  }

  React.useEffect(()=>{
    if (count!==props.quantity)
      update_count()
  },[count])


  return (
    <div>
          <Row gutter={10}>
              <Col>
                  <Button type="default" shape="circle" icon={<MinusOutlined/>} onClick={decrement} size={'middle'} disabled={loading}/>
              </Col>
              <Col flex={'auto'} className={'text-center'}>
                  <div className="counter">{count}</div>
              </Col>
              <Col>
                  <Button type="default" shape="circle" icon={<PlusOutlined/>} onClick={increment} size={'middle'} disabled={loading}/>
              </Col>
          </Row>
    </div>
  )

}
