import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Space, Descriptions, Typography, Dropdown, InputNumber, Menu, Upload, Pagination, Table, Row, Col, Tag, Checkbox, Select, Modal, notification, message } from 'antd';
import { DeleteOutlined, UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import API_SERVICE from '../../../services/api-service';

const { Text, Title, Link } = Typography;
export default function ORDER_RECEIVED({ details }: any) {
    const [shipping_charges,set_shipping_charges] = useState(details.shipmentCharge)
    const [other_charges,set_other_charges] = useState(details.otherTax)
    const [loading,set_loading] = React.useState(false)
    const [file_id,set_file_id] = React.useState(false)

    const form_width = 205

    async function submit(){
      set_loading(true)
      try {
        const data = await API_SERVICE.pi_approval(details.id,
          {
            "isPiApproved" : true,
		        "shipmentCharge" : shipping_charges,
		        "otherTax" : other_charges,
            'supporting_doc_id': file_id,
          }
        )
        window.location.assign('/admin/orders')
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
      set_loading(false)
    }

    async function handle_upload(file){
      try {
        const data = await API_SERVICE.upload_file(file,'DOCUMENT')
        set_file_id(data.data.data.id)
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
    }

    return (
      <div>
        <div className="title-desc">Status : {details.status}</div>

        <div style={{
          margin: 8,
          width: form_width,
          }} >
            <div>Shipping charges :</div>
            <InputNumber
              value={shipping_charges}
              onChange={set_shipping_charges}
              />
        </div>
        <div style={{
          margin: 8,
          width: form_width,
          }} >
            <div>Tax/Other charges :</div>
            <InputNumber
              value={other_charges}
              onChange={set_other_charges}
              />
        </div>

        <div style={{
          margin: 8,
          width: 200,
          }} >
          <br/>
          <Upload
            accept=".png,.jpg,.jpeg,.pdf"
            maxCount={1}
            beforeUpload={file => {
              handle_upload(file)
              return false;
             }}
            className="upload-list-inline">
            <Button icon={<UploadOutlined />}>Upload Supporting Docs</Button>
          </Upload>
        </div>
        <Button
          type="primary"
          style={{
            margin: 8,
            width: form_width,
          }}
          disabled={loading}
          onClick={()=>{
            submit()
          }}>Generate Performa Invoice</Button>
      </div>
    )
}
