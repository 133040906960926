import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import { Button, Menu, Modal, notification, Space, Form, Typography, Input, Row, Col, Select, Divider, Avatar, Image, Upload, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { UserOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import {get_user} from '../../services/local-storage';
import TextArea from 'antd/es/input/TextArea';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function UserProfile({ details, onClose }: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [exit_modal,set_exit] = useState([])
    const history = useHistory();

    //const [details,set_details] = useState(false)
    const fetchUser = async (id) => {
        try {
            const { data: { data: { items } } } = await API_SERVICE.users({ id:id });
            set_details(items.length && items[0]);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const [file,set_file] = useState(false)
    const [thumb,set_thumb] = useState(false)
    async function handle_upload(file){
      try {
        const data = await API_SERVICE.upload_file(file,'PROFILE_PIC')
        set_file(data.data.data.id)
        const image = await toBase64(file)
        set_thumb(image)
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
    }

    /*
    useEffect(() => {
        const user = get_user();
        if (!user)
          onClose()
        else {
          fetchUser(user.id);
        }
    }, []);
    if (!details){
      return <div />
    }*/
    return (
        <Modal title={
            (<Space>
                <UserOutlined />
                <Text>User Profile</Text>
            </Space>)
        }
               width={400}
               visible={true}
               onOk={
                   () => {
                       form.submit();
                   }
               }
               onCancel={() =>
                 /*onClose()*/
                 set_exit(
                 <Modal
                   closable={false}
                   zIndex={1200}
                   width={300}
                   centered
                   okText="Yes"
                   cancelText="No"
                   onOk={()=>onClose()}
                   onCancel={()=>set_exit([])}
                   visible={true}>
                   <Text>Are you sure you want to exit ?</Text>
                 </Modal>)
               }>
               <Col align="center">
                 <Upload
                   accept=".png,.jpg,.jpeg"
                   maxCount={4}
                   itemRender={()=>[]}
                   listType="picture"
                   onRemove={()=>set_file(false)}
                   beforeUpload={file => {
                      if (!file.type.includes('image')) {
                        message.error(`${file.name} is not a png file`);
                        return Upload.LIST_IGNORE;
                      }
                      else {
                        handle_upload(file)
                        return false;
                      }
                    }}
                   className="upload-list-inline">
                      <Avatar
                        style={{
                         border: '1px solid #C7232A'
                        }}
                        size={92}
                        icon={<UserOutlined />}
                        src={
                          thumb? <Image src={thumb} />
                          :details && details.file? <Image src={details.file.url} />
                          :null} />
                 </Upload>
               </Col>
            <Form
                size='large'
                form={form}
                initialValues={details || {}}
                fields={[]}
                onFinish={(value) => {
                    const reqObj: any = value;
                    setSaving(true);
                    if (details) {
                        if (!reqObj['password']) {
                            delete reqObj['password'];
                        }
                        if (file) reqObj.fileId = file;
                        API_SERVICE.update_franchise_user(details.id, reqObj).then((d) => {
                            onClose(true);
                        }).catch((e) => {
                            console.log(e)
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        }).finally(() => {
                            setSaving(false);
                        });
                    } else {
                        API_SERVICE.add_franchise_user(reqObj).then((d) => {
                            onClose(true);
                        }).catch((e) => {
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        }).finally(() => {
                            setSaving(false);
                        });
                    }
                }}
                layout='vertical'
                requiredMark={true}
            >

                    <Col>
                        <Form.Item
                            label={'Fullname'}
                            name={'name'}
                            rules={[
                                { required: true, message: 'Name required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label={'Email'}
                            name={'email'}
                            rules={[
                                { required: true, type: 'email', message: 'Email required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label={'Phone'}
                            name={'phone'}
                            rules={[
                                { required: true, message: 'Phone required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label={'Password'}
                            name={'password'}
                            rules={[
                                { required: !details, message: 'Password required' },
                            ]}
                        >
                            <Input type={'password'} />
                        </Form.Item>
                    </Col>
                    {/*
                      <Col md={24} sm={24} xs={24}>
                          <Form.Item
                              label={'Address'}
                              name={'address'}
                              rules={[
                                  { required: true, message: 'Address required' },
                              ]}
                          >
                              <TextArea rows={2} />
                          </Form.Item>
                      </Col>
                    */}

            </Form>
            {exit_modal}
        </Modal>
    );
}


const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});
