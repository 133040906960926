import * as React from 'react';
import { ReactElement } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import MobileLayout from 'shared/components/layout/MobileLayout';

import { useTranslation } from 'react-i18next';
import Login from './login/Login';
import HomePage from './home/HomePage';
import Dashboard from './Dashboard';
import ProductListPage from './ProductList/ProductListPage';
import ProductDetailsPage from './ProductDetails/ProductDetailsPage';
import DesktopAdminLayout from 'shared/components/layout/DesktopAdminLayout';
import FranchisePage from './Franchise/FranchisePage';
import ProductListing from './ProductsAdmin/ProductListing';
import Shipping from './Shipping';
import Account from './Account';
import Orders from './OrdersAdmin/Orders';
import OrderDetails from './OrdersAdmin/OrderDetails';
import UserOrderDetails from './Orders/OrderDetails';
import Notifications from './Notifications/Notifications';

import {
    DASHBOARD,
    PRODUCT_LIST,
    CATEGORIES_LIST,
    CONTAINER_TYPES,
    FRANCHISES_LIST,
    PORT_AND_SUPPLIERS_LIST,
    ROLES_LIST,
    USER_LIST,
    ORDER_LIST,
    VIEW_KNOWLEDGE,
    UPLOAD_KNOWLEDGE,
} from 'shared/constants/RouteConstants';
import CategoriesPage from './Categories/CategoriesPage';
import PortAndSuppliersPage from './PortAndSuppliers/PortAndSuppliers';
import UsersPage from './UsersAndRolesManagement/UsersPage';
import RolesPage from './UsersAndRolesManagement/RolesPage';
import ContainersPage from './Containers/ContainersPage';
import ViewDocument from './KnowledgeBase/ViewDocument';
import UploadDocument from './KnowledgeBase/UploadDocument';

interface MenuItem {
    route: string;
    icon: ReactElement;
    label: string;
}

type Props = {};

const ClosedLayout: React.FunctionComponent<Props> = () => {
    const { t } = useTranslation();

    const { pathname } = useLocation();
    const history = useHistory();

    const selectedMenu = pathname;

    function handleMenuClick(route: string) {
        if (route !== pathname) {
            history.push(route);
        }
    }

    const AppLayout = DesktopAdminLayout;

    React.useEffect(() => {
        document.title =
            'Admin | Danube Home One Application | Home Decor, Furnishings, Lighting, Bath & Kitchen';
    }, []);

    return (
        <AppLayout selectedMenu={selectedMenu} onMenuClick={handleMenuClick}>
            <Switch>
                <Route exact path={DASHBOARD} component={Dashboard} />

                <Route exact path={PRODUCT_LIST} component={ProductListing} />
                <Route exact path={FRANCHISES_LIST} component={FranchisePage} />
                <Route
                    exact
                    path={CATEGORIES_LIST}
                    component={CategoriesPage}
                />
                <Route
                    exact
                    path={PORT_AND_SUPPLIERS_LIST}
                    component={PortAndSuppliersPage}
                />
                <Route exact path={USER_LIST} component={UsersPage} />
                <Route
                    exact
                    path={CONTAINER_TYPES}
                    component={ContainersPage}
                />
                <Route exact path={ROLES_LIST} component={RolesPage} />
                <Route exact path={VIEW_KNOWLEDGE} component={ViewDocument} />
                <Route
                    exact
                    path={UPLOAD_KNOWLEDGE}
                    component={UploadDocument}
                />
                <Route exact path={ORDER_LIST} component={Orders} />
                <Route
                    exact
                    path={ORDER_LIST + '/:order_id'}
                    component={OrderDetails}
                />

                <Route
                    exact
                    path="/admin/notifications"
                    component={Notifications}
                />

                <Route exact path="/admin/shipping" component={Shipping} />
                <Route exact path="/admin/account" component={Account} />

                <Route
                    exact
                    path="/admin/franchises/:id/:mode"
                    component={ProductDetailsPage}
                />
            </Switch>
        </AppLayout>
    );
};

export default ClosedLayout;
