import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import { Button, Menu, Modal, notification, Space, Form, Typography, Input, Row, Col, Select, Divider } from 'antd';
import { useHistory } from 'react-router-dom';
import { ShopOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';

import AccessControl from 'client/src/services/AccessControl';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function UserCreateOrEditPage({ onClose, details }: any) {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [roles, setRoles] = useState([] as any);
    const history = useHistory();
    const fetchRoles = async () => {
        try {
            const { data: { data: { items } } } = await API_SERVICE.roles({ perpage: 1000 });
            setRoles(items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };
    useEffect(() => {
        fetchRoles();
    }, []);
    useEffect(()=>{
      if (roles.length && details && !(details.role.id===2 || details.role.id===3)){
        form.setFieldsValue({
          roleId: details.role.id
        })
      }
    },[roles])
    return (
        <Modal title={
            (<Space>
                <ShopOutlined />
                <Text>{details ? 'Edit User' : 'Add New user'}</Text>
            </Space>)
        }
               width={800}
               visible={true}
               onOk={
                   () => {
                       form.submit();
                   }
               }
               onCancel={() => onClose()}>
            <Form
                size='large'
                form={form}
                initialValues={details || {}}
                fields={[]}
                onFinish={(value) => {
                    const reqObj: any = value;
                    setSaving(true);
                    if (details) {
                        if (!reqObj['password']) {
                            delete reqObj['password'];
                        }
                        API_SERVICE.userUpdate(details.id, reqObj).then((d) => {
                            onClose(true);
                        }).catch((e) => {
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        }).finally(() => {
                            setSaving(false);
                        });
                    } else {
                        API_SERVICE.userCreate(reqObj).then((d) => {
                            onClose(true);
                        }).catch((e) => {
                            console.log(e)
                            notification.error({ message: API_SERVICE.handleErrors(e) });
                        }).finally(() => {
                            setSaving(false);
                        });
                    }
                }}
                layout='vertical'
                requiredMark={true}
            >
                <Row gutter={20}>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Name'}
                            name={'name'}
                            rules={[
                                { required: true, message: 'Name required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Email'}
                            name={'email'}
                            rules={[
                                { required: true, type: 'email', message: 'Email required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Phone'}
                            name={'phone'}
                            rules={[
                                { required: true, message: 'Phone required' },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    {(!details || !(details.role.id===2 || details.role.id===3)) && <AccessControl id={details?39:38}>
                      <Col md={12} sm={24} xs={24}>
                          <Form.Item
                              label={'Role'}
                              name={'roleId'}
                              rules={[
                                  { required: true, message: 'Role required' },
                              ]}
                          >
                              <Select>
                                  {
                                      roles.map((role: any) => {
                                          return <Select.Option
                                              value={role.id}>{role.name}</Select.Option>;
                                      })
                                  }
                              </Select>
                          </Form.Item>
                      </Col>
                    </AccessControl>}
                    <Col md={12} sm={24} xs={24}>
                        <Form.Item
                            label={'Password'}
                            name={'password'}
                            rules={[
                                { required: !details, message: 'Password required' },
                            ]}
                        >
                            <Input type={'password'} />
                        </Form.Item>
                    </Col>
                    <Col md={24} sm={24} xs={24}>
                        <Form.Item
                            label={'Address'}
                            name={'address'}
                            rules={[
                                { required: true, message: 'Address required' },
                            ]}
                        >
                            <TextArea rows={2} />
                        </Form.Item>
                    </Col>

                </Row>


            </Form>
        </Modal>
    );
}
