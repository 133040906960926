import React, { CSSProperties } from 'react';
import './index.scss';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import { Checkbox, Col, Row, Input, Typography, Space, Spin, notification,Button } from 'antd';
import { CodeSandboxOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useHistory } from 'react-router-dom';
import API_SERVICE from 'client/src/services/api-service';

const { Search } = Input;
const contentStyle: CSSProperties = {
    height: '320px',
    color: '#fff',
    width: '200px',
    lineHeight: '320px',
    textAlign: 'center',
    background: '#364d79',
};
const { Text, Title, Link } = Typography;
export default function CategoryCarousel() {
    const history = useHistory();
    const { t } = useTranslation();
    const [data,set_data] = React.useState([])
    const [loading,set_loading] = React.useState(true)
    const items = 5
    const [flags,set_flags] = React.useState({
      pairs: 0,
      items: 0,
      under_limit: true,
      view_width: 0,
    })
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 3000, min: 1600 },
            items: 5,
            // slidesToSlide: items,
        },
        desktop: {
          breakpoint: { max: 1599, min: 1370 },
          items: 4,
          // slidesToSlide: items,
        },
        laptop: {
          breakpoint: { max: 1369, min: 992 },
          items: 3,
          // slidesToSlide: items,
        },
        tablet: {
            breakpoint: { max: 992, min: 545 },
            items: 2,
            // slidesToSlide: items,
        },
        mobile: {
            breakpoint: { max: 545, min: 0 },
            items: 1,
            // slidesToSlide: items,
        },
    };

    async function get_categories() {
      try {
        const params={
          page:-1
        }
          const resp = await API_SERVICE.get_categories(params);
          set_data(resp.data.data.items);
          set_loading(false);
      } catch (e) {
        console.log(e)
          notification.error({
              message: t(API_SERVICE.handleErrors(e)),
          });
          set_loading(false);
      }
    }
    React.useEffect(()=>{
      if (data && data.length){
        const pairs = Math.floor((data.length+1)/2)
        set_flags({
          pairs: pairs,
          items: (pairs<items)? pairs:items,
          under_limit: (pairs<items),
          view_width: pairs*(250+24), //card-width + margin
        })
      }
    },[data])
    React.useEffect(()=>{
      get_categories()
    },[])
    if (loading) return null;
    else return (
        <div className="full_width">
          <div className="centered">
            <div className="padding_24">
              <Title style={{margin: 0}} level={3}>SHOP BY CATEGORY</Title>
              
            </div>
          </div>
          <div className="category-carousel-container" style={{maxWidth:flags.view_width}}>

              <Carousel
                  swipeable={true}
                  draggable={true}
                  showDots={!flags.under_limit}
                  responsive={responsive}
                  ssr={false} // means to render carousel on server-side.
                  infinite={false}
                  autoPlay={false}
                  autoPlaySpeed={50000000}
                  keyBoardControl={true}
                  customTransition="all .5"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  // removeArrowOnDeviceType={['tablet', 'mobile']}
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                  >
                  {[...Array(flags.pairs).keys()].map((index, i) => {
                      const category_A = data[2*index]
                      const category_B = data[2*index+1]
                      return(
                        <div key={i} className="pair">
                          <div onClick={()=>history.push(`/home/${category_A.name}/${category_A.id}`)}>
                            <CategoryTile category={category_A} />
                          </div>
                        <div onClick={()=>history.push(`/home/${category_B.name}/${category_B.id}`)}>
                            <CategoryTile category={category_B} />
                          </div>
                        </div>
                      )
                  })}
              </Carousel>
          </div>
        </div>
    );
}

function CategoryTile({category}:any) {
  if (category)
  return (
    <div key={category.id} className={'category-card'}>
        <div className="category-image">
          {category.fileId?
            <img className="cover-image" src={category.file.url} />
            :<div/>
          }
        </div>
        <div className="category-section">
            <div>
                <Text className={'category-name'}>{category.name.toUpperCase()}</Text>
         
            </div>
        </div>
    </div>
  )
  else return null;
}
