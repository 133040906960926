import React, { CSSProperties, useEffect, useState } from 'react';
import { Typography, Button, Modal, notification, message, Space, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import Parameters from 'shared/parameters';
//import './index.scss';
import { useHistory } from 'react-router-dom';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';

/*
const supported_ext = [
  'CSV',
  'ZIP',
] //uppercase important
*/

const modal_width = 300
const { Text, Title, Link } = Typography;
export default function BulkUpload({ onClose,cartId, callback, upload_bulk }: any) {
    const [datafile,set_datafile] = useState(false)
    const [images,set_images] = useState(false)
    const [sample_url,set_sample_url] = useState('')
    //const type='.xls,.xlsx';
    async function handle_upload(){
      console.log(datafile);
      try {
        const resp=await API_SERVICE.upload_bulk_order(cartId,datafile)
        window.open(resp.data.data,"_blank") ;
          callback();
        onClose()
        message.success('Files uploaded');
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
    }
    async function get_sample_url(){
      try {
        const data = await API_SERVICE.bulk_import_sample()
        set_sample_url(data.data.data.url)
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
    }
    function handle_sample_download () {
        window.open(API_SERVICE.downloadBulkOrderSampleSheet());
    }

    useEffect(()=>{
      get_sample_url()
    },[])
    return (
        <Modal title={
            (<div className="series">
              <Space>
                <UploadOutlined />
                <Text>Upload Bulk</Text>
              </Space>
            </div>
            )
        }
               width={modal_width}
               visible={upload_bulk}
               closable={true}
               onCancel = {
                 ()=>onClose()
               }
               footer={[
                /*<Button key="cancel" key="esc" onClick={onClose}>Cancel</Button>,*/
                   <>
                   <Button key="action" type="primary" key="esc" onClick={handle_sample_download} >Download Sample</Button>
                    <Button key="action" type="primary" key="esc" onClick={handle_upload} >Upload</Button>
                    </>
                ]}>
                  {/*
                    <Upload
                      accept=".csv,.zip"
                      beforeUpload={file => {
                          const name = file.name.toUpperCase()
                          for (let i=0;i<supported_ext.length;i++){
                            if (name.includes(supported_ext[i])){
                              //handle_upload(file,supported_ext[i])
                              return false;
                            }
                          }
                          message.error(`Please select ${supported_ext.join('/')} files`);
                          return Upload.LIST_IGNORE;
                       }}
                      className="upload-list-inline">
                      <Button>Click to Upload {supported_ext.join('/')}</Button>
                    </Upload>
                  */}
                  <Upload
                    accept=".xls,.xlsx"
                    beforeUpload={file => {
                      if (file) set_datafile(file)
                      return false
                     }}
                     onRemove={()=>{
                       set_datafile(false)
                     }}
                    className="upload-list-inline">
                    {!datafile && <Button style={{width:150}}>Click to Select XLS</Button>}
                  </Upload>
                  <br />
                 {/*} <Upload
                    accept=".zip"
                    beforeUpload={file => {
                      if (file) set_images(file)
                      return false
                     }}
                    onRemove={()=>{
                      set_images(false)
                    }}
                    className="upload-list-inline">
                    {!images && <Button style={{width:150}}>Click to Select ZIP</Button>}
                  </Upload>
                  <br />
                  {/*<a href={sample_url} target="_blank">Download sample</a>*/}
        </Modal>
    );
}
