import React, {useState} from 'react';


import { Form, Input, Button, Row, Col, Modal, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import API_SERVICE from 'client/src/services/api-service';

interface Props {
    onLogin: (email: string, password: string) => void;
    loading: boolean;
}

export default function LoginForm({
                                      onLogin,
                                      loading,
                                  }: Props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [forgotForm] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };


    const handleCancel = () => {
        setIsModalVisible(false);
        forgotForm.resetFields();
    };

    function handleSubmit(values: any) {
        if (onLogin) {
            const { email, password } = values;
            onLogin(email, password);
        }
    }

    return (
        <>
            <Form
                size='large'
                form={form}
                fields={[]}
                initialValues={
                    {
                        email: '',
                        password: '',
                    }
                }
                onFinish={handleSubmit}
                layout='vertical'
                requiredMark={false}
            >
                <Form.Item
                    label={'Username'}
                    name='email'
                    rules={[
                        { required: true, type: 'email', message: 'Email required' },
                    ]}
                >
                    <Input autoComplete={'off'}
                    />
                </Form.Item>
                <Form.Item
                    label={'Password'}
                    name='password'
                    rules={[
                        { required: true, message: 'Password required' },
                    ]}>
                    <Input
                        autoComplete={'off'}
                        type='password'
                    />
                </Form.Item>
                <br/>
                <Form.Item>
                    <Button
                        type='primary'
                        htmlType='submit'
                        loading={loading}
                        shape='round'
                        size={'large'}
                        className='theme-button primary full-width'
                    >
                        Login
                    </Button>
                </Form.Item>
                <div>
                    <small><a className="theme-link" onClick={showModal}>Forgot Password?</a></small>
                </div>
            </Form>

            <Modal title="Forgot Password"
                   footer={null}
                   visible={isModalVisible}
                   onCancel={handleCancel}
            >
                <Form
                    className={'forgot-password-form'}
                    form={forgotForm}
                    fields={[]}
                    onFinish={async (value: string) => {
                        setSendingEmail(true);
                        try {
                            await API_SERVICE.forgotPassword(value.f_email).then(()=> {
                                forgotForm.resetFields();
                                notification.success({
                                    message: 'An email with new password has been sent to you!',
                                });
                                handleCancel();
                            });
                        } catch (e) {
                            notification.error({
                                message: t(API_SERVICE.handleErrors(e)),
                            });
                        } finally {
                            setSendingEmail(false);
                        }
                    }}
                    requiredMark={false}
                    layout={'inline'}
                >
                    <Form.Item
                        style={{flex: 1}}
                        label={'Email ID'}
                        name='f_email'
                        rules={[
                            { required: true, message: 'Email required' },
                        ]}
                    >
                        <Input autoComplete={'off'}
                        />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type='primary'
                            htmlType={sendingEmail ? 'button' : 'submit'}
                            loading={loading}
                            className='theme-button primary full-width '
                        >
                            {sendingEmail && <Spin />} Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>

    );
}
