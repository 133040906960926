import React, { useState } from 'react';
import { Redirect, useHistory, Link } from 'react-router-dom';
import { Row, Col, Carousel, Typography, notification } from 'antd';
import ForgotPasswordForm from './forgotPasswordForm';

import './forgotPassword.scss';
import { useTranslation } from 'react-i18next';


import { AuthConsumer } from 'shared/contexts/AuthContext';
import API_SERVICE from 'client/src/services/api-service';
import { COMPANY_LIST } from '../../constants/RouteConstants';

const { Title } = Typography;
import logo from 'shared/assets/logo.png';
import banner from '../login/assets/banner.png';
import card_stock from '../login/assets/card_stock.png';
import card_shipping from '../login/assets/card_shipping.png';
import card_account from '../login/assets/card_account.png';
import card_training from '../login/assets/card_training.png';
import card_client from '../login/assets/card_client.png';

interface Props {
    location: any;
}

const contentStyle = {
    height: '373px',
    color: '#fff',
    lineHeight: '373px',
    textAlign: 'center',
    background: '#364d79',
};

export default function ForgotPassword({ location }: Props) {
    const { t } = useTranslation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const { from } = location.state || { from: { pathname: '/' } };

    return (
        <AuthConsumer>
            {({ isAuth, updateAuthToken }) => {
                return (isAuth ? (
                    <Redirect to={from} />
                ) : (
                    <div className='login'>
                        <Row gutter={20} justify={"center"} className='login-form-row'>
                            <Col flex="1" className="banner">
                                <img src={banner} />
                            </Col>
                            <Col flex='400px'>
                                <div className='login-form-wrapper'>
                                    <div className="logo-container">
                                      <img src={logo} />
                                    </div>
                                    <Title level={4}>Reset password</Title>
                                    <ForgotPasswordForm
                                        onforgotPassword={async (data: any) => {
                                            setLoading(true);
                                            try {
                                                await API_SERVICE.resetPassword(data);
                                                notification.success({
                                                    message: 'Password changed',
                                                });
                                                setLoading(false);
                                                history.push('/login');
                                            } catch (e) {
                                                notification.error({
                                                    message: t(API_SERVICE.handleErrors(e)),
                                                });
                                                setLoading(false);
                                            }
                                        }}
                                        loading={loading}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={20} style={{ marginTop: '16px' }}>
                            {
                              [card_stock, card_shipping, card_account, card_training, card_client].map((item, index)=>
                                <Col key={index} className={'service-card'}>
                                  <div/>
                                  <img src={item}  className="image"/>
                                  <h3>
                                    {[
                                      'STOCK MANAGEMENT',
                                      'SHIPPING MANAGEMENT',
                                      'ACCOUNT MANAGEMENT',
                                      'TRAINING',
                                      'CLIENT MANAGEMENT'
                                    ][index]}
                                  </h3>
                                </Col>
                              )
                            }
                        </Row>
                    </div>
                ));
            }
            }
        </AuthConsumer>
    );
}
