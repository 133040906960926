import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Menu, Modal, notification, Space, Form, Typography, Input, Row, Col, Select, Divider, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import { PlusCircleOutlined, UserOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import TextArea from 'antd/es/input/TextArea';

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function Addresses() {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);
    const [addresses, set_addresses] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [details,set_details] = useState(null);
    const [countries, setCountries] = useState([]);
    const history = useHistory();


    const getCountries = async () => {
        try {
            const { data: { data: { items } } } = await API_SERVICE.admin_countries({ perpage: 1000 });
            setCountries(items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const fetchAddresses = async () => {
        try {
            const { data: { data: { items } } } = await API_SERVICE.get_addresses({ perpage: 1000 });
            set_addresses(items);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        }
    };

    const deleteAddress = async (id) => {
      API_SERVICE.delete_address(id).then((d) => {
      }).catch((e) => {
          notification.error({ message: API_SERVICE.handleErrors(e) });
      }).finally(() => {
          fetchAddresses();
      });
    }

    const column_data = [
        {
            title: 'S.No',
            key: 'sno',
            align: 'center',
            render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
        },
        {
            title: 'Full Address',
            key: 'address',
            width: '400px',
            ellipsis: true,
            render: (a: any, v: any, index: number) =>
            <Text>{a.houseNumber ? a.houseNumber : ''}{a.lineOne ? ", " + a.lineOne : ''}{a.lineTwo ? ", " + a.lineTwo : ''}{a.city ? ", " + a.city : ''}{a.state ? ", " + a.state : ''}{(a.country && a.country.name) ? ", " + a.country.name : ''}{a.port ? ", " + a.port : ''}{a.zipCode ? ", " + a.zipCode : ''}</Text>,
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (a: any, v: any, index: number) =>
              <div>
                <EditOutlined onClick={()=>set_details(a)} style={{padding: 6}} />
                <DeleteOutlined onClick={()=>deleteAddress(a.id)} style={{padding: 6}} />
              </div>,
        },
    ]



    useEffect(() => {
        fetchAddresses();
        getCountries()
    }, []);

    useEffect(() => {
        if (details)
          form.setFieldsValue(details)
        else form.resetFields()
    }, [details]);

    return (
      <div className={'franchise-wrapper page-wrapper'}>
      <AdminListLayout titleIcon={<UserOutlined />} title={'Manage Addresses'} >
          <Row
            gutter={20}
            style={{
              padding: 24,
            }} >
                  <Col md={12} sm={24} xs={24}>
                    <Table loading={fetching} pagination={false} dataSource={addresses} columns={column_data}>
                    </Table>
                  </Col>

                  <Col md={12} sm={24} xs={24}>
                    <Form
                        size='large'
                        form={form}
                        fields={[]}
                        onFinish={(value) => {
                            const reqObj: any = value;
                            setSaving(true);

                            if (details) {
                                API_SERVICE.edit_address(details.id, { ...reqObj }).then((d) => {
                                    form.resetFields();
                                }).catch((e) => {
                                    notification.error({ message: API_SERVICE.handleErrors(e) });
                                }).finally(() => {
                                    setSaving(false);
                                    fetchAddresses();
                                    set_details(null)
                                });
                            } else {
                                API_SERVICE.add_address({ ...reqObj }).then((d) => {
                                    form.resetFields();
                                }).catch((e) => {
                                    notification.error({ message: API_SERVICE.handleErrors(e) });
                                }).finally(() => {
                                    setSaving(false);
                                    fetchAddresses();
                                });
                            }
                        }}
                        layout='vertical'
                        requiredMark={true}
                    >
                        <Row gutter={20} align="middle">
                            <Col md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'Full Name'}
                                    name={'name'}
                                    rules={[
                                        { required: true, message: 'Name required' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'Contact No.'}
                                    name={'phone'}
                                    rules={[
                                        { required: true, message: 'Contact No. required' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label={'Building/Warhouse/Plot No.'}
                                    name={'houseNumber'}
                                    rules={[
                                        { required: true, message: 'Building/Warhouse/Plot No. required' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label={'Street Address Line-1'}
                                    name={'lineOne'}
                                    rules={[
                                        { required: true, message: 'Street Address Line-1 required' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={24} sm={24} xs={24}>
                                <Form.Item
                                    label={'Street Address Line-2'}
                                    name={'lineTwo'}
                                    rules={[
                                        { required: true, message: 'Street Address Line-2 required' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'City'}
                                    name={'city'}
                                    rules={[
                                        { required: true, message: 'City required' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'Port'}
                                    name={'port'}
                                    rules={[
                                        { required: true, message: 'Port required' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'State'}
                                    name={'state'}
                                    rules={[
                                        { required: true, message: 'State required' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'Country'}
                                    name={'countryId'}
                                    rules={[
                                        { required: true, message: 'Country required' },
                                    ]}
                                >
                                    <Select>
                                        {
                                            countries.map((c: any) => {
                                                return <Select.Option value={c.id}>{c.name}</Select.Option>;
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={24} xs={24}>
                                <Form.Item
                                    label={'Zip/Pin Code'}
                                    name={'zipCode'}
                                    rules={[
                                        { required: true, message: 'Zip/Pin Code required' },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={12} sm={24} xs={24}>
                              <br/>
                              <Row gutter={10}>
                                <Col>
                                  <Button htmlType={'submit'} type="primary" icon={<PlusCircleOutlined />}>{details? 'Edit':'Add new Address'}</Button>
                                </Col>
                                {details && <Col>
                                    <Button type="primary" onClick={()=>set_details(null)}>Cancel</Button>
                                  </Col>
                                }
                              </Row>
                            </Col>
                        </Row>
                    </Form>
                  </Col>
            </Row>
        </AdminListLayout>
        </div>
    );
}
