import React, { CSSProperties, useEffect, useState } from 'react';
import './index.scss';
import {
    Button,
    Col,
    Menu,
    notification,
    Row,
    Space,
    Checkbox,
    Tag,
    Typography,
    Form,
    Input,
    Select,
    Divider, Tree,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { BarsOutlined, PlusCircleOutlined } from '@ant-design/icons';
import API_SERVICE from '../../services/api-service';
import AccessControl from 'client/src/services/AccessControl'
import {get_permissions} from 'client/src/services/local-storage'

const { SubMenu } = Menu;

const { Text, Title, Link } = Typography;
export default function PortAndSuppliersPage(props: any) {
    const [saving, setSaving] = useState(false);

    const history = useHistory();
    const [countries, setCountries] = useState([] as any[]);
    const [fetching, setFetching] = useState(false);
    const [checkedKeys, setCheckedKeys] = useState([] as any[]);
    const [countryDetails, setCountryDetails] = useState(false);
    const [portDetails, setPortDetails] = useState(false);
    const [supplierDetails, setSupplierDetails] = useState(false);
    const refresh = async () => {
        setCountryDetails(false)
        setPortDetails(false)
        setSupplierDetails(false)

        setFetching(true);
        try {
            const { data: { data: { items: countries } } } = await API_SERVICE.admin_countries({ perpage: 1000 });
            const { data: { data: { items: ports } } } = await API_SERVICE.ports({ perpage: 1000 });
            const { data: { data: { items: suppliers } } } = await API_SERVICE.suppliers({ perpage: 1000 });
            ports.forEach((port: any) => {
                port.key = port.id + 'p';
                port.title = `${port.id}. ${port.name}`;
                port.type = 'PORT';
                port['children'] = suppliers.filter((s: any) => s.portId === port.id).map((s: any) => {
                    return { ...s, key: s.id+'s', type: 'SUPPLIER', title: `${s.id}. ${s.name}` };
                });
            });
            countries.forEach((country: any) => {
                country.key = country.id + 'c';
                country.type = 'COUNTRY';
                country.title = `${country.id}. ${country.name}`;
                country['children'] = ports.filter((p: any) => p.countryId === country.id);
            });
            setCountries(countries);
            const CheckedKeys: number[] = [];
            const HalfCheckedKeys: number[] = [];
            countries.forEach((country: any) => {
                country.children.forEach((port: any) => {
                    port.children.forEach((supplier: any) => {
                        if (supplier.isActive) {
                            CheckedKeys.push(supplier.id+'s');
                        }
                    });
                    const port_status = checked_status(port.children)
                    if (port_status==='CHECKED'){
                      CheckedKeys.push(port.id+'p');
                      port.isActive = true;
                    }
                    else if (port_status==='HALF_CHECKED'){
                      HalfCheckedKeys.push(port.id+'p');
                      port.isActive = 'HALF_CHECKED';
                    }
                    else port.isActive = false
                });
                const country_status = checked_status(country.children)
                if (country_status==='CHECKED')
                    CheckedKeys.push(country.id+'c');
                else if (country_status==='HALF_CHECKED')
                    HalfCheckedKeys.push(country.id+'c');
            });
            setCheckedKeys({
              checked: CheckedKeys,
              halfChecked: HalfCheckedKeys
            });
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };
    useEffect(() => {
        refresh();
    }, []);

    function handle_select(e,i) {
      const node = i.node
      switch (node.type) {
        case 'PORT':
          setPortDetails(node)
          break;
        case 'SUPPLIER':
          setSupplierDetails(node)
          break;
        case 'COUNTRY':
          setCountryDetails(node)
          break;
        default:
          break;
      }
    }

    return (
        <div className={'categories-wrapper page-wrapper'}>
            <Row className={'main-wrapper'}>
                <Col span={12} className={'main-section'}>
                    <div className='header-section'>
                        <Row gutter={20} align={'middle'}>
                            <Col>
                                <Title level={3}> <BarsOutlined /></Title>
                            </Col>
                            <Col>
                                <Title level={4}> Port & Supplier Master</Title>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={12} className={'main-section'}>
                    <div className='header-section'>
                        <Row gutter={20} justify={'center'} align={'middle'}>
                            <AccessControl id={5}>
                            <Col>
                                <Space style={{ paddingTop: '2px' }}>
                                    <Checkbox onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }} checked={true}>Fully Enabled</Checkbox>
                                    <Checkbox onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }} indeterminate={true}>Partially Enabled</Checkbox>
                                    <Checkbox checked={false} onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}>Disabled</Checkbox>
                                </Space>
                            </Col>
                            </AccessControl>
                        </Row>
                    </div>

                </Col>
            </Row>
            <Row className={'main-wrapper'}>
                <Col span={12} className={'main-section'}>
                    <div className='sub-section'>
                        <CountryForm refresh={refresh} details={countryDetails} />
                        <Divider />
                        <PortForm refresh={refresh} countries={countries} details={portDetails} />
                        <Divider />
                        <SupplierForm refresh={refresh} countries={countries} details={supplierDetails} />
                    </div>
                </Col>
                <Col span={12} className={'main-section'}>
                    <div className='sub-section'>
                        <AccessControl id={5}>
                        <Tree
                            checkable
                            // onExpand={onExpand}
                            // expandedKeys={expandedKeys}
                            // autoExpandParent={autoExpandParent}
                            onCheck={async (e: any, v: any) => {
                                //AccessControl
                                if (!get_permissions().includes(7)){
                                  return false
                                }
                                const { id, name, isActive, portId, countryId, type } = v.node;
                                switch (type) {
                                    case 'PORT':
                                        API_SERVICE.portUpdate(id, {
                                            name,
                                            countryId,
                                            isActive: !isActive,
                                        }).then((d) => {
                                            console.log(d)
                                            refresh();
                                        }).catch((e) => {
                                            notification.error({ message: API_SERVICE.handleErrors(e) });
                                        });
                                        break;
                                    case 'COUNTRY':
                                        API_SERVICE.countryUpdate(id, {
                                            name,
                                            isActive: !isActive,
                                        }).then((d) => {
                                            refresh();
                                        }).catch((e) => {
                                            notification.error({ message: API_SERVICE.handleErrors(e) });
                                        });
                                        break;
                                    case 'SUPPLIER':
                                        API_SERVICE.supplierUpdate(id, {
                                            name,
                                            portId,
                                            isActive: !isActive,
                                        }).then((d) => {
                                            refresh();
                                        }).catch((e) => {
                                            notification.error({ message: API_SERVICE.handleErrors(e) });
                                        });
                                        break;
                                }

                            }}
                            disabled={fetching}
                            checkStrictly
                            checkedKeys={fetching? []:checkedKeys}
                            onSelect={handle_select}
                            treeData={countries}
                        />
                        </AccessControl>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

const CountryForm = ({ refresh, details }: any) => {
    const [saving, setSaving] = useState(false);
    const [form] = Form.useForm();

    useEffect(()=>{
      if (details){
        form.setFieldsValue(details)
      }
      else form.resetFields()
    },[details])
    return <Form
        form={form}
        size='large'
        fields={[]}
        onFinish={(value) => {
            const reqObj: any = value;
            setSaving(true);
            if (details){
              API_SERVICE.countryUpdate(details.id ,{ ...reqObj, isActive: true }).then((d) => {
                  refresh();
              }).catch((e) => {
                  notification.error({ message: API_SERVICE.handleErrors(e) });
              }).finally(() => {
                  setSaving(false);
              });
            }
            else {
              API_SERVICE.countryCreate({ ...reqObj, isActive: true }).then((d) => {
                  refresh();
              }).catch((e) => {
                  notificationerror({ message: API_SERVICE.handleErrors(e) });
              }).finally(() => {
                  setSaving(false);
              });
            }
        }}
        layout='vertical'
        requiredMark={true}>
        <Row gutter={20} align={'middle'}>
            <Col span={24}>
                <Form.Item
                    label={'Country name'}
                    name={'name'}
                    rules={[
                        { required: true, message: 'Name required' },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <Col flex={'auto'}>
                <Form.Item
                    label={'Country Code'}
                    name={'code'}
                    rules={[
                        { required: true, message: 'Code required' },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <AccessControl id={!details?6:7}>
            <Col>
                <Button loading={saving} htmlType={'submit'} type={'primary'} style={{ marginTop: '20px' }}
                        icon={<PlusCircleOutlined />}>
                    {!details? 'Add':'Save'}
                </Button>
            </Col>
            </AccessControl>
            {details && <Col>
                  <Button onClick={refresh} type={'primary'} style={{ marginTop: '20px' }}
                          icon={<PlusCircleOutlined />}>
                      Cancel
                  </Button>
              </Col>}
        </Row>
    </Form>;
};

const PortForm = ({ refresh, countries, details }: any) => {
    const [form] = Form.useForm();
    const [saving, setSaving] = useState(false);

    useEffect(()=>{
      if (details){
        form.setFieldsValue(details)
      }
      else form.resetFields()
    },[details])

    return <Form
        size='large'
        form={form}
        fields={[]}
        onFinish={(value) => {
            const reqObj: any = value;
            setSaving(true);
            if (details){
              API_SERVICE.portUpdate(details.id ,{ ...reqObj, isActive: true }).then((d) => {
                  refresh();
              }).catch((e) => {
                  notification.error({ message: API_SERVICE.handleErrors(e) });
              }).finally(() => {
                  setSaving(false);
              });
            }
            else {
              API_SERVICE.portCreate({ ...reqObj, isActive: true }).then((d) => {
                  refresh();
              }).catch((e) => {
                  notification.error({ message: API_SERVICE.handleErrors(e) });
              }).finally(() => {
                  setSaving(false);
              });
            }
        }}
        layout='vertical'
        requiredMark={true}>
        <Row gutter={20} align={'middle'}>
            <Col span={24}>
                <Form.Item
                    label={'Select Country'}
                    name={'countryId'}
                    rules={[
                        { required: true, message: 'Country required' },
                    ]}
                >
                    <Select>
                        {
                            countries.map((c: any) => {
                                return <Select.Option value={c.id}>{c.name}</Select.Option>;
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col flex={'auto'}>
                <Form.Item
                    label={'Port name'}
                    name={'name'}
                    rules={[
                        { required: true, message: 'Name required' },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Col>

            <AccessControl id={!details?6:7}>
            <Col>
                <Button loading={saving} htmlType={'submit'} type={'primary'} style={{ marginTop: '20px' }}
                        icon={<PlusCircleOutlined />}>
                    {!details? 'Add':'Save'}
                </Button>
            </Col>
            </AccessControl>
            {details && <Col>
                  <Button onClick={refresh} type={'primary'} style={{ marginTop: '20px' }}
                          icon={<PlusCircleOutlined />}>
                      Cancel
                  </Button>
              </Col>}
        </Row>
    </Form>;
};

const SupplierForm = ({ refresh, countries, details }: any) => {
    const [saving, setSaving] = useState(false);
    const [form] = Form.useForm();
    const [ports, setPorts] = useState([]);

    useEffect(()=>{
      if (details){
        const country_id = details.port.countryId
        setPorts(countries.find((c: any) => c.id === country_id).children)
        form.setFieldsValue({
          name: details.name,
          countryId: country_id,
          portId: details.port.id
        })
      }
      else form.resetFields()
    },[details])

    return <Form
        size='large'
        form={form}
        fields={[]}
        form={form}
        onFinish={(value) => {
            const reqObj: any = value;
            setSaving(true);
            if (details){
              details.name = reqObj.name
              API_SERVICE.supplierUpdate(details.id, { ...details, isActive: true }).then((d) => {
                  refresh();
              }).catch((e) => {
                  notification.error({ message: API_SERVICE.handleErrors(e) });
              }).finally(() => {
                  setSaving(false);
              });
            }
            else {
              API_SERVICE.supplierCreate({ ...reqObj, isActive: true }).then((d) => {
                  refresh();
              }).catch((e) => {
                  notification.error({ message: API_SERVICE.handleErrors(e) });
              }).finally(() => {
                  setSaving(false);
              });
            }
        }}
        layout='vertical'
        requiredMark={true}>
        <Row gutter={20} align={'middle'}>
            <Col span={24}>
                <Form.Item
                    label={'Select country'}
                    name={'countryId'}
                    rules={[
                        { required: true, message: 'country required' },
                    ]}
                >
                    <Select onChange={(e) => {
                        form.resetFields(['portId']);
                        setPorts(countries.find((c: any) => c.id === e).children);
                    }
                    }>
                        {
                            countries.map((c: any) => {
                                return <Select.Option value={c.id}>{c.name}</Select.Option>;
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col span={24}>
                <Form.Item
                    label={'Select port'}
                    name={'portId'}
                    rules={[
                        { required: true, message: 'Port required' },
                    ]}
                >
                    <Select>
                        {
                            ports.map((c: any) => {
                                return <Select.Option value={c.id}>{c.name}</Select.Option>;
                            })
                        }
                    </Select>
                </Form.Item>
            </Col>
            <Col flex={'auto'}>
                <Form.Item
                    label={'Supplier name'}
                    name={'name'}
                    rules={[
                        { required: true, message: 'Name required' },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Col>
            <AccessControl id={!details?6:7}>
            <Col>
                <Button loading={saving} htmlType={'submit'} type={'primary'} style={{ marginTop: '20px' }}
                        icon={<PlusCircleOutlined />}>
                    {!details? 'Add':'Save'}
                </Button>
            </Col>
            </AccessControl>
            {details && <Col>
                  <Button onClick={refresh} type={'primary'} style={{ marginTop: '20px' }}
                          icon={<PlusCircleOutlined />}>
                      Cancel
                  </Button>
              </Col>}
        </Row>
    </Form>;
};

function checked_status(children) {
  let all_active = true
  let all_empty = true
  children.map((child)=>{
    if (child.isActive==='HALF_CHECKED'){
      console.log(child)
      all_active = false
      all_empty = false
    }
    else if (child.isActive)
      all_empty = false
    else all_active = false
  })
  if (all_empty)
    return 'ALL_EMPTY'
  else if (all_active)
    return 'CHECKED'
  else return 'HALF_CHECKED'
}
