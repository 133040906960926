import React, { CSSProperties, useEffect, useState } from 'react';
//import './index.scss';
import { Button, Space, Typography, Dropdown, Menu, Pagination, Table, Row, Col, Tag, Checkbox, Select, Modal, notification, message } from 'antd';
import { ShopOutlined, PlusCircleOutlined, UserOutlined, DownOutlined, UploadOutlined, SyncOutlined, EditOutlined, DeleteOutlined  } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';
import AddProduct from './AddProduct';
import BulkImport from './BulkImport';
import StoreImport from './StoreImport';
import ProductFilter from './ProductFilter';
import ColumnSelector from './ColumnSelector';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from '../../services/api-service';
import AccessControl from 'client/src/services/AccessControl';

const { Text, Title, Link } = Typography;
const { Option } = Select;


export default function ProductListing(props) {

  const history = useHistory();
  const { t } = useTranslation();

  const [data,set_data] = React.useState([])
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState('');
  const [addNew, setAddNew] = useState(false);
  const [edit_details, set_edit] = useState(false);
  const [delete_details, set_delete] = useState(false);
  const [filter_view, set_filter_view] = useState(false);
  const [filter,set_filter] = useState({
    categoryIds: [],
    isActive: true,
  })
  const [column_select,set_column_select] = useState(false)
  const [categories,set_categories] = useState([])
  const [pagination, setPagination] = useState({ page: 1, perpage: '10' });
  const [total, setTotal] = useState(10);

  const [selected_id,push_selection] = useState(false)
  const [selection_arr,set_selection] = useState([])
  const [bulk_action,set_bulk_action] = useState({})
  const [bulk_wait,set_bulk_wait] = useState(false)

  const [add_bulk,set_add_bulk] = useState(false)
  const [add_stores,set_add_stores] = useState(false)

  /*TESTING
  useEffect(()=>{
    console.log(selection_arr)
  },[selection_arr])
  */
  function oncheck(id){
    let arr = selection_arr
    let index = arr.indexOf(id)
    if (index>-1){
      arr.splice(index,1)
      set_selection(arr)
    }
    else {
      arr.push(id)
      set_selection(arr)
    }
  }
  useEffect(()=>{
    if (selected_id)
      oncheck(selected_id)
    else push_selection(false)
  },[selected_id])
  function Serial(index){
    return
  }
  const column_data = [
      {
        title: 'Select',
        key: 'select',
        align: 'center',
        render: (a: any) => {
          return (
            <Space key={a.id} size={0}>
                <Checkbox onChange={()=>push_selection(a.id)} />
            </Space>
          )
        }
      },
      {
          title: 'S.No',
          key: 'sno',
          align: 'center',
          render: (a: any, v: any, index: number) => <Text>{index + 1}</Text>,
      },
      {
          title: 'Thumbnail',
          key: 'thumbnail',
          align: 'center',
          render: (a: any) => <Space size={0}>
              <img src={a.thumbnail? a.thumbnail.url:''} className="thumbnail" />
          </Space>,
      },
      {
          title: 'Name',
          key: 'name',
          render: (a: any) => <Space direction={'vertical'} size={0}>
              <Text>{a.name || 'NA'}</Text>
          </Space>,
      },
      {
          title: 'SKU ID',
          key: 'skuid',
          align: 'center',
          render: (a: any) => <Space direction={'vertical'} size={0}>
              <Text>{a.skuId || 'NA'}</Text>
          </Space>,
      },
      {
        title: (
          <div>
            <div>Pricing</div>
            <div className="text-desc">-- In Stock</div>
            <div className="text-desc">-- At Source</div>
            <div className="text-desc">-- Custom</div>
          </div>
          ),
        key: 'pricing',
        render: (a: any) => <Space direction={'vertical'} size={0}>
            <Text>${a.dubaiPrice || 'NA'}</Text>
            <Text>${a.portPrice || 'NA'}</Text>
            <Text>${a.customPrice || 'NA'}</Text>
        </Space>,
      },
      {
        title: (
          <div>
            <div>Quantity</div>
            <div className="text-desc">-- In Stock</div>
            <div className="text-desc">-- At Source</div>
            <div className="text-desc">-- Custom MOQ</div>
          </div>
          ),
        key: 'quantity',
        render: (a: any) => <Space direction={'vertical'} size={0}>
            <Text>{a.dubaiQuantity || 'NA'}</Text>
            <Text>{a.totalPortProducts? a.totalPortProducts.quantity:'NA'}</Text>
            <Text>MOQ-{a.minimumOrderQuantity || 'NA'}</Text>
        </Space>,
      },
      {
          title: 'CBM',
          key: 'cbm',
          align: 'center',
          render: (a) => <Space direction={'vertical'} size={0}>
              <Text>{(a.cbnValue).toFixed(3) || 'NA'} CBM</Text>
          </Space>,
      },
      {
          title: 'Status',
          key: 'isActive',
          align: 'center',
          render: (a:any) => (<div>
              {a.isActive ? <Tag color='green'>Active</Tag> : <Tag color='red'>Inactive</Tag>}
          </div>),
      },
      {
          title: 'Visibility',
          key: 'visibility',
          align: 'center',
          render: (a: any) => <Space direction={'vertical'} size={0}>
              {a.isCatalogue && <Text>Catalogue</Text>}
              {a.isSearchable && <Text>Search</Text>}
          </Space>,
      },
      {
          title: 'Color',
          key: 'color',
          align: 'center',
          render: (a: any) => <Space direction={'vertical'} size={0}>
              <Text>{a.color || 'NA'}</Text>
          </Space>,
      },
      {
          title: 'Size',
          key: 'size',
          align: 'center',
          render: (a: any) => <Space direction={'vertical'} size={0}>
              <Text>{a.size || 'NA'}</Text>
          </Space>,
      },
      {
          title: 'Category',
          key: 'category',
          align: 'center',
          render: (a: any) => <Space direction={'vertical'} size={0}>
              <Text>{a.category.name || 'NA'}</Text>
          </Space>,
      },
      {
          title: 'Action',
          key: 'action',
          align: 'center',
          render: (a: any) => <Space size={0}>
              <AccessControl id={15} closed={
                <Button icon={<EditOutlined/>} disabled />
              }>
                <Button icon={<EditOutlined/>} onClick={()=>set_edit(a)} />
              </AccessControl>
              <AccessControl id={16} closed={
                <Button icon={<DeleteOutlined/>} disabled />
              }>
                <Button icon={<DeleteOutlined/>} onClick={()=>set_delete(a)} />
              </AccessControl>
          </Space>,
      },

  ];
  const [columns,set_columns] = useState(column_data)

  const refresh = async () => {
      setFetching(true);
      set_selection([])

      //update serialno
      const column_set = [...columns]
      column_set[1] = {
          title: 'S.No',
          key: 'sno',
          align: 'center',
          render: (a: any, v: any, index: number) =>
          <Text>{(pagination.page-1)*(pagination.perpage) + (index+1)}</Text>,
      }
      set_columns(column_set)

      const params = { ...pagination };
      if (search)
        params['q'] = search;
      let categoryIds = false
      if (filter.categoryIds.length)
        categoryIds = '%5B'+encodeURI(filter.categoryIds)+'%5D';
      params['isActive'] = filter.isActive;
      try {
          const { data: { data } } = await API_SERVICE.get_admin_products(params,categoryIds);
          set_data(data.items);
          setTotal(data.total);
      } catch (e) {
          notification.error({ message: API_SERVICE.handleErrors(e) });
      } finally {
          setFetching(false);
      }
  };

  async function delete_product() {
    const id = delete_details.id
    set_delete(false)
    try {
        const resp = await API_SERVICE.deleteProduct(id);
        refresh();
    } catch (e) {
      console.log(e)
        notification.error({
            message: t(API_SERVICE.handleErrors(e)),
        });
    }
  }

    async function ls_sync() {
      try {
          const resp = await API_SERVICE.ls_sync();
          message.success("LS Sync Complete")
      } catch (e) {
          notification.error({
              message: t(API_SERVICE.handleErrors(e)),
          });
      }
    }

  async function apply_bulk(){
    if (bulk_action.selection){
      if (!selection_arr.length)
        return message.info('No products are selected')
      try {
          const data = {
            "productIds" : selection_arr,
            "type": bulk_action.action
          }
          const resp = await API_SERVICE.bulk_action(data);
          set_bulk_wait(true)
          refresh();
      } catch (e) {
        console.log(e)
          notification.error({
              message: t(API_SERVICE.handleErrors(e)),
          });
      } finally {
        set_bulk_action({})
      }
    } else message.info('No bulk action is selected')
  }

  const bulk_actions = <Menu>
    {[
      'enable',
      'disable',
      'delete',
      'catalogue_enable',
      'catalogue_disable',
      'search_enable',
      'search_disable'
    ].map((item,index)=>{
      const arr = item.split('_')
      arr.reverse();
      const option = arr.join(' ').title()
      return (
        <Menu.Item key={index} onClick={()=>set_bulk_action({
            selection: option,
            action: item
          })}>
          {option}
        </Menu.Item>
      )}
    )} </Menu>;

  async function get_categories() {
    try {
        const resp = await API_SERVICE.get_categories();
        //console.log(resp.data.data.items)
        set_categories(resp.data.data.items);
    } catch (e) {
      console.log(e)
        notification.error({
            message: t(API_SERVICE.handleErrors(e)),
        });
    }
  }
  useEffect(()=>{
    get_categories()
  },[])

  useEffect(()=>{
    if (!selection_arr.length)
      set_bulk_wait(false)
  },[selection_arr])

  useEffect(() => {
    if (!addNew && !edit_details)
      refresh();
  }, [props.location, pagination, search, filter, addNew, edit_details]);

  return (
    <div className={'franchise-wrapper page-wrapper'}>
    <AdminListLayout titleIcon={<ShopOutlined />} title={'All Products'}
       titleSearch={
         <AccessControl id={13}>
         <div className='search-bar'>
           <Search
               placeholder='Search any product here'
               size='large'
               onSearch={
                   (e) => {
                      setPagination({ page: 1, perpage: '10' })
                      setSearch(e);
                   }
               }
           />
       </div>
       </AccessControl>}
       titleAction={
         <Row gutter={20}>
            <Col>
            <AccessControl id={18}>
              <Button type={'primary'} onClick={ls_sync} icon={<SyncOutlined />}>
                  LS Sync
              </Button>
            </AccessControl>
            </Col>
            <Col>

              <Button type={'primary'} onClick={() => set_add_stores(true)} icon={<UploadOutlined />}>
                  Stock Update
              </Button>

            </Col>
         </Row>
       }
       panel={[
         <AccessControl id={14}>
         <Button key="0" type={'primary'} onClick={() => setAddNew(true)} icon={<PlusCircleOutlined />}>
             Add New
         </Button>
         </AccessControl>,

        <AccessControl id={17}>
         <Button key="0" type={'primary'} onClick={() => setAddNew(true)} icon={<UploadOutlined />} onClick={()=>set_add_bulk(!add_bulk)}>
             Add Bulk
         </Button>
        </AccessControl>

       ]}
       subheader = {[
         [
           <AccessControl id={41}>
            <Button key="0-1" style={{fontWeight:'500'}}>Bulk Action</Button>
           </AccessControl>,

           <AccessControl id={41}>
           <Dropdown key="0-2" overlay={bulk_actions}>
             <Button>
               {bulk_action.selection || 'Select'} <DownOutlined />
             </Button>
           </Dropdown>
           </AccessControl>,

           <AccessControl id={41}>
           <Button key="0-3" type={'primary'} onClick={apply_bulk}>
               Apply
           </Button>
           </AccessControl>,

         ],

         <AccessControl id={13}>
         <Button key="2" type={'primary'} onClick={() => set_filter_view(true)} icon={<PlusCircleOutlined />}>
             Filter
         </Button>
         </AccessControl>,

         <AccessControl id={13}>
         <Button key="3" type={'primary'} onClick={() => set_column_select(true)} icon={<PlusCircleOutlined />}>
             Columns
         </Button>
         </AccessControl>,

         <AccessControl id={13}>
          <Pagination key="4" showSizeChanger defaultCurrent={1} total={total} onChange={(page,size)=>setPagination({ page:page, perpage: size })} />
         </AccessControl>
       ]}>

       <AccessControl id={13}>
         {!bulk_wait && <Table loading={fetching} scroll={{ x:true }} pagination={false} dataSource={data} columns={columns}>
         </Table>
        }
       </AccessControl>


       {(addNew || edit_details) && <AddProduct details={edit_details} categories={categories} onClose={()=>{
         setAddNew(false)
         set_edit(false)
       }} />}

       {add_bulk && <BulkImport onClose={()=>set_add_bulk(false)} />}
       {add_stores && <StoreImport onClose={()=>set_add_stores(false)} />}

       <ProductFilter
        categories={categories}
        selection={filter}
        onChange={set_filter}
        visible={filter_view}
        close={()=>set_filter_view(false)}
        />

      <ColumnSelector
        columns={column_data}
        selection={columns}
        onChange={set_columns}
        visible={column_select}
        close={()=>set_column_select(false)}
        />

      <Modal
        closable={false}
        zIndex={1200}
        width={300}
        centered
        okText="Yes"
        cancelText="No"
        onOk={delete_product}
        onCancel={()=>set_delete(false)}
        visible={delete_details}>
        <Text>Are you sure you want to delete ?</Text>
      </Modal>

    </AdminListLayout>
    </div>
  )

}
