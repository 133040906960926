import AXIOS from 'axios';
import Parameters from 'shared/parameters';

function getPublicInstance() {
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': Parameters.ApiUrl,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
}

function getProtectedInstance() {
    let user = localStorage.getItem('user') as string;
    let token = '';
    if (user) {
        token = JSON.parse(user).token;
    } else {
        window.location.href = '/login';
    }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': Parameters.ApiUrl,
        'headers': {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
}

function getProtectedFileUpload() {
  let user = localStorage.getItem('user') as string;
  let token = '';
  if (user) {
  token = JSON.parse(user).token;
  } else {
  window.location.href = '/login';
  }
  return AXIOS.create({
  // @ts-ignore
  'accept': 'application/json',
  'baseURL': Parameters.ApiUrl,
  'headers': {
  Accept: 'application/json',
  Authorization: `Bearer ${token}`,
  'Content-Type': 'multipart/form-data',
  },
  });
  }
  

function handleErrors(error: any) {
    let message = 'Something went wrong!!';
    if (error && error.response && error.response.data) {
      const data = error.response.data;
      if (data.error)
          message = data.error;
      else if (data.message){
        const keys = Object.keys(data.message)
        if (keys.length)
          message = data.message[keys[0]];
      }
    }
    return message;
}

async function login(email: string, password: string) {
    const instance = getPublicInstance();
    return await instance.post('/api/authenticate', { email, password });
}

async function self() {
    const instance = getProtectedInstance();
    return await instance.get('/api/self');
}

async function forgotPassword(email: string) {
    const instance = getPublicInstance();
    return await instance.post('/api/forgot-password', {email});
}

async function resetPassword(reqObj: any) {
    const instance = getPublicInstance();
    return await instance.post('/api/reset-password', reqObj);
}

async function franchises(params: any) {
    const instance = getProtectedInstance();
    return await instance.get('/admin/franchises', { params });
}

async function franchiseCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/admin/franchises', data);
}

async function franchiseUpdate(id: string, data: any) {
    const instance = getProtectedInstance();
    return await instance.put('/admin/franchises/' + id, data);
}

async function categories(params: any = { perpage: 1000 }) {
    const instance = getProtectedInstance();
    return await instance.get('/admin/categories', { params });
}

async function categoryCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/admin/categories', data);
}

async function categoryUpdate(id: number, data: any) {
    const instance = getProtectedInstance();
    return await instance.put('/admin/categories/' + id, data);
}

async function countries(params: any = { perpage: 1000 }) {
    const instance = getProtectedInstance();
    return await instance.get('/api/countries');//, { params });
}

async function admin_countries(params: any = { perpage: 1000 }) {
    const instance = getProtectedInstance();
    return await instance.get('/admin/countries');//, { params });
}

async function countryCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/admin/countries', data);
}

async function countryUpdate(id: number, data: any) {
    const instance = getProtectedInstance();
    return await instance.put('/admin/countries/' + id, data);
}


async function roles(params: any = { perpage: 1000 }) {
    const instance = getProtectedInstance();
    return await instance.get('/admin/roles', { params });
}

async function roleCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/admin/roles', data);
}

async function roleUpdate(id: number, data: any) {
    const instance = getProtectedInstance();
    return await instance.put('/admin/roles/' + id, data);
}

async function ports(params: any = { perpage: 1000 }) {
    const instance = getProtectedInstance();
    return await instance.get('/admin/ports', { params });
}

async function portCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/admin/ports', data);
}

async function portUpdate(id: number, data: any) {
    const instance = getProtectedInstance();
    return await instance.put('/admin/ports/' + id, data);
}

async function suppliers(params: any = { perpage: 1000 }) {
    const instance = getProtectedInstance();
    return await instance.get('/admin/suppliers', { params });
}

async function supplierCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/admin/suppliers', data);
}

async function supplierUpdate(id: number, data: any) {
    const instance = getProtectedInstance();
    return await instance.put('/admin/suppliers/' + id, data);
}


async function users(params: any = { perpage: 1000 }) {
    const instance = getProtectedInstance();
    return await instance.get('/admin/users', { params });
}

async function userCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/admin/users', data);
}

async function userUpdate(id: number, data: any) {
    const instance = getProtectedInstance();
    return await instance.put('/admin/users/' + id, data);
}

async function userDelete(id: number) {
    const instance = getProtectedInstance();
    return await instance.delete('/admin/users/' + id);
}



async function containers(params: any = { perpage: 1000 }) {
    const instance = getProtectedInstance();
    return await instance.get('/admin/containers', { params });
}

async function containerCreate(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/admin/containers', data);
}

async function containerUpdate(id: number, data: any) {
    const instance = getProtectedInstance();
    return await instance.put('/admin/containers/' + id, data);
}

/*
Dhruv Arya
1-7-21
*/

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

async function upload_file(file :any, type: string) {
  const formdata = new FormData();
  formdata.append('file', file);
  formdata.append('type', type);
  const instance = getProtectedFileUpload();
  return await instance.post('/api/files',formdata);
}

async function bulk_import(file :any, images: any) {
  const formdata = new FormData();
  formdata.append('file', file);
  formdata.append('images', images);
  const instance = getProtectedInstance();
  return await instance.post('admin/products-bulk/upload',formdata);
}

async function stores_upload(file :any) {
  const formdata = new FormData();
  formdata.append('file', file);
  const instance = getProtectedInstance();
  return await instance.post('admin/products-stores/upload',formdata);
}

async function get_categories(params?:any) {
    const instance = getProtectedInstance();
    return await instance.get('/admin/categories',{params});
}
async function get_ports() {
    const instance = getProtectedInstance();
    return await instance.get('/admin/countries');
}
async function get_products(params: any = {}, attrib: any = []) {
    const instance = getProtectedInstance();
    let attrib_params = ''
    if (attrib.length){
      attrib_params = `?${attrib.join('&')}`
    }
    return await instance.get(`/api/products`+attrib_params, { params });
}
async function add_to_wishlist(product_id: number) {
    const instance = getProtectedInstance();
    const data: any = {
        productId: product_id
    }
    return await instance.post('/api/wishlist', data);
}

async function remove_from_wishlist(product_id: number) {
    const instance = getProtectedInstance();
    return await instance.delete('/api/wishlist/'+product_id);
}

async function get_wishlist() {
    const instance = getProtectedInstance();
    return await instance.get('/api/wishlist');
}

async function get_download(category_id:number) {
  const instance = getProtectedInstance();
  return await instance.get(`api/categories/${category_id}/products-download`);
}

async function add_to_cart(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/admin/carts', data);
}

async function get_cart() {
    const instance = getProtectedInstance();
    return await instance.get('/admin/carts');
}

async function get_admin_products(params: any = { perpage: 1000 }, categoryIds=false) {
  const instance = getProtectedInstance();
  return await instance.get('/admin/products'+(categoryIds?'?categoryIds='+categoryIds:''), { params });
}

async function post_product(data: any) {
    const instance = getProtectedInstance();
    return await instance.post('/admin/products', data);
}

async function edit_product(data: any, id: any) {
    const instance = getProtectedInstance();
    return await instance.put('/admin/products/'+id, data);
}

async function deleteProduct(id: any) {
    const instance = getProtectedInstance();
    return await instance.delete('/admin/products/'+id);
}

async function get_attributes(params: any) {
    const instance = getProtectedInstance();
    return await instance.get('/api/attributes',{params});
}

async function get_trending_products(params: any = {}, attrib: any = []) {
  const instance = getProtectedInstance();
  let attrib_params = ''
  if (attrib.length){
    attrib_params = `?${attrib.join('&')}`
  }
  return await instance.get('/api/products-trending'+attrib_params,{ params })
}

async function get_franchise_users(params:type = {}) {
  const instance = getProtectedInstance();
  return await instance.get('/api/franchise-users',params)
}

async function update_franchise_user(id: number, data:type = {}) {
  const instance = getProtectedInstance();
  return await instance.put('/api/franchise-users/'+id,data)
}

async function add_franchise_user(data:type = {}) {
  const instance = getProtectedInstance();
  return await instance.post('/api/franchise-users',data)
}

async function bulk_action(data:type = {}) {
  const instance = getProtectedInstance();
  return await instance.post('/admin/bulk-update/products',data)
}

async function bulk_import_sample(params:type = {}) {
  const instance = getProtectedInstance();
  return await instance.get('/admin/sample-csv-products/download',{params})
}

async function permissions(params:type = {}) {
  const instance = getProtectedInstance();
  return await instance.get('/admin/permissions',{})
}

async function update_permissions(id: number, arr:type = []) {
  const instance = getProtectedInstance();
  const data = {
    permissionIds: arr,
  }
  return await instance.post(`/admin/roles/${id}/permissions`,data)
}

async function checkout_cart(cart_id: number, address_id: number) {
  const instance = getProtectedInstance();
  return await instance.post('/admin/orders', {cartId:cart_id, addressId:address_id} )
}

async function get_orders(params: any) {
  const instance = getProtectedInstance();
  return await instance.get('/admin/orders', {params})
}

async function pi_approval(id: number, data: any = {}) {
  const instance = getProtectedInstance();
  return await instance.post(`/admin/orders/${id}/pi-approval`, data )
}

async function get_color(params:any = {}) {
  const instance = getProtectedInstance();
  return await instance.get('/api/color',{params})
}

async function download_invoice(id: number, params:any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`admin/orders/${id}/download-invoice`,{params})
}
async function cart_download(cartid: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/carts/${cartid}/products-download`)
}
async function cancel_orders(order_id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/orders/${order_id}/cancel`)
}

async function get_order_details(order_id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/orders/${order_id}`)
}

async function cancel_pi(order_id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/orders/${order_id}/cancel-pi`)
}

async function upload_bulk_order(cart_id: number,file:any) {
    const formdata = new FormData();
    formdata.append('file', file);
    const instance = getProtectedFileUpload();
    //return await instance.post('/files',formdata);
  return await instance.post(`/api/carts/products-upload`,formdata)
}

async function upload_pi(id: number, data: any) {
  const instance = getProtectedInstance();
  return await instance.post(`api/orders/${id}/upload-pi`,{data})
}

async function payment_status_update(id: number, data: any) {
  const instance = getProtectedInstance();
  return await instance.post(`admin/orders/${id}/payment-status-update`,data)
}


async function payment_sign_off(id: number, data: any) {
  const instance = getProtectedInstance();
  return await instance.post(`admin/orders/${id}/payment-sign-off`,data)
}

async function ready_to_ship(id: number, data: any) {
  const instance = getProtectedInstance();
  return await instance.post(`admin/orders/${id}/shipment`,data)
}

async function order_delivered(id: number, data: any) {
  const instance = getProtectedInstance();
  return await instance.post(`admin/orders/${id}/delivery`,data)
}

async function order_complete(id: number, data: any) {
  const instance = getProtectedInstance();
  return await instance.post(`admin/orders/${id}/complete`,data)
}

async function regenerate_pi(id: number, data: any) {
  const instance = getProtectedInstance();
  return await instance.post(`api/orders/${id}/regenerate-pi`,data)
}

async function dashboard_stats(params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`admin/dashboard`, params)
}

async function client_dashboard_stats(params: any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`api/dashboard`, params)
}

async function get_addresses() {
  const instance = getProtectedInstance();
  return await instance.get(`api/user-address`)
}

async function add_address(data: any) {
  const instance = getProtectedInstance();
  return await instance.post(`api/user-address`,data)
}

async function delete_address(id: number) {
  const instance = getProtectedInstance();
  return await instance.delete(`api/user-address/${id}`)
}

async function edit_address(id: number, data: any) {
  const instance = getProtectedInstance();
  return await instance.put(`api/user-address/${id}`,data)
}

async function header_count() {
  const instance = getProtectedInstance();
  return await instance.get(`api/header-count`)
}

async function export_orders(order_id: number) {
  const instance = getProtectedInstance();
  return await instance.get(`admin/orders/${order_id}/export`)
}

async function top_franchises() {
  const instance = getProtectedInstance();
  return await instance.get(`admin/top-franchises`)
}

async function port_quantity(productId, portId) {
  const instance = getProtectedInstance();
  return await instance.get(`api/product/${productId}/port-quantity?portId=${portId}`)
}

async function ls_sync() {
  const instance = getProtectedInstance();
  return await instance.get(`admin/ls-sync`)
}

async function mini_cart(params:any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`api/mini-cart`,{params})
}

async function franchise_save_signature(id: any, data:any) {
  const instance = getProtectedInstance();
  return await instance.post(`api/franchise/${id}/upload-sign`, data);
}

async function rate_order(id: number, rating: number, remark: string) {
  const instance = getProtectedInstance();
  return await instance.post(`api/orders/${id}/franchise-remark`, { remark, rating });
}

async function get_notifications(params:any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`admin/notifications`,{params})
}

async function open_notification(id: any) {
  const instance = getProtectedInstance();
  return await instance.get(`admin/notifications/${id}`);
}

async function get_headers(params:any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/carts-header`,{params})
}

async function get_cartproducts(params:any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/cart/products`,{params})
}

//Knowledge base

async function franchise_status(id:number,params:any) {
  const instance = getProtectedInstance();
  return await instance.patch(`/admin/documents/status-update/${id}`,params)
}
async function get_uploaded_documents(params: any = { }) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/uploaded-documents`, { params })
}

async function get_view_documents_uploaded_by_franchise(params:any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/uploaded-documents-franchise`,{params})
}
async function get_view_documents_uploaded_by_admin(params:any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`/api/uploaded-documents-admin`,{params})
}
async function get_documents_type(params:any) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/document-type`,{params})
}
async function get_franchise_list() {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/franchise-list`)
}
async function upload_documents_admin(params:any) {
  const instance = getProtectedInstance();
  return await instance.post(`/admin/document-upload`,params)
}
async function upload_documents_franchise(params:any) {
  const instance = getProtectedInstance();
  return await instance.post(`/admin/franchises-document-upload`,params)
}
async function get_uploaded_franchise(params:any = {}) {
  const instance = getProtectedInstance();
  return await instance.get(`/admin/franchises-document`,{params})
}

/*async function bulk_download(params:any = {}) {
  const instance = getProtectedInstance();
  return await instance.get('/temp/SAMPLE-PRODUCT.xlsx',{params})
}*/

function downloadBulkOrderSampleSheet(claimId: any){
    return `${Parameters.ApiUrl}/temp/SAMPLE-PRODUCT.xlsx`;
}

// const custom_path = 'https://dhruvar1998.pythonanywhere.com/'

async function forceDownloadFile(params:any = {}) {
    const instance = getProtectedInstance();
    return await instance.get(`/admin/documents/download-pdf`,{params})
}

const API_SERVICE = {
    login,
    self,
    forgotPassword,
    resetPassword,


    franchises,
    franchiseCreate,
    franchiseUpdate,

    categories,
    categoryCreate,
    categoryUpdate,


    countries,
    admin_countries,
    countryCreate,
    countryUpdate,

    roles,
    roleCreate,
    roleUpdate,

    ports,
    portCreate,
    portUpdate,


    suppliers,
    supplierCreate,
    supplierUpdate,


    users,
    userCreate,
    userUpdate,
    userDelete,

    //_
    // custom_path,
    toBase64,

    get_categories,
    get_ports,

    get_products,
    get_trending_products,
    get_attributes,

    get_wishlist,
    add_to_wishlist,
    remove_from_wishlist,

    get_color,

    add_to_cart,
    get_cart,
    checkout_cart,

    upload_file,
    get_admin_products,
    post_product,
    edit_product,
    deleteProduct,
    bulk_action,
    bulk_import_sample,
    bulk_import,
    stores_upload,

    get_franchise_users,
    add_franchise_user,
    update_franchise_user,
    franchise_save_signature,

    permissions,
    update_permissions,

    containers,
    containerCreate,
    containerUpdate,

    get_orders,
    pi_approval,

    download_invoice,
    upload_pi,
    payment_status_update,
    payment_sign_off,
    ready_to_ship,
    order_delivered,
    order_complete,
    regenerate_pi,
    rate_order,

    dashboard_stats,
    client_dashboard_stats,

    header_count,

    get_addresses,
    add_address,
    delete_address,
    edit_address,

    export_orders,

    top_franchises,

    port_quantity,
    ls_sync,
    mini_cart,

    get_notifications,
    open_notification,

    get_download,
    cart_download,
    cancel_orders,
    get_order_details,
    cancel_pi,
    upload_bulk_order,
    get_headers,
    get_cartproducts,
    downloadBulkOrderSampleSheet,
    
    franchise_status,
    get_uploaded_documents,
    get_view_documents_uploaded_by_franchise,
    get_view_documents_uploaded_by_admin,
    get_documents_type,
    get_franchise_list,
    upload_documents_admin,
    upload_documents_franchise,
    get_uploaded_franchise,

    handleErrors,
    forceDownloadFile
};
export default API_SERVICE;
