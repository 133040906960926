import React, { CSSProperties, useEffect, useState } from 'react';
import { Button, Space, Descriptions, Typography, Dropdown, InputNumber, Menu, Upload, Pagination, Table, Row, Col, Tag, Checkbox, Select, Modal, notification, message } from 'antd';
import { DeleteOutlined, UploadOutlined, DownloadOutlined} from '@ant-design/icons';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import API_SERVICE from '../../../services/api-service';

const { Text, Title, Link } = Typography;
export default function READY_TO_SHIP({ details }: any) {
    const [loading,set_loading] = React.useState(false)

    async function submit(){
      set_loading(true)
      try {
        const data = await API_SERVICE.order_delivered(details.id,{})
        message.info("Marked as delivered")
        window.location.assign('/admin/orders')
      }
      catch (e) {
        notification.error({ message: API_SERVICE.handleErrors(e) });
      }
      set_loading(false)
    }


    return (
      <div>
        <div><b>Status</b> : Order Shipped</div>
        <Button
          type="primary"
          style={{
            marginTop: 16,
          }}
          disabled={loading}
          onClick={()=>{
            submit()
          }}>Mark Order as Delivered</Button>
      </div>
    )
}
