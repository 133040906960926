import React, { CSSProperties, useEffect, useState } from 'react';
//import './index.scss';
import {
    Button,
    Space,
    Typography,
    Dropdown,
    Menu,
    Pagination,
    Table,
    Row,
    Col,
    Tag,
    Checkbox,
    Select,
    Modal,
    notification,
    message,
} from 'antd';
import {
    ShoppingOutlined,
    PlusCircleOutlined,
    EditOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from '../../services/api-service';
import { roundOf } from '../../services/utility';
import AccessControl from 'client/src/services/AccessControl';

import OrderDetails from './OrderDetails';

const { Text, Title, Link } = Typography;
const { Option } = Select;

export default function OrderListing(props) {
    const history = useHistory();
    const { t } = useTranslation();

    const [data, set_data] = React.useState([]);
    const [fetching, setFetching] = useState(false);
    const [search, setSearch] = useState('');
    const [view_order, set_view] = useState(false);
    const formatter = new Intl.NumberFormat('en-US');
    const [categories, set_categories] = useState([]);
    const [pagination, setPagination] = useState({ page: 1, perpage: '10' });
    const [total, setTotal] = useState(10);

    /*TESTING
  useEffect(()=>{
    console.log(selection_arr)
  },[selection_arr])
  */

    const columns = [
        {
            title: 'S.No',
            key: 'sno',
            align: 'center',
            render: (a: any, v: any, index: number) => (
                <Text key={a.id}>
                    {10 * (pagination.page - 1) + (index + 1)}
                </Text>
            ),
        },
        {
            title: 'Order ID',
            key: 'orderId',
            align: 'center',
            render: (a: any, v: any, index: number) => (
                <Link
                    onClick={() => {
                        //set_view(a)
                        history.push({
                            pathname: '/orders/' + a.id,
                            state: {
                                order: a,
                            },
                        });
                    }}
                >
                    {a.orderNumberNew}
                </Link>
            ),
        },
        {
            title: 'Placed on',
            key: 'order_date',
            align: 'center',
            render: (a: any, v: any, index: number) => (
                <Text>{a.createdAt.split('T')[0]}</Text>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            align: 'center',
            render: (a: any, v: any, index: number) => <Text>{a.status}</Text>,
        },
        {
            title: 'Total CBM & Containers',
            key: 'cbm',
            align: 'center',
            render: (a: any, v: any, index: number) => {
                return a.totalCbmOrder !== null ? (
                    <Text>
                        {a?.orderContainer && a?.orderContainer?.container
                            ? `${a?.orderContainer?.container?.description} x ${
                                  a?.orderContainer?.quantity
                              } = ${roundOf(a?.totalCbmOrder?.cbmSum, 3)} CBM`
                            : ''}
                    </Text>
                ) : (
                    'Not Available'
                );
            },
        },
        {
            title: 'Order Type',
            key: 'type',
            align: 'center',
            render: (a: any, v: any, index: number) => <Text>{a.type}</Text>,
        },
        /*
      {
          title: 'Franchise Name',
          key: 'franchise',
          render: (a: any, v: any, index: number) => a.user.franchise?
          <div>
            <img
              src={a.user.franchise.file && a.user.franchise.file.url}
              style={{height: 24}} />
            <br/>
            <Text>{a.user.franchise.name}</Text>
          </div>
          :<div/>,
      },
      */
        {
            title: 'Total Amount',
            key: 'total',
            align: 'center',
            render: (a: any, v: any, index: number) => (
                <Text>
                    $
                    {a.amount &&
                        formatter.format(Number(roundOf(a.amount.sum)))}
                </Text>
            ),
        },
        {
            title: 'Port Name',
            key: 'port',
            align: 'center',
            render: (a: any, v: any, index: number) => (
                <Text>{a.port && a.port.name}</Text>
            ),
        },
        {
            title: 'Placed by',
            key: 'customer',
            align: 'center',
            render: (a: any, v: any, index: number) => (
                <div>
                    <div>{a.user && a.user.name}</div>
                    <div>{a.user && a.user.email}</div>
                </div>
            ),
        },
        {
            title: 'Last Action Taken By',
            key: 'admin',
            align: 'center',
            render: (a: any, v: any, index: number) => (
                <div>
                    <div>{a.admin && a.admin.name ? a.admin.name : '-'}</div>
                    <div>{a.admin && a.admin.email ? a.admin.email : '-'}</div>
                </div>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (a: any, v: any, index: number) => {
                const notRated = a.status === 'ORDER_COMPLETE' && !a.rating;
                return (
                    <div>
                        <Button
                            type="primary"
                            style={
                                notRated
                                    ? {
                                          width: 120,
                                          border: 0,
                                          backgroundColor: '#FF3131',
                                          boxShadow: '0 0 5px #FF3131',
                                      }
                                    : { width: 120 }
                            }
                            onClick={() => {
                                history.push({
                                    pathname: '/orders/' + a.id,
                                    state: {
                                        notRated: notRated,
                                        order: a,
                                    },
                                });
                            }}
                        >
                            {notRated ? 'Rate Order' : 'View Details'}
                        </Button>
                    </div>
                );
            },
        },
    ];

    const refresh = async () => {
        setFetching(true);

        const params = { ...pagination };
        if (search) params['q'] = search;
        try {
            if (props.location.match)
                params['id'] = props.location.match.params.id;
            const {
                data: { data },
            } = await API_SERVICE.get_orders(params);
            if (params.id) {
                history.push({
                    pathname: '/orders/' + params.id,
                    state: {
                        order: data.items.length ? data.item[0] : false,
                    },
                });
            }
            set_data(data.items);
            setTotal(data.total);
        } catch (e) {
            notification.error({ message: API_SERVICE.handleErrors(e) });
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        refresh();
    }, [props.location, pagination, search]);

    /*if (view_order)*/
    // if (props.location.state)
    //   return <OrderDetails details={props.location.state.order} defaultTab={props.location.state.tab || '1'} onClose={()=>{history.push('/orders')}} />

    return (
        <div className={'franchise-wrapper page-wrapper'}>
            <AdminListLayout
                titleIcon={<ShoppingOutlined />}
                title={'All Orders'}
                titleSearch={
                    <div className="search-bar">
                        <Search
                            placeholder="Search any order here"
                            size="large"
                            onSearch={(e) => {
                                setSearch(e);
                            }}
                        />
                    </div>
                }
            >
                <Table
                    loading={fetching}
                    scroll={{ x: true }}
                    pagination={false}
                    dataSource={data}
                    columns={columns}
                ></Table>
                <div
                    style={{
                        padding: 24,
                        float: 'right',
                    }}
                >
                    <Pagination
                        showSizeChanger
                        key="4"
                        defaultCurrent={1}
                        total={total}
                        onChange={(page, size) =>
                            setPagination({
                                page: page,
                                perpage: pagination.perpage,
                            })
                        }
                    />
                </div>

                {/*<Modal
        closable={false}
        zIndex={1200}
        width={300}
        centered
        okText="Yes"
        cancelText="No"
        onOk={delete_product}
        onCancel={()=>set_delete(false)}
        visible={delete_details}>
        <Text>Are you sure you want to delete ?</Text>
      </Modal>*/}
            </AdminListLayout>
        </div>
    );
}
