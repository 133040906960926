import React, { CSSProperties, useEffect, useState } from 'react';
//import './index.scss';
import { Button, Space, Typography, Dropdown, Menu, Pagination, Table, Row, Col, Tag, Checkbox, Select, Modal, notification, message } from 'antd';
import { ShoppingOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined, DownloadOutlined  } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import AdminListLayout from 'shared/components/layout/AdminListLayout/AdminListLayout';

import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from '../../services/api-service';

const { Text, Title, Link } = Typography;
const { Option } = Select;


export default function AccountListing(props) {

  const history = useHistory();
  const { t } = useTranslation();

  const [data,set_data] = React.useState([])
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState('');
  const [view_order, set_view] = useState(false);

  const [categories,set_categories] = useState([])
  const [pagination, setPagination] = useState({ page: 1, perpage: '10' });
  const [total, setTotal] = useState(10);

  const columns = [
      {
          title: 'S.No',
          key: 'sno',
          align: 'center',
          render: (a: any, v: any, index: number) => <Text key={a.id}>{10*(pagination.page-1)+(index+1)}</Text>,
      },
      {
          title: 'Order ID',
          key: 'orderId',
          align: 'center',
          render: (a: any, v: any, index: number) => <Text key={a.id}>{a.orderNumberNew}</Text>,
      },
      {
          title: 'Order Date',
          key: 'order_date',
          align: 'center',
          render: (a: any, v: any, index: number) => <Text>{a.createdAt.split('T')[0]}</Text>,
      },
      {
          title: 'Order Value',
          key: 'order_value',
          align: 'center',
          render: (a: any, v: any, index: number) => <Text>${a.amount? (Number(a.amount.sum)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','):'0'}</Text>,
      },
      {
          title: 'Order Status',
          key: 'status',
          align: 'center',
          render: (a: any, v: any, index: number) => <Text>{a.status}</Text>,
      },
      {
          title: 'Paid Amount',
          key: 'order_value',
          align: 'center',
          render: (a: any, v: any, index: number) => <Text>${(Number(a.receivedAmount)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '0'}</Text>,
      },
      {
          title: 'Pending Amount',
          key: 'order_value',
          align: 'center',
          render: (a: any, v: any, index: number) => <Text>
            ${(a.amount && a.receivedAmount)?
             (Number(a.amount.sum-a.receivedAmount)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              :'0'
            }
          </Text>,
      },
      {
        title: <div>Download<br/> Payment Docs</div>,
        key: 'download',
        align: 'center',
        render: (a: any, v: any, index: number) =>
          <Button
            type="primary"
            style={{borderRadius:50}}
            icon={<DownloadOutlined />}
            disabled={!a.paymentFile}
            onClick={()=>{
              const paymentFile = a.paymentFile
              if (paymentFile)
                window.open(paymentFile.url, '_blank').focus();
              }}
            />,
      }
  ];


  const refresh = async () => {
      setFetching(true);

      const params = { ...pagination };
      if (search)
          params['q'] = search;
      try {
          if (props.location.match)
            params['id'] = props.location.match.params.id
          const { data: { data } } = await API_SERVICE.get_orders(params);
          if (params.id){
            history.push({
              pathname: '/orders/'+params.id,
              state: {
                order: data.items.length? data.item[0] : false
              }
            })
          }
          set_data(data.items);
          setTotal(data.total);
      } catch (e) {
          notification.error({ message: API_SERVICE.handleErrors(e) });
      } finally {
          setFetching(false);
      }
  };


  useEffect(() => {
    refresh();
  }, [props.location, pagination, search]);


  return (
    <div className={'franchise-wrapper page-wrapper'}>
    <AdminListLayout titleIcon={<ShoppingOutlined />} title={'Account Management'}
       titleSearch={
         <div className='search-bar'>
           <Search
               placeholder='Search any shipment here'
               size='large'
               onSearch={
                   (e) => {
                       setSearch(e);
                   }
               }
           />
       </div>}
       >

       <Table loading={fetching} scroll={{ x:true }} pagination={false} dataSource={data} columns={columns}>
       </Table>
       <div style={{
         padding: 24,
         float: 'right'
       }}>
        <Pagination key="4" defaultCurrent={1} total={total} onChange={(page,size)=>setPagination({ page:page, perpage: pagination.perpage })} />
        </div>

    </AdminListLayout>
    </div>
  )

}
