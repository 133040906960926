import React, { CSSProperties, useState, useEffect } from 'react';
import './index.scss';
import DesktopLayout from 'shared/components/layout/DesktopLayout';
import { Carousel, Space, Typography, Modal, Collapse, List, notification } from 'antd';
import { DirectionsBoatOutlined } from '@material-ui/icons';
import {CloseOutlined} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import API_SERVICE from 'client/src/services/api-service';

const { Text, Title } = Typography;
const { Panel } = Collapse;

export default function CategorySelector(props: any) {
    const history = useHistory();
    const { t } = useTranslation();
    const data = props.data;

    return (
        <Modal className="selector" zIndex={1200} width={400} closable={false} visible={props.visible} footer={null}>
          <div>
            <div className="series">
              <div className="title-container">
                <div className="title-desc">Select Product Category</div>
              </div>
              <CloseOutlined onClick={props.close} />
            </div>
            <div className="collapse-container">
            <Collapse expandIconPosition="right" defaultActiveKey={props.selection && props.selection.parentId} accordion ghost>
              {
                data.map((item:any,index)=>{
                  return (
                    <Panel key={item.id} header={item.name}>
                      <List
                        className="list-item"
                        size="small"
                        split={false}
                        dataSource={item.children}
                        renderItem={(children:any) =>
                          <List.Item>
                            {
                              <Collapse expandIconPosition="right" accordion ghost>
                                <Panel key={index} header={children.name}>
                                  <List
                                    className="list-item"
                                    size="small"
                                    split={false}
                                    dataSource={children.children}
                                    renderItem={(sub:any) =>
                                      <List.Item>
                                        {
                                          (sub.id===props.selection.id)?
                                          <Link>{sub.name}</Link>
                                          :<Link onClick={()=>{
                                            props.onSelect(sub)
                                            props.close()
                                            }}>
                                            <Text>{sub.name}</Text>
                                          </Link>
                                        }
                                      </List.Item>}
                                    />
                                </Panel>
                              </Collapse>
                            }
                          </List.Item>}
                      />
                    </Panel>
                  );
                })
              }
            </Collapse>
            </div>
          </div>
        </Modal>
    );
}
